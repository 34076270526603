import { Container, FormGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addFeature, removeFeatures } from "../../classification/Utils";
import { FeatureCheck } from "../../components/FeatureCheck";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateFromEvent } from "./Utils";
import { setNeuroExamFeatures } from "../../features/features/featuresSlice";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureRadio } from "../../components/FeatureRadio";

function NeuroExamPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    
    let temp = features.get("exam") === undefined ? ["exam_normal", "exam_course_normal"] : features.get("exam");
    const [exam, setExam] = useState(temp);

    // Main state of exam at onset (normal, impaired/abnormal or not available)
    const [examAtOnset, setExamAtOnset] = useState("exam_normal");
    const examAtOnsetChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let ex = [...exam];
        removeFeatures(ex, ["exam_normal", "exam_abnormal", "exam_unavailable"]);
        addFeature(ex, e.currentTarget.value);
        setExamAtOnset(e.currentTarget.value);
        setExam(ex);
    }

    // Main state of exam during the course (normal, impaired/abnormal or not available)
    const [examAtCourse, setExamAtCourse] = useState("exam_course_normal");
    const examAtCourseChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let ex = [...exam];
        removeFeatures(ex, ["exam_course_normal", "exam_course_abnormal", "exam_course_unavailable"]);
        addFeature(ex, e.currentTarget.value);
        setExamAtCourse(e.currentTarget.value);
        setExam(ex);
    }

    temp = features.get("examFreeText") === undefined ? "" : features.get("examFreeText");
    const [examFreeText, setExamFreeText] = useState(temp);

    temp = features.get("examCourseFreeText") === undefined ? "" : features.get("examCourseFreeText");
    const [examCourseFreeText, setExamCourseFreeText] = useState(temp);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, exam, setExam);

    useEffect(() => {
        dispatch(setNeuroExamFeatures({exam: exam, examFreeText: examFreeText, examCourseFreeText: examCourseFreeText}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.NEURO_EXAM}));
    });

    const abnormal = examAtOnset === "exam_abnormal";
    const abnormalCourse = examAtCourse === "exam_course_abnormal";

    return (
        <Container>
            <Form>
                <h2>Neurological examination</h2>
                <h3>Neurological examination at onset</h3>
                <p>Was the neurological examination at onset normal or abnormal?</p>
                <FormGroup>
                    <FeatureRadio feature="exam_normal" label="Normal examination" selection={examAtOnset} onChange={examAtOnsetChanged} />
                    <FeatureRadio feature="exam_abnormal" label="Abnormal examination " selection={examAtOnset} onChange={examAtOnsetChanged} />
                    <FeatureRadio feature="exam_unavailable" label="Info not available" selection={examAtOnset} onChange={examAtOnsetChanged} />
                </FormGroup>
                                    
                { abnormal && 
                    <div>
                        <FormGroup className="gap_above_small">
                            <FeatureCheck feature="exam_focal" label="Focal neurological sign" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_sensory" label="Sensory deficit" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_cortical_visual" label="Cortical visual impairment" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_visual_field_deficit" label="Persistent visual field deficit" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_hemiparesis_onset" label="Hemiparesis (at seizure onset)" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_microcephaly" label="Microcephaly" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_dysmorphism" label="Dysmorphism" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_other_congenital" label="Other congenital anomalies" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_decelarated_head_growth" label="Deceleration of head growth" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_eye_head_gaze_saccades" label="Eye-head gaze saccades" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_tone_posture_movement" label="Abnormalities of tone, posture or movement" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_movement_disorder" label="Movemement disorder" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_progressive_myoclonus" label="Progressive myoclonus with impaired fine motor function" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_spasticity" label="Spasticity" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_ataxia" label="Ataxia" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_stormy_ataxia" label="Ataxia starting from stormy period" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_encephalopathy" label="Acute encephalopathy with onset of frequent seizures" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_irritability" label="Irritability" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_vomiting" label="Vomiting" featureArray={exam} onChange={handleChange} />
                        </FormGroup>
                        <Form.Group className="gap_above_small">
                            <Form.Label>Other (please specify free text):</Form.Label>
                            <Form.Control as="textarea" rows={3} value={examFreeText} onChange={(e) => setExamFreeText(e.target.value)} />
                        </Form.Group>
                    </div>
                }

                <h3 className="gap_above_small">Neurological examination during the further course</h3>
                <p>Did the patient show any abnormalities in the further course?</p>
                <FormGroup>
                    <FeatureRadio feature="exam_course_normal" label="Normal examination" selection={examAtCourse} onChange={examAtCourseChanged} />
                    <FeatureRadio feature="exam_course_abnormal" label="Abnormal examination" selection={examAtCourse} onChange={examAtCourseChanged} />
                    <FeatureRadio feature="exam_course_unavailable" label="Info not available" selection={examAtCourse} onChange={examAtCourseChanged} />
                </FormGroup>

                { abnormalCourse && 
                    <div>
                        <FormGroup className="gap_above_small">
                            <FeatureCheck feature="exam_course_focal" label="Focal neurological sign" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_sensory" label="Sensory deficit" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_cortical_visual" label="Cortical visual impairment" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_visual_field_deficit" label="Persistent visual field deficit" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_decelarated_head_growth" label="Deceleration of head growth" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_eye_head_gaze_saccades" label="Eye-head gaze saccades" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_tone_posture_movement" label="Abnormalities of tone, posture or movement" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_movement_disorder" label="Movemement disorder" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_progressive_myoclonus" label="Progressive myoclonus with impaired fine motor function" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_spasticity" label="Spasticity" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_ataxia" label="Ataxia" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_late_hemiparesis" label="Development of progressive hemiparesis late after epilepsy onset" featureArray={exam} onChange={handleChange} />                     
                            <FeatureCheck feature="exam_course_irritability" label="Irritability" featureArray={exam} onChange={handleChange} />
                            <FeatureCheck feature="exam_course_vomiting" label="Vomiting" featureArray={exam} onChange={handleChange} />
                            
                        </FormGroup>
                        <Form.Group className="gap_above_small">
                            <Form.Label>Other (please specify free text):</Form.Label>
                            <Form.Control as="textarea" rows={3} value={examCourseFreeText} onChange={(e) => setExamCourseFreeText(e.target.value)} />
                        </Form.Group>
                    </div>
                }
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/EEG")} previous={() => navigate("/pages/Development")} />
        </Container>
        );
}

export default NeuroExamPage;