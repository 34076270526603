import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasFeature } from './Utils';
import { OnsetDevelopment } from './Constants';
import { hasMandatoryDevFeature, hasMandatoryDevFeatures } from './Development';

export class EIMFS extends Syndrome {
    
    getName() {
        return "EIMFS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 0, 1);
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let increase = false;

        let s1 = defaultSeizure();
        s1.mainType = "focal|migrating";
        s1.onset = "motor";
        s1.details = ["motor_clonic"];
        let result = this.getContainingSeizures(seizures, s1, true, annot, "Focal/migrating clonic seizures", false, false);
        const clonic = result.found;
        if(clonic) {
            for(let seizure of result.seizures) {
                if(hasFeature(seizure.frequency, "seizure_freq_inc")) {
                    increase = true;
                    break;
                }
            }
        }


        s1.details = ["motor_tonic"];
        result = this.getContainingSeizures(seizures, s1, true, annot, "Focal/migrating tonic seizures", false, false);
        const tonic = result.found;
        if(!increase && tonic) {
            for(let seizure of result.seizures) {
                if(hasFeature(seizure.frequency, "seizure_freq_inc")) {
                    increase = true;
                    break;
                }
            }
        }

        if(increase) {
            annot.push("Mandatory present: Some or all of the seizures show an increasing seizure frequency.");
        } else {
            annot.push("Mandatory missing: Some or all of the seizures show an increasing seizure frequency.");
        }

        if(increase && (clonic || tonic)) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Generalized myoclonic seizures");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "nonmotor";
        const nonmotor = this.containsSeizure(seizures, s1, false, annot, "Generalized nonmotor seizures");

        if(!myoclonic && !nonmotor) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: OnsetDevelopment[], devAvailable: boolean): FeatureResponse {
        if(!devAvailable) {
            return new Unresolved("Development is not available however is critical to determine mandatory regression and/or psychomotor delay during the course.");
        }

        const annot: string[] = new Array();
        
        const mandatoryOk = hasMandatoryDevFeatures(dev, ["dev_course_regression", "dev_course_delay"], annot, false, false, false);
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory multifocal IEDs and/or ictal migrating patterns.");
        }
        
        const annot: string[] = new Array();

        const mandatoryOk = this.checkFeatures(eeg, ["eeg_multifocal", "eeg_ictal_migrating"], true, annot, true);
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine exlusionary causal lesion on imaging.");
        }
        
        if(hasFeature(imaging, "imaging_lesion")) {
            return new No("Exclusionary: Abnormal imaging with structural causal lesion");
        } 
        return new Unclear("Exclusionary not present: Abnormal imaging with structural causal lesion");
    }

    getAlertCriteria(): string[] {
        return ["Age at onset 6-12 months", "Severe developmental delay prior to seizure onset", "Significant abnormalities on neurological exam prior to onset", 
            "Lack of brain atrophy on MRI during the course of the disease", "Seizure freedom during the course of the disease"];
    }

}