import { Container, Form, FormGroup } from "react-bootstrap";
import { FeatureCheck } from "../../components/FeatureCheck";
import { FeatureCheckField } from "../../components/FeatureCheckField";
import { FeatureRadio } from "../../components/FeatureRadio";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useNavigate } from "react-router-dom";
import { updateFromEvent } from "./Utils";
import { useEffect, useState } from "react";
import { getSeizure } from "../../classification/Seizures";
import { setSeizureExtraFeatures, setSeizureRemark } from "../../features/features/featuresSlice";
import { setAllowed, setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureCheckField2 } from "../../components/FeatureCheckField2";
import { addFeature, removeFeatures } from "../../classification/Utils";

/**
 * Component to specify seizure extra features: Timepoint, triggers, duration, time of day, frequency and others.
 * @returns SeizureExtraFeaturePage component
 */
function SeizureExtraFeaturePage() {
    const features = useAppSelector(state => state.features.features);
    const currentSeizure = useAppSelector(state => state.seizure.currentSeizure);
    const seizure = getSeizure(currentSeizure, features);

    let temp = seizure === null ? 'wake_sleep_unknown' : seizure.timepoint;
    const [timepoint, setTimepoint] = useState(temp === "" ? "wake_sleep_unknown" : temp);
    
    let temp2 = seizure === null ? [] as string[] : seizure.triggers;
    const [triggers, setTriggers] = useState(temp2);

    const [anyTriggers, setAnyTriggers] = useState("no");
    const anyTriggersChanged = (anyTrig: string) => {
        if(anyTrig === "no") {
            setTriggers([]);
        }
        setAnyTriggers(anyTrig);
    }
    
    temp2 = seizure === null ? [] as string[] : seizure.duration;
    const [duration, setDuration] = useState(temp2);
    
    temp2 = seizure === null ? [] as string[] : seizure.timeOfDay;
    const [timeOfDay, setTimeOfDay] = useState(temp2);
    const [anyTimeOfDay, setAnyTimeOfDay] = useState("no");
    const anyTimeOfDayChanged = (anyTime: string) => {
        if(anyTime === "no") {
            setTimeOfDay([]);
        }
        setAnyTimeOfDay(anyTime);
    }
    
    temp2 = seizure === null ? [] as string[] : seizure.frequency;
    const [frequency, setFrequency] = useState(temp2);
    const [anyFrequency, setAnyFrequency] = useState("no");
    const anyFrequencyChanged = (anyFreq: string) => {
        if(anyFreq === "no") {
            setFrequency([]);
        }
        setAnyFrequency(anyFreq);
    }

    temp2 = seizure === null ? [] as string[] : seizure.other;
    const [other, setOther] = useState(temp2);

    temp = "";
    if(seizure !== null && seizure.remarks !== undefined && seizure.remarks.has("seizure_freq_other")) {
        let value = seizure.remarks.get("seizure_freq_other");
        temp = value ?? "";
    }
    const [otherFreq, setOtherFreq] = useState(temp);
    const [otherTrigger, setOtherTrigger] = useState(temp);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleFrequencyFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value;
        setOtherFreq(value);
        dispatch(setSeizureRemark({currentSeizure: currentSeizure, remarkKey: "seizure_freq_other", remarkValue: value}));
    }

    const handleTriggerFieldChange = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value;
        setOtherTrigger(value);
        dispatch(setSeizureRemark({currentSeizure: currentSeizure, remarkKey: "seizure_trigger_other", remarkValue: value}));
    }

    useEffect(() => {
        dispatch(setSeizureExtraFeatures({currentSeizure: currentSeizure, timepoint: timepoint, 
            triggers: triggers, duration: duration, timeOfDay: timeOfDay, frequency: frequency, other: other}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: false, stage: ClassificatorStage.SEIZURES}));
    });

    return (
        <Container>
            <Form>
                <h2>Further information on the seizure type</h2>
                <FormGroup>
                    <h4>Seizures during wakefulness or sleep</h4>
                    <FeatureRadio feature="wake_only" label="Only during wakefulness" selection={timepoint} onChange={e => setTimepoint("wake_only")} />
                    <FeatureRadio feature="wake_predominant" label="Predominantly during wakefulness" selection={timepoint} onChange={e => setTimepoint("wake_predominant")} />
                    <FeatureRadio feature="wake_sleep_either" label="Either from wakefulness or sleep" selection={timepoint} onChange={e => setTimepoint("wake_sleep_either")} />
                    <FeatureRadio feature="sleep_predominant" label="Predominantly from sleep" selection={timepoint} onChange={e => setTimepoint("sleep_predominant")} />
                    <FeatureRadio feature="sleep_only" label="Only from sleep" selection={timepoint} onChange={e => setTimepoint("sleep_only")} />
                    <FeatureRadio feature="wake_sleep_unknown" label="Unknown/not specified" selection={timepoint} onChange={e => setTimepoint("wake_sleep_unknown")} />
                </FormGroup>
                { timepoint !== "wake_only" &&
                    <FormGroup className="gap_above_small">
                        <p>Are seizures associated associated with falling asleep or waking up?</p>
                        <FeatureCheck feature="sleep_falling_asleep" label="Within the first hour/short after falling asleep" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                        <FeatureCheck feature="sleep_before_awakening" label="Within 1-2 hours before awakening" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                        <FeatureCheck feature="sleep_before_at_awakening" label="At awakening" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                        <FeatureCheck feature="sleep_no_association" label="No association" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                    </FormGroup>
                }
                <FormGroup className="gap_above_small">
                    <h4>Time of day/timepoint</h4>
                    <p>Is there an association with a specific time of day?</p>
                    <FormGroup>
                        <FeatureRadio feature="yes" label="Yes" selection={anyTriggers} onChange={() => anyTimeOfDayChanged("yes")} />
                        <FeatureRadio feature="no" label="No" selection={anyTriggers} onChange={() => anyTimeOfDayChanged("no")} />
                    </FormGroup>
                    {anyTimeOfDay === "yes" &&
                        <>
                            <FeatureCheck offset={1} feature="seizure_timeofday_early_morning" label="Early morning" featureArray={timeOfDay} onChange={(e) => updateFromEvent(e, timeOfDay, setTimeOfDay)} />
                            <FeatureCheck offset={1} feature="seizure_timeofday_morning" label="Morning" featureArray={timeOfDay} onChange={(e) => updateFromEvent(e, timeOfDay, setTimeOfDay)} />
                            <FeatureCheck offset={1} feature="seizure_timeofday_day" label="During the day" featureArray={timeOfDay} onChange={(e) => updateFromEvent(e, timeOfDay, setTimeOfDay)} />
                            <FeatureCheck offset={1} feature="seizure_timeofday_evening" label="Evening" featureArray={timeOfDay} onChange={(e) => updateFromEvent(e, timeOfDay, setTimeOfDay)} />
                            <FeatureCheck offset={1} feature="seizure_timeofday_night" label="During the night" featureArray={timeOfDay} onChange={(e) => updateFromEvent(e, timeOfDay, setTimeOfDay)} />
                        </>
                    }
                </FormGroup>
                <FormGroup className="gap_above_small">
                    <h4>Seizure duration</h4>
                    <p>(Most frequently observered duration of the seizure type over the course so far)</p>
                    <FeatureCheck feature="seizure_duration_brief" label="Brief (<2min)" featureArray={duration} onChange={(e) => updateFromEvent(e, duration, setDuration)} />
                    <FeatureCheck feature="seizure_duration_minutes" label="Several minutes (>2min)" featureArray={duration} onChange={(e) => updateFromEvent(e, duration, setDuration)} />
                    <FeatureCheck feature="seizure_duration_longer" label="Longer (>5min)" featureArray={duration} onChange={(e) => updateFromEvent(e, duration, setDuration)} />
                    <FeatureCheck feature="seizure_duration_unknown" label="Unknown" featureArray={duration} onChange={(e) => updateFromEvent(e, duration, setDuration)} />
                </FormGroup>
                <FormGroup className="gap_above_small">
                    <h4>Triggers</h4>
                    <p>Are there any triggers for the seizures?</p>
                    <FormGroup>
                        <FeatureRadio feature="yes" label="Yes" selection={anyTriggers} onChange={() => anyTriggersChanged("yes")} />
                        <FeatureRadio feature="no" label="No" selection={anyTriggers} onChange={() => anyTriggersChanged("no")} />
                    </FormGroup>
                    {anyTriggers === "yes" && 
                        <>
                            <FeatureCheck offset={1} feature="seizure_trigger_reading" label="Reading or language-related tasks" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheck offset={1} feature="seizure_trigger_photic" label="Photic stimuli, such as flickering sunlight" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheck offset={1} feature="seizure_trigger_eye_closure" label="Eye closure" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheck offset={1} feature="seizure_trigger_fasting" label="Fasting" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheck offset={1} feature="seizure_trigger_fever" label="Fever" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheck offset={1} feature="seizure_trigger_sleep_deprivation" label="Sleep deprivation" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} />
                            <FeatureCheckField2 offset={1} feature="seizure_trigger_other" label="Other" featureArray={triggers} onChange={(e) => updateFromEvent(e, triggers, setTriggers)} 
                                fieldName="" fieldValue={otherTrigger} onChangeField={handleTriggerFieldChange}/>
                        </>
                    }
                </FormGroup>
                <FormGroup className="gap_above_small">
                    <h4>Frequency</h4>
                    <p>Is there any information about seizure frequency?</p>
                    <FormGroup>
                        <FeatureRadio feature="yes" label="Yes" selection={anyFrequency} onChange={() => anyFrequencyChanged("yes")} />
                        <FeatureRadio feature="no" label="No" selection={anyFrequency} onChange={() => anyFrequencyChanged("no")} />
                    </FormGroup>
                    {anyFrequency === "yes" && 
                        <>
                            <FeatureCheck offset={1} feature="seizure_freq_daily" label="Daily" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheck offset={1} feature="seizure_freq_weekly" label="Weekly" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheck offset={1} feature="seizure_freq_monthly" label="Monthly" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheckField2 offset={1} feature="seizure_freq_other" label="Other" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} 
                                fieldName="" fieldValue={otherFreq} onChangeField={handleFrequencyFieldChange}/>
                            <FeatureCheck offset={1} feature="seizure_freq_inc" label="Frequency increase over weeks to months" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheck offset={1} feature="seizure_freq_inc_status" label="Seizures progress in frequency and severity to culminate in refractory status epilepticus, typically within 2 weeks of onset"
                                featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheck offset={1} feature="seizure_freq_crescendo" label="Progress in crescendo pattern over months to years" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                            <FeatureCheck offset={1} feature="seizure_freq_cluster" label="Occurence in clusters" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} />
                        </>
                    }
                </FormGroup>
                <FormGroup className="gap_above_small">
                    <h4>Other</h4>
                    <FeatureCheck feature="seizure_freq_cluster" label="Occurence in clusters" featureArray={frequency} onChange={(e) => updateFromEvent(e, frequency, setFrequency)} /> 
                    <FeatureCheck feature="seizure_type_not_occuring" label="Currently not occuring (free of this seizure type)" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_drug_resistant" label="Drug-resistant seizures" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_todd" label="Transient postictal hemiparesis (Todd paresis)" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_persisting_sz" label="Persistence of seizures after mid to late adolesence" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_stormy_onset" label="Onset with abrupt 'stormy period'" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_convulsive_status" label="Occurence of convulsive status epilepticus" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_non_convulsive_status" label="Occurence of non-convulsive status epilepticus" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />
                    <FeatureCheck feature="seizure_other_hhe" label="History of acute and chronic stage: Acute stage: Episode of febrile, hemiclonic status epilepticus, immediately followed by permantent hemiparesis. 
                        Chronic stage: After variable time (usually <3years) unilateral focal motor or focal to bilateral tonic clonic seizures" featureArray={other} onChange={(e) => updateFromEvent(e, other, setOther)} />    
                </FormGroup>
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/SeizureSummary")} previous={() => navigate("/pages/SeizureDetails")} />
        </Container>
        );
}

export default SeizureExtraFeaturePage;