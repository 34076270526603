import { Container, Form, FormGroup } from "react-bootstrap";
import { FeatureRadio } from "../../components/FeatureRadio";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSeizure } from "../../classification/Seizures";
import { useEffect, useState } from "react";
import { FeatureCheck } from "../../components/FeatureCheck";
import { GroupedFeatureCheck } from "../../components/GroupedFeatureCheck";
import { addFeature, addFeatures, removeFeature, removeFeatures } from "../../classification/Utils";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useNavigate } from "react-router-dom";
import { setSeizureDetails } from "../../features/features/featuresSlice";
import { Onset } from "../../classification/Constants";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { updateFromEvent } from "./Utils";
import { ClassificatorStage } from "../../classification/Classificator";

/**
 * Component to specify awareness, motor/nonmotor onset and any further semiological details.
 * @returns component to render
 */
function SeizureDetailsPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    const currentSeizure = useAppSelector(state => state.seizure.currentSeizure);
    const seizure = getSeizure(currentSeizure, features);
    
    let temp = seizure !== null ? seizure.awareness : "aware";
    const [awareness, setAwareness] = useState(temp === "" ? "aware" : temp); 

    temp = seizure !== null ? seizure.onset : "motor";
    const [onset, setOnset] = useState(temp === "" ? "motor" : temp); 

    let seizureType = "focal";
    if(seizure !== null) {
        seizureType = seizure.mainType;
    }

    let defaultTypes: string[] = [];
    if(seizureType === "focal") {
        defaultTypes = seizure !== null ? seizure.details : [];
    }
    const [focal_types, setFocalTypes] = useState(defaultTypes); 
    
    if(seizureType === "generalized") {
        defaultTypes = seizure !== null ? seizure.details : [];
    }
    const [gen_types, setGenTypes] = useState(defaultTypes); 
    
    if(seizureType === "unknown") {
        defaultTypes = seizure !== null ? seizure.details : [];
    }
    const [unknown_types, setUnknownTypes] = useState(defaultTypes); 
        
    const focal = seizureType === "focal" || seizureType === "sequential" || seizureType === "migrating";
    let focalTitle = "Focal seizure";
    if(seizureType === "sequential") {
        focalTitle = "Sequential seizures";
    } else if(seizureType === "migrating") {
        focalTitle = "Migrating seizures";
    }

    const generalized = seizureType === "generalized";
    const unknown = seizureType === "unknown";

    let onsetNonmotorMsg = "Nonmotor";
    if(generalized) {
        onsetNonmotorMsg = "Nonmotor (Absence)"
    }

    const motor = onset === "motor";

    const handleFocalChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, focal_types, setFocalTypes);
    const handleGenChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, gen_types, setGenTypes);
    const handleUnknownChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, unknown_types, setUnknownTypes);

    const groupedSelectionChanged = (mainFeature: string, mainFeatureSelected: boolean, selection: string[], features: string[]) => {
        let focal = [...focal_types];
        removeFeatures(focal, features);
        removeFeature(focal, mainFeature);
        if(mainFeatureSelected) {
            addFeatures(focal, selection);
            addFeature(focal, mainFeature);
        }
        setFocalTypes(focal);
    }

    useEffect(() => {
        const storeFeatures = () => {
            if(seizure === null) {
                return;
            }

            let types;
            if(seizure.mainType === "focal") {
                types = focal_types;
            } else if(seizure.mainType === "generalized") {
                types = gen_types;
            } else if(seizure.mainType === "unknown") {
                types = unknown_types;
            } else {
                return;
            }

            let details = [];
            for(let type of types) {
                if(onset === "motor" && type.startsWith("nonmotor_")) {
                    continue;
                }
                if(onset === "nonmotor" && type.startsWith("motor_")) {
                    continue;
                }
                details.push(type);
            }

            let aware = "";
            if(seizure.mainType === "focal") {
                aware = awareness;
            }

            dispatch(setSeizureDetails({currentSeizure: currentSeizure, details: details, onset: onset as Onset, awareness: aware}));
        }
        storeFeatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSeizure, focal_types, gen_types, unknown_types, onset, awareness]);

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: false, stage: ClassificatorStage.SEIZURES}));
    });

    return (
        <Container>
            { focal &&
                <Form>
                    <h2>{focalTitle}</h2>
                    <p>What type of {seizureType} seizure does the patient suffer from?</p>
                    <FormGroup>
                        <h4>Awareness</h4>
                        <FeatureRadio feature="aware" label="Aware" selection={awareness} onChange={e => setAwareness("aware")} />
                        <FeatureRadio feature="impaired awareness" label="Impaired awareness" selection={awareness} onChange={e => setAwareness("impaired awareness")} />
                        <FeatureRadio feature="awareness unknown" label="Unknown" selection={awareness} onChange={e => setAwareness("awareness unknown")} />
                    </FormGroup>
                </Form>
            }
            { generalized &&
                <div>
                    <h2>Generalized seizure</h2>
                    <p>What type of generalized seizure does the patient suffer from?</p>
                </div>
            }

            { unknown &&
                <div>
                    <h2>Seizure with unknown onset</h2>
                    <p>What type of seizure does the patient suffer from?</p>
                </div>
            }
            
            <Form>
                <FormGroup>
                    <h4 className="gap_above_small">Onset</h4>
                    <FeatureRadio feature="motor" label="Motor" selection={onset} onChange={e => setOnset("motor")} />
                    <FeatureRadio feature="nonmotor" label={onsetNonmotorMsg} selection={onset} onChange={e => setOnset("nonmotor")} />
                </FormGroup>
            </Form>

            { focal && 
                <Form>
                    <FormGroup>
                        {motor && 
                            <div>
                                <h4 className="gap_above_small">Motor onset</h4>
                                <FeatureCheck feature="motor_automatisms" label="Automatisms" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_atonic" label="Atonic" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_clonic" label="Clonic" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_epileptic_spasms" label="Epileptic spasms" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_hyperkinetic" label="Hyperkinetic" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_myoclonic" label="Myoclonic" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="motor_tonic" label="Tonic" featureArray={focal_types} onChange={handleFocalChange} />
                            </div>
                        }
                        {!motor &&
                            <div>
                                <h4 className="gap_above_small">Non-motor onset</h4>
                                <FeatureCheck feature="nonmotor_autonomic" label="Autonomic" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="nonmotor_behavioral_arrest" label="Behavioral arrest" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="nonmotor_cognitive" label="Cognitive" featureArray={focal_types} onChange={handleFocalChange} />
                                <FeatureCheck feature="nonmotor_emotional" label="Emotional" featureArray={focal_types} onChange={handleFocalChange} />
                                <GroupedFeatureCheck mainFeature="nonmotor_sensory" mainLabel="Sensory" features={["nonmotor_sensory_visual", "nonmotor_sensory_auditory"]} labels={["Visual", "Auditory"]} onChange={groupedSelectionChanged} />
                                <FeatureCheck feature="nonmotor_status_epilepticus" label="Non-convulsive status epilepticus" featureArray={focal_types} onChange={handleFocalChange} />
                            </div>
                        }

                        <h4 className="gap_above_small">Focal to bilateral tonic-clonic</h4>
                        <FeatureCheck feature="focbilattc" label="Focal to bilateral tonic-clonic" featureArray={focal_types} onChange={handleFocalChange} />
                    </FormGroup>
                </Form>
            }

            { generalized &&
                <Form className="gap_above_small">
                    <FormGroup>
                        {motor &&
                            <div>
                                <h4>Motor</h4>
                                <FeatureCheck feature="motor_gen_tonic_clonic" label="Tonic-clonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_clonic" label="Clonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_tonic" label="Tonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_myoclonic" label="Myoclonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_myoclonic_tonic_clonic" label="Myoclonic tonic-clonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_myoclonic_atonic" label="Myoclonic-atonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_atonic" label="Atonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="motor_gen_epileptic_spasms" label="Epileptic spasms" featureArray={gen_types} onChange={handleGenChange} />
                            </div>
                        }

                        {!motor &&
                            <div>
                                <h4 className="gap_above_small">Non-motor (Absence)</h4>
                                <FeatureCheck feature="nonmotor_gen_typical" label="Typical" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="nonmotor_gen_atypical" label="Atypical" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="nonmotor_gen_myoclonic" label="Myoclonic" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="nonmotor_gen_eyelid_myoclonia" label="Eyelid myoclonia" featureArray={gen_types} onChange={handleGenChange} />
                                <FeatureCheck feature="nonmotor_gen_status_epilepticus" label="Non-convulsive status epilepticus" featureArray={gen_types} onChange={handleGenChange} />
                            </div>
                        }
                    </FormGroup>
                </Form>
            }
            { unknown &&
                <Form className="gap_above_small">
                    <FormGroup>
                        {motor &&
                            <div>
                                <h4>Motor</h4>
                                <FeatureCheck feature="motor_unknown_tonic_clonic" label="Tonic-clonic" featureArray={unknown_types} onChange={handleUnknownChange} />
                                <FeatureCheck feature="motor_unknown_epileptic_spasms" label="Epileptic spasms" featureArray={unknown_types} onChange={handleUnknownChange} />
                            </div>
                        }   
                        {!motor &&
                            <div>
                                <h4 className="gap_above_small">Non-motor</h4>
                                <FeatureCheck feature="nonmotor_unknown_behavioral_arrest" label="Behavioral arrest" featureArray={unknown_types} onChange={handleUnknownChange} />
                            </div>
                        }
                    </FormGroup>
                </Form>
            }
            <NextPreviousButtons next={() => navigate("/pages/SeizureExtra")} previous={() => navigate("/pages/SeizureType")} />
        </Container>
    );
}

export default SeizureDetailsPage;