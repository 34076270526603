import { Button, Container, Form, FormGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Syndrome } from "../../classification/Syndrome";
import { FeatureCheck } from "../../components/FeatureCheck";
import intl from 'react-intl-universal';
import { Classificator, ClassificatorStage } from "../../classification/Classificator";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { updateFromEvent } from "./Utils";
import { setAlerts } from "../../features/alerts/alertsSlice";
import { setAlertCriteria } from "../../features/features/featuresSlice";

function AlertsPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);

    const classificator = new Classificator();
    const classifications = classificator.check(features, ClassificatorStage.RESULTS);
    const likely = classifications.likely;
    if(classifications.likelyWithAlerts !== undefined) {
        likely.push(...classifications.likelyWithAlerts);
    }

    const [criteria, setCriteria] = useState([] as string[]);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, criteria, setCriteria);
    const constructAlertCriteria = (syndrome: Syndrome) => {
        let alertCriteria = syndrome.getAlertCriteria();
        if(alertCriteria === null) {
            return;
        }

        let crit = [];
        for(let i=0;i<alertCriteria.length;i++) {
            const elem = alertCriteria[i];
            crit.push(<FeatureCheck key={syndrome.getName()+"-"+i} feature={syndrome.getName()+"-"+i} label={elem} featureArray={criteria} onChange={handleChange} />);
        }
        return crit;
    }
    
    let elements = [] as any[];
    likely.forEach((syndrome: Syndrome) => {
        elements.push(<h3 key={syndrome.getName()} className="gap_above">{intl.get(syndrome.getName())}</h3>);
        let alerts = constructAlertCriteria(syndrome);
        elements.push(alerts);
    });

    useEffect(() => {
        dispatch(setAlerts(criteria));
    });

    useEffect(() => {
        dispatch(setAlertCriteria({alerts: criteria}));
    });
        
    return (
        <Container>
            <Form>
                <h2>Alert criteria</h2>
                { elements.length > 0 &&
                    <p>
                        Below, alert criteria for the syndromes that have been identified as likely based on the provided information are checked.
                        Alert criteria are absent in the vast majority of cases, but rarely can be seen. 
                        Their presence should result in caution in diagnosing the syndrome and consideration of other conditions.
                    </p>
                }
                { elements.length === 0 &&
                    <p>
                        No syndromes have been classified as likely, alert criteria are therefore not checked.
                    </p>
                }
                <FormGroup>
                    { elements }
                </FormGroup>
            </Form>
            <Row className="gap_above">
                <Button variant={"secondary"} onClick={(e) => navigate("/pages/Results")}>
                    Next step
                </Button>
            </Row>
        </Container>
    );
}

export default AlertsPage;