import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { hasFeature } from "../classification/Utils";
import { FeatureCheck } from "./FeatureCheck";

export interface FeatureCheckField2Props {
    feature: string;
    label: string;
    featureArray: string[];
    fieldName: string;
    fieldValue: any;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onChangeField: React.ChangeEventHandler<HTMLInputElement>;
    offset?: number;
}

export const FeatureCheckField2 = (props: FeatureCheckField2Props) => {
    const selected = hasFeature(props.featureArray, props.feature);
    const offset = props.offset !== undefined? props.offset : 0;
    
    return <div>
        <Form.Group className="mb-3">
            <Row className="align-items-center">
                <Col><FeatureCheck offset={offset} feature={props.feature} label={props.label} featureArray={props.featureArray} onChange={props.onChange}/></Col>        
            </Row>
            {selected && props.fieldName !== "" &&
                <Row>
                    <Col md={{offset: offset}}>
                        <Form.Label>{props.fieldName}</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control type="text" placeholder={props.fieldName} name={props.feature} value={props.fieldValue} onChange={props.onChangeField} />
                    </Col> 
                </Row>
            }
            {selected && props.fieldName === "" &&
                <Row>
                    <Col md={{offset: offset}}>
                        <Form.Control type="text" placeholder={props.fieldName} name={props.feature} value={props.fieldValue} onChange={props.onChangeField} />
                    </Col> 
                </Row>
            }     
        </Form.Group>   
    </div>;
    
    
}