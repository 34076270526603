import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';
import { Development } from './Constants';
import { hasOnlyImpairedAtOnset } from './Development';

export class PDDEE extends Syndrome {
    
    getName() {
        return "PDDEE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 0, 28/365);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset < 28 days.");
        }
                
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, true, annot, "Focal epileptic spasms", true, false);

        s1.details = ["motor_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, true, annot, "Focal myoclonic seizures", true, false);

        s1 = defaultSeizure();
        s1.details = ["focbilattc"];
        const foc2bilat = this.containsSeizure(seizures, s1, true, annot, "Focal to bilateral tonic-clonic seizures for at least some of the seizures.", true, false);

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_clonic"];
        const gen_clonic = this.containsSeizure(seizures, s1, true, annot, "Generalized clonic seizures", true, false);

        s1.details = ["motor_gen_tonic"];
        const gen_tonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic seizures", true, false);

        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tonic_clonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic-clonic seizures", true, false);

        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistance of at least some seizure types.");

        if(((spasms || myoclonic) && foc2bilat) || (gen_clonic || gen_tonic || gen_tonic_clonic) && dre) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory features.");
        }
        
        const annot: string[] = new Array();
        let disability = false;
        if(hasAnyFeatures(dev, ["dev_disability_ns", "dev_disability_mild", "dev_disability_moderate", "dev_disability_profound"])) {
            annot.push("Mandatory present: Mild to profound intellectual disability (or not further specified).")
            disability = true;
        } else {
            if(hasOnlyImpairedAtOnset(dev)) {
                annot.push("Mandatory missing: Mild to profound intellectual disability (or not further specified), but impaired development at onset without further details specified, syndrome not ruled out.");
                disability = true;
            } else {
                annot.push("Mandatory missing: Mild to profound intellectual disability (or not further specified).");
            }   
        }

        if(disability) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(history, ["history_response_pyr"], true, annot);
        // Also consider family history EIDEE, infertility, death in siblings?
                
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        const annot: string[] = new Array();
        const geneticTestsAvailable = this.checkFeatures(tests, ["test_genetic"], true, [], true);
        if(!geneticTestsAvailable) {
            annot.push("Genetic testing is not available however should be performed to identify ALDH7A1 and PLBP mutations.");
        }
        if(!testsAvailable) {
            annot.push("Further test results are not available however is critical to determine mandatory increased a-aminoadipic semialdehyde and pipecolic acid in urine/plasma or CSF.");
        }
        if(!testsAvailable) {
            return new Unclear(annot);
        }

        const mandatoryOk = this.checkFeatures(tests, ["test_lab_semial_pipecolic"], true, annot);
        const gene = this.checkFeatures(tests, ["test_genetics_aldh7a1", "test_genetics_plbp"], true, annot, true);
                
        if(mandatoryOk) {
            if(gene) {
                return new Yes(annot);
            } else {
                if(!geneticTestsAvailable) {
                    return new Yes(annot);
                } else {
                    return new Unclear(annot);
                }
            }
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        // abnormal with slowing and focal/multifocal discharges or burst-supression pattern
        const annot: string[] = new Array();
        const slow = this.checkFeature(eeg, "eeg_slowing", true, annot);
        const ied = this.checkFeatures(eeg, ["eeg_ied", "eeg_focal", "eeg_multifocal"], true, annot, true);
        const burstSupp = this.checkFeature(eeg, "eeg_burst_supression", true, annot);
        
        if((slow && ied) || burstSupp) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset > 3 years", "Normal intellectual development at onset", "Normal development", "Normal neuroimaging", 
            "Unspecific abnormalities on neuroimaging: White matter edema, intraventricular hemorrhage, ventricular dilatation, corpus callosum hypoplasia",
            "Lack of encephalopathy"];
    }

}