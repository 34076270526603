import { FeatureResponse, No, Unclear, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';

export class PCDH19 extends Syndrome {
    
    getName() {
        return "PCDH19";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        // 1.5–60 months in females; 5–96 months in males
        const gender = features.get("gender");
        let mandatory;
        if(gender === "female") {
            mandatory = super.checkAge(features, 1.5/12, 60/12);
        } else if(gender === "male") {
            mandatory = super.checkAge(features, 5/12, 96/12);
        } else {
            mandatory = super.checkAge(features, 1.5/12, 96/12);
        }

        
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset 1.5-60 months in females/5-96 months in males.");
        }
                
        return new No("Mandatory missing: Age at onset not 1.5-60 months in females/5-96 months in males.");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        // Todo: fearful screaming typical, in clusters; may be triggered by fever 
        // - "may be" implies cases without, thus not included as mandatory.
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.frequency = ["seizure_freq_cluster"];
        const focal = this.containsSeizure(seizures, s1, true, annot, "Focal seizures occuring in clusters", true);

        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic_clonic"];
        const tonic_clonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic-clonic seizures occuring in clusters", true);

        if(focal || tonic_clonic) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        const geneticTestsAvailable = this.checkFeatures(tests, ["test_genetic"], true, [], true);
        if(!testsAvailable || !geneticTestsAvailable) {
            return new Yes("Genetic testing is not available however should be performed to determine identification of PCDH19 mutations.");
        }
        
        const annot: string[] = [];
        const gene = this.checkFeature(tests, "test_genetics_pcdh19", true, annot);
        
        return gene ? new Yes(annot) : new Unclear(annot);
    }
    
    getAlertCriteria(): string[] {
        return ["Prolonged focal clonic (hemiclonic) seizures in infancy (consider Dravet)", "No clustering", 
            "Absence of epileptiform discharges"];
    }

}