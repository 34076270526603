import { Container, Form, FormGroup, Stack } from "react-bootstrap";
import { FeatureCheckField } from "../../components/FeatureCheckField";
import { FeatureCheck } from "../../components/FeatureCheck";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { setImagingFeatures } from "../../features/features/featuresSlice";
import { updateFromEvent } from "./Utils";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureRadio } from "../../components/FeatureRadio";
import { addFeature, removeFeatures } from "../../classification/Utils";

function ImagingPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    
    let temp = features.get("imaging") === undefined ? ["imaging_normal"] as string[] : features.get("imaging");
    const [imaging, setImaging] = useState(temp);

    // Main state of imaging (normal, impaired/abnormal or not available)
    const [imagingState, setImagingState] = useState("imaging_normal");
    const imagingStateChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let ex = [...imaging];
        removeFeatures(ex, ["imaging_normal", "imaging_abnormal", "imaging_unavailable"]);
        addFeature(ex, e.currentTarget.value);
        setImagingState(e.currentTarget.value);
        setImaging(ex);
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, imaging, setImaging);
    
    let temp2 = features.get("otherImaging") === undefined ? "" : features.get("otherImaging");
    const [otherImaging, setOtherImaging] = useState(temp2);

    useEffect(() => {
        dispatch(setImagingFeatures({imaging: imaging, otherImaging: otherImaging}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.IMAGING}));
    });

    const imagingAvailable = imaging.indexOf("imaging_unavailable") == -1;
    const imagingNormal = imaging.indexOf("imaging_normal") > -1;
    
    return (
    <Container>
        <Form onSubmit={e => e.preventDefault()}>
            <h2>Imaging</h2>
            <p>Are imaging results available?</p>
            <FormGroup>
                <FeatureRadio feature="imaging_normal" label="Normal imaging" selection={imagingState} onChange={imagingStateChanged} />
                <FeatureRadio feature="imaging_abnormal" label="Abnormal imaging" selection={imagingState} onChange={imagingStateChanged} />
                <FeatureRadio feature="imaging_unavailable" label="Imaging not available" selection={imagingState} onChange={imagingStateChanged} />
            </FormGroup>
        </Form>
        { imagingAvailable && 
            <Form className="gap_above_small"> 
                <Form.Group>
                    <Form.Label>Other imaging (provide type and age):</Form.Label>
                    <Form.Control as="textarea" placeholder="Other imaging" rows={3} value={otherImaging} onChange={(e) => setOtherImaging(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    {!imagingNormal &&
                        <>
                            <h3 className="gap_above_small">Imaging findings</h3>
                            <FeatureCheck feature="imaging_lesion" label="Causal lesion on brain MRI" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_fcd" label="FCD" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_hs" label="Hippocampal sclerosis (unilateral or bilateral)" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_progressive_hemiatrophy" label="Progressive hemiatrophy " featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_occ_calcifications" label="Cerebral occipital lobe calcifications" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_lesion_hemiplegia" label="Imaging features consistent with causing hemiplegia" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_transitory_t1_hyper" label="Transitory T1 hyperintensity in basal ganglia" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_mild_frontal_atrophy" label="Mild frontal atrophy" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_thin_corpus_callosum" label="Thin corpus callosum" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_hypothalamic_hamartoma" label="Hypothalamic hamartoma" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_sws" label="MRI showing leptomeningeal enhancement suggestive of leptomeningeal angioma, with cortical calcification and focal cerebral atrophy developing with time (consistent with Sturge-Weber)" 
                                featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_tubers" label="Cortical/subcortical tubers" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_nodules" label="Subependymal nodules" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_bands" label="Subcortical radial bands" featureArray={imaging} onChange={handleChange} />
                            <FeatureCheck feature="imaging_SEGA" label="Subependymal giant cell astrocytomas (SEGA)" featureArray={imaging} onChange={handleChange} />
                        </>
                    }
                </Form.Group>
            </Form>
        }
        <NextPreviousButtons next={() => navigate("/pages/Tests")} previous={() => navigate("/pages/History")} />
    </Container>
    );
}

export default ImagingPage;