import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { FeatureCheck } from "../../components/FeatureCheck";
import { addFeature, addFeatures, hasFeature, removeFeature, removeFeatures } from "../../classification/Utils";
import { GroupedFeatureCheck } from "../../components/GroupedFeatureCheck";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { updateFromEvent } from "./Utils";
import { FeatureCheckField } from '../../components/FeatureCheckField';
import { setEEGFeatures } from "../../features/features/featuresSlice";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureRadio } from "../../components/FeatureRadio";
import { IctalEEG, InterictalEEG, SleepEEG } from "../../classification/Constants";

function EEGPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    
    // EEG features
    let temp = features.get("eeg") === undefined ? [] as string[] : features.get("eeg");
    const [eeg, setEEG] = useState(temp);

    // Spike wave frequencies
    temp = features.get("swFreq") === undefined ? "" : features.get("swFreq");
    const [swFreq, setSwFreq] = useState(temp);

    // Other sleep findings
    temp = features.get("eeg_other_sleep_findings") === undefined ? "" : features.get("eeg_other_sleep_findings");
    const [otherSleepFindings, setOtherSleepFindings] = useState(temp);

    // Main state of interictal EEG
    temp = InterictalEEG.eeg_interictal_unavailable;
    if(eeg.indexOf(InterictalEEG.eeg_interictal_normal) > -1) {
        temp = InterictalEEG.eeg_interictal_normal;
    } else if(eeg.indexOf(InterictalEEG.eeg_interictal_abnormal) > -1) {
        temp = InterictalEEG.eeg_interictal_abnormal;
    }
    const [interictalEEG, setInterictalEEG] = useState(temp);
    const interictalEEGChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let temp = [...eeg];
        removeFeatures(temp, [InterictalEEG.eeg_interictal_normal, InterictalEEG.eeg_interictal_abnormal, InterictalEEG.eeg_interictal_unavailable]);
        addFeature(temp, e.currentTarget.value);
        setInterictalEEG(e.currentTarget.value);
        setEEG(temp);
    }

    // Main state of ictal EEG
    temp = IctalEEG.eeg_ictal_unavailable;
    if(eeg.indexOf(IctalEEG.eeg_ictal_normal) > -1) {
        temp = IctalEEG.eeg_ictal_normal;
    } else if(eeg.indexOf(IctalEEG.eeg_ictal_abnormal) > -1) {
        temp = IctalEEG.eeg_ictal_abnormal;
    }
    const [ictalEEG, setIctalEEG] = useState(temp);
    const ictalEEGChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let temp = [...eeg];
        removeFeatures(temp, [IctalEEG.eeg_ictal_normal, IctalEEG.eeg_ictal_abnormal, IctalEEG.eeg_ictal_unavailable]);
        addFeature(temp, e.currentTarget.value);
        setIctalEEG(e.currentTarget.value);
        setEEG(temp);
    }

    // Main state of sleep EEG
    temp = SleepEEG.eeg_sleep_unavailable;
    if(eeg.indexOf(SleepEEG.eeg_sleep_normal) > -1) {
        temp = SleepEEG.eeg_sleep_normal;
    } else if(eeg.indexOf(SleepEEG.eeg_sleep_abnormal) > -1) {
        temp = SleepEEG.eeg_sleep_abnormal;
    }
    const [sleepEEG, setSleepEEG] = useState(temp);
    const sleepEEGChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let temp = [...eeg];
        removeFeatures(temp, [SleepEEG.eeg_sleep_normal, SleepEEG.eeg_sleep_abnormal, SleepEEG.eeg_sleep_unavailable]);
        addFeature(temp, e.currentTarget.value);
        setSleepEEG(e.currentTarget.value);
        setEEG(temp);
    }
        
    const abnorm = hasFeature(eeg, "eeg_epilep_abnorm");

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, eeg, setEEG);
    const groupedSelectionChanged = (mainFeature: string, mainFeatureSelected: boolean, selection: string[], features: string[]) => {
        let temp = [...eeg];
        removeFeatures(temp, features);
        removeFeature(temp, mainFeature);
        if(mainFeatureSelected) {
            addFeatures(temp, selection);
            addFeature(temp, mainFeature);
        }
        setEEG(temp);
    }

    useEffect(() => {
        dispatch(setEEGFeatures({eeg: eeg, swFreq: swFreq, otherSleepFindings: otherSleepFindings}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.EEG}));
    });
    
    return (
        <Container>
            <Form onSubmit={e => e.preventDefault()}>
                <Row><h2>EEG findings</h2></Row>
                <Row>
                    <Col>
                        <h3>EEG interictal</h3>
                        <FormGroup>
                            <FeatureRadio feature={InterictalEEG.eeg_interictal_unavailable} label="Not available" selection={interictalEEG} onChange={interictalEEGChanged} />
                            <FeatureRadio feature={InterictalEEG.eeg_interictal_normal} label="Normal" selection={interictalEEG} onChange={interictalEEGChanged} />
                            <FeatureRadio feature={InterictalEEG.eeg_interictal_abnormal} label="Abnormal" selection={interictalEEG} onChange={interictalEEGChanged} /> 
                        </FormGroup>
                    </Col>
                </Row>
                {interictalEEG === InterictalEEG.eeg_interictal_abnormal &&
                    <div>
                        <Row>
                            <Col md={{ offset: 1 }}> 
                                <FeatureCheck feature="eeg_epilep_abnorm" label="Epileptiform abnormalities" featureArray={eeg} onChange={handleChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ offset: 2 }}>
                                { abnorm &&
                                    <div>
                                        <FeatureCheck feature="eeg_gen_ps" label="Generalized polyspikes" featureArray={eeg} onChange={handleChange} />
                                        <FeatureCheck feature="eeg_gen_sw_psw" label="Generalized spike/polyspike-and-wave" featureArray={eeg} onChange={handleChange} />                                     
                                        <FeatureCheckField feature="eeg_gen_sw" label="Generalized spike wave" featureArray={eeg} fieldName="frequency (Hz)" onChange={handleChange} onChangeField={(e) => setSwFreq(e.target.value)} />
                                        <FeatureCheck feature="eeg_hemispheric_abnorm" label="Hemispheric epileptiform abnormality" featureArray={eeg} onChange={handleChange} />
                                        <FeatureCheck feature="eeg_unilateral_discharges" label="Consistently unilateral focal epileptiform discharges" featureArray={eeg} onChange={handleChange} />
                                        <GroupedFeatureCheck mainFeature="eeg_ied" mainLabel="Focal/multifocal abnormalities" 
                                            features={["eeg_ied_frontal", "eeg_ied_central", "eeg_ied_parietal", "eeg_ied_temporal", "eeg_ied_occipital", "eeg_focal", "eeg_multifocal"]} 
                                            labels={["Frontal", "Central", "Parietal", "Temporal", "Occipital", "Unspecified focal", "Unspecified multifocal"]} 
                                            onChange={groupedSelectionChanged} />                          
                                        <FeatureCheck feature="eeg_trigger_IPS" label="Epileptiform abnormalities facilitated by eye closure and intermittent photic stimulation" featureArray={eeg} onChange={handleChange} />                              
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ offset: 1 }}>

                                <GroupedFeatureCheck mainFeature="eeg_slowing" mainLabel="Slowing" 
                                    features={["eeg_background_slowing", "eeg_hemifocal_slowing", "eeg_hemispheric_slowing", "eeg_focal_slowing"]} 
                                    labels={["Background slowing, not limited to the postictal phase", "Hemifocal slowing of background activity", 
                                        "Hemispheric slowing", "Focal slowing"]} 
                                    onChange={groupedSelectionChanged} />                                                              

                                <FeatureCheck feature="eeg_progressive_slowing" label="EEG deterioration with progressive background slowing and/or increased epileptiform abnormality"
                                    featureArray={eeg} onChange={handleChange} />     
                                <FeatureCheck feature="eeg_burst_supression" label="Burst supression"
                                    featureArray={eeg} onChange={handleChange} />
                                <FeatureCheck feature="eeg_discontinuity" label="Discontinuity"
                                    featureArray={eeg} onChange={handleChange} />
                                <FeatureCheck feature="eeg_hemispheric_supression" label="Hemispheric supression"
                                    featureArray={eeg} onChange={handleChange} />
                                <FeatureCheck feature="eeg_hypsarrhythmia" label="Hypsarrhythmia"
                                    featureArray={eeg} onChange={handleChange} />
                            </Col>
                        </Row>
                    </div>
                }

                <Row>
                    <Col>
                        <h3 className="gap_above_small">EEG ictal</h3>
                        <FormGroup>
                            <FeatureRadio feature={IctalEEG.eeg_ictal_unavailable} label="Not available" selection={ictalEEG} onChange={ictalEEGChanged} />
                            <FeatureRadio feature={IctalEEG.eeg_ictal_normal} label="Normal" selection={ictalEEG} onChange={ictalEEGChanged} />
                            <FeatureRadio feature={IctalEEG.eeg_ictal_abnormal} label="Abnormal" selection={ictalEEG} onChange={ictalEEGChanged} />
                        </FormGroup>
                    </Col>
                </Row>
                {ictalEEG === IctalEEG.eeg_ictal_abnormal &&
                    <Row>
                        <Col md={{ offset: 1 }}>
                            <FeatureCheck feature="eeg_myoclonic_event" label="Habitual myoclonic event captured on eeg in the absence of polyspike and spike-wave discharge"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_seizures_generalized" label="Recorded seizures with generalized onset"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_seizures_outside_TL" label="EEG seizures recorded with onset in regions outside the temporal lobe"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_focal_ictal_discharges" label="Focal ictal discharges"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_onset_gen_3Hz_discharges" label="Paroxysms of 3 Hz (2.5-4 Hz) generalized spike-wave concomitant to activity arrest"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_sw_jerks" label="Regular 3Hz generalized spike-and-wave pattern time-locked with myoclonic jerks"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_gen_fast" label="Generalized paroxysmal fast ictal activity"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_lack_EEG_correlate" label="Lack of EEG correlate with typical clinical event"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_neonatal_myoclonus_no_EEG" label="Neonatal (focal/asymetric), erratic myoclonus without EEG correlate"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_low_fast" label="Low-voltage, high frequency fast activity"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_migrating" label="Migrating pattern"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_ictal_electrodecrement" label="Generalized voltage attenuation (electrodecrement), sometimes combined with very fast oscillations"
                                featureArray={eeg} onChange={handleChange} />
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <h3 className="gap_above_small">Sleep EEG</h3>
                        <FormGroup>
                            <FeatureRadio feature={SleepEEG.eeg_sleep_unavailable} label="Not available" selection={sleepEEG} onChange={sleepEEGChanged} />
                            <FeatureRadio feature={SleepEEG.eeg_sleep_normal} label="Normal" selection={sleepEEG} onChange={sleepEEGChanged} />
                            <FeatureRadio feature={SleepEEG.eeg_sleep_abnormal} label="Abnormal" selection={sleepEEG} onChange={sleepEEGChanged} /> 
                        </FormGroup>
                    </Col>
                </Row>

                {sleepEEG === SleepEEG.eeg_sleep_abnormal &&
                    <Row>
                        <Col md={{ offset: 1 }}>
                            <p>Are any of the following specific EEG features present during sleep?</p>
                            <FeatureCheck feature="eeg_sw_nrem_sleep" label="Slow (1.5–2 Hz) spike-and-wave abnormalities in N-REM sleep, abnormalities markedly activated in sleep"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_sleep_drowsiness_increase" label="Increase of epileptic activity in drowsiness"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_sleep_increase" label="Increase of epileptic activity during sleep"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_sleep_burst_supression" label="Burst supression during sleep"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheck feature="eeg_gen_fast_sleep" label="Generalized paroxysmal fast activity in sleep"
                                featureArray={eeg} onChange={handleChange} />
                            <FeatureCheckField feature="eeg_sleep_other" label="Other"
                                featureArray={eeg} onChange={handleChange} fieldName={""} fieldDefaultValue={otherSleepFindings} size="large" onChangeField={(event: ChangeEvent<HTMLInputElement>) => {setOtherSleepFindings(event.currentTarget.value)} } />
                        </Col>
                    </Row>
                }
            </Form>

            <NextPreviousButtons next={() => navigate("/pages/history")} previous={() => navigate("/pages/NeuroExam")} />
        </Container>
        );
}

export default EEGPage;