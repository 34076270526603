import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AccountUIState {
    open: boolean;
}

const initialState: AccountUIState = {
    open: false
}

export const accountUISlice = createSlice({
    name: 'accountUI',
    initialState,
    reducers: {
        showAccountView: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
            return state;
        }
    }
});

export const { showAccountView } = accountUISlice.actions;

export const selectAccountUI = (state: RootState) => state.accountUI;

export default accountUISlice.reducer;