import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ClassificatorStage } from "../../classification/Classificator";

export interface ClassificationUIState {
    allowed: boolean;
    open: boolean;
    stage: ClassificatorStage;
}

const initialState: ClassificationUIState = {
    allowed: false,
    open: false,
    stage: ClassificatorStage.UNDEFINED
}

export const classificationUISlice = createSlice({
    name: 'classificationUI',
    initialState,
    reducers: {
        setAllowed: (state, action: PayloadAction<boolean>) => {
            state.allowed = action.payload;
            return state;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
            return state;
        },
        setStage: (state, action: PayloadAction<ClassificatorStage>) => {
            state.stage = action.payload;
            return state;
        },
        setAllowedAndStage: (state, action: PayloadAction<{stage: ClassificatorStage, allowed: boolean}>) => {
            state.stage = action.payload.stage;
            state.allowed = action.payload.allowed;
            return state;
        }
    }
});

export const { setAllowed, setOpen, setStage, setAllowedAndStage } = classificationUISlice.actions;

export const selectClassificationUI = (state: RootState) => state.classificationUI;

export default classificationUISlice.reducer;