import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SeizureState {
    currentSeizure: number;
}

const initialState: SeizureState = {
    currentSeizure: 0
}

export const seizureSlice = createSlice({
    name: 'seizure',
    initialState,
    reducers: {
        setCurrentSeizure: (state, action: PayloadAction<number>) => {
            state.currentSeizure = action.payload;
        }
    }
});

export const { setCurrentSeizure } = seizureSlice.actions;

export const selectSeizure = (state: RootState) => state.seizure;

export default seizureSlice.reducer;