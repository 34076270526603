import { EAF } from "./EAF";
import { EwRIS } from "./EwRIS";
import { FFEVF } from "./FFEVF";
import { GTCA } from "./GTCA";
import { SHE } from "./SHE";
import { Syndrome } from "./Syndrome";
import { JAE } from './JAE';
import { JME } from "./JME";
import { mTLE_HS } from "./mTLE_HS";
import { PME } from "./PME";
import { CAE } from './CAE';
import { EEM } from "./EEM";
import { EMA } from "./EMA";
import { EMAtS } from "./EMAtS";
import { FIRES } from "./FIRES";
import { HHE } from "./HHE";
import { DEESWAS } from "./DEESWAS";
import { EESWAS } from "./EESWAS";
import { LGS } from "./LGS";
import { POLE } from "./POLE";
import { SeLEAS } from './SeLEAS';
import { SeLECTS } from './SeLECTS';
import { EIDEE } from './EIDEE';
import { Glut1DS } from "./Glut1DS";
import { KCNQ2DEE } from "./KCNQ2DEE";
import { PDDEE } from "./PDDEE";
import { PNPODEE } from "./PNPODEE";
import { SeLFNIE } from "./SeLFNIE";
import { SeLNE } from "./SeLNE";
import { CDKL5DEE } from './CDKL5DEE';
import { DS } from "./DS";
import { EIMFS } from "./EIMFS";
import { IESS } from "./IESS";
import { GEFS } from "./GEFS";
import { GSHH } from "./GSHH";
import { MEI } from "./MEI";
import { PCDH19 } from "./PCDH19";
import { SWS } from "./SWS";
import { SeLIE } from "./SeLIE";

/**
 * Get an instance of the syndrome specified by its name.
 * @param name name of the syndrome
 * @returns instance of the syndrome or null if it wasn't recognized
 */
export function getSyndrome(name: string): Syndrome | null {
    switch (name) {
        case "SHE": return new SHE();
        case "EAF": return new EAF();
        case "EwRIS": return new EwRIS();
        case "FFEVF": return new FFEVF();
        case "GTCA": return new GTCA();
        case "JAE": return new JAE();
        case "JME": return new JME();
        case "mTLE_HS": return new mTLE_HS();
        case "PME": return new PME();
        case "CAE": return new CAE();
        case "EEM": return new EEM();
        case "EMA": return new EMA();
        case "EMAtS": return new EMAtS();
        case "FIRES": return new FIRES();
        case "HHE": return new HHE();
        case "DEESWAS": return new DEESWAS();
        case "EESWAS": return new EESWAS();
        case "LGS": return new LGS();
        case "POLE": return new POLE();
        case "SeLEAS": return new SeLEAS();
        case "SeLECTS": return new SeLECTS();
        case "EIDEE": return new EIDEE();
        case "Glut1DS": return new Glut1DS();
        case "KCNQ2DEE": return new KCNQ2DEE();
        case "PDDEE": return new PDDEE();
        case "PNPODEE": return new PNPODEE();
        case "SeLFNIE": return new SeLFNIE();
        case "SeLNE": return new SeLNE();
        case "CDKL5DEE": return new CDKL5DEE();
        case "DS": return new DS();
        case "EIMFS": return new EIMFS();
        case "IESS": return new IESS();
        case "GEFS": return new GEFS();
        case "GSHH": return new GSHH();
        case "MEI": return new MEI();
        case "PCDH19": return new PCDH19();
        case "SWS": return new SWS();
        case "SeLIE": return new SeLIE();
    }
    return null;
}

export const syndromes = [
    "SHE", "EAF", "EwRIS", "FFEVF", "GTCA", "JAE", "JME", "mTLE_HS", "PME", "CAE", "EEM", "EMA", "EMAtS",
    "FIRES", "HHE", "DEESWAS", "EESWAS", "LGS", "POLE", "SeLEAS", "SeLECTS", "EIDEE", "Glut1DS", "KCNQ2DEE", "PDDEE",
    "PNPODEE", "SeLFNIE", "SeLNE", "CDKL5DEE", "DS", "EIMFS", "IESS", "GEFS", "GSHH", "MEI", "PCDH19", "SWS", "SeLIE"
];