import { FeatureResponse, No, Unclear, Unresolved, Yes } from "./FeatureResponse";
import { Syndrome } from "./Syndrome";

export class mTLE_HS extends Syndrome {

    getName() {
        return "mTLE_HS";
    }

    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        for (const seizure of seizures) {
            if(seizure.mainType === "focal") 
                return new Yes("Mandatory: Focal seizures present.");
        }
        return new No("Mandatory missing: No focal seizures.");
    }

    checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        for (const seizure of seizures) {
            if(seizure.mainType === "generalized") 
                return new No("Exlusionary: Generalized seizures.");
        }
        return new Yes();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine exlusionary features.");
        }

        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_seizures_generalized", "eeg_seizures_outside_TL"], false, annot);
        
        if(exclusionaryOk) {
            return new Yes(annot); 
        }

        return new Unclear();
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeature(imaging, "imaging_hs", true, annot);
        
        if(mandatoryOk)  
            return new Yes(annot);
        return new No(annot);
    }

    getAlertCriteria(): string[] {
        return ["Age at onset < 2 years", "Moderate to severe intellectual disability", "Focal neurological findings such as hemiparesis (excluding facial asymmetry)",
            "Initial semiology referable to networks other than mesial temporal (e.g., throat discomfort, clonic or dystonic movements, somatic sensory symptoms, hyperkinetic activity, visual symptoms, auditory symptoms, laughter)",
            "Consistent lack of temporal epileptiform abnormality, despite repeated EEGs", "Generalized epileptiform abnormality", "High-amplitude, centrotemporal spikes with horizontal dipole",
            "Interictal epileptiform abnormality or focal slowing outside of the temporal regions or over the posterior temporal region"];
    }
}