import React from "react";
import { Form } from "react-bootstrap";

export interface FeatureRadioProps {
    feature: string;
    label: string;
    selection: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const FeatureRadio = (props: FeatureRadioProps) => {
    return <Form.Check checked={props.selection === props.feature} type="radio" label={props.label} 
    value={props.feature} onChange={props.onChange}/>
}