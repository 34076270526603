export enum SECTION {
    EEG = "eeg", 
    EXAM = "neuroexam", 
    DEVELOPMENT = "development", 
    IMAGING = "imaging",
    HISTORY = "history",
    SEIZURES = "seizures"
}

export const MainType = {
    focal: "focal",
    generalized: "generalized",
    unkown: "unknown",
    unclassified: "unclassified",
    sequential: "sequential",
    migrating: "migrating",
    ns: "*"
}

export type MainType = (typeof MainType)[keyof typeof MainType];

export const Onset = {
    motor: "motor",
    nonmotor: "nonmotor",
    ns: "*",
    empty: ""
} as const;

export type Onset = (typeof Onset)[keyof typeof Onset];

export const TimeOfDay = {
    earlyMorning: "seizure_timeofday_early_morning",
    ns: "*"
}

export type TimeOfDay = (typeof TimeOfDay)[keyof typeof TimeOfDay];

export const SeizureExtraFeatures = {
    seizure_trigger_sleep_deprivation: "seizure_trigger_sleep_deprivation",
    seizure_trigger_reading: "seizure_trigger_reading",
    seizure_trigger_photic: "seizure_trigger_photic",
    seizure_trigger_fasting: "seizure_trigger_fasting",
    seizure_trigger_fever: "seizure_trigger_fever"
} as const;

export const OnsetDevelopment = {
    dev_unavailable: "dev_unavailable",
    dev_normal: "dev_normal",
    dev_abnormal: "dev_abnormal",
    dev_int_disability: "dev_int_disability",
    dev_regression: "dev_regression",
    dev_delay: "dev_delay",
    dev_disability_ns: "dev_disability_ns", 
    dev_disability_mild: "dev_disability_mild", 
    dev_disability_moderate: "dev_disability_moderate", 
    dev_disability_profound: "dev_disability_profound",
    dev_severe_delay: "dev_severe_delay",
    dev_progressive_deterioration: "dev_progressive_deterioration",
    dev_encephalopathy: "dev_encephalopathy",
    dev_cognitive_stagnation: "dev_cognitive_stagnation",
    dev_dee_swas_regression: "dev_dee_swas_regression",
    dev_asd: "dev_asd"
} as const;

export type OnsetDevelopment = (typeof OnsetDevelopment)[keyof typeof OnsetDevelopment];

export const onsetDevelopmentMinor = ["dev_int_disability", "dev_disability_ns", "dev_disability_mild", "dev_disability_moderate", 
    "dev_disability_profound", "dev_delay", "dev_severe_delay", "dev_regression", "dev_cognitive_stagnation",
    "dev_encephalopathy", "dev_dee_swas_regression", "dev_asd"];

export const CourseDevelopment = {
    dev_course_normal: "dev_course_normal",
    dev_course_abnormal: "dev_course_abnormal",
    dev_course_unavailable: "dev_course_unavailable",
    dev_course_disability_ns: "dev_course_disability_ns", 
    dev_course_disability_mild: "dev_course_disability_mild", 
    dev_course_disability_moderate: "dev_course_disability_moderate", 
    dev_course_disability_profound: "dev_course_disability_profound",
    dev_course_delay: "dev_course_delay",
    dev_course_severe_delay: "dev_course_severe_delay",
    dev_course_regression: "dev_course_regression",
    dev_course_encephalopathy: "dev_course_encephalopathy",
    dev_course_dee_swas_regression: "dev_course_dee_swas_regression"
} as const;

export type CourseDevelopment = (typeof CourseDevelopment)[keyof typeof CourseDevelopment];

export const courseDevelopmentMinor = ["dev_course_disability_ns", "dev_course_disability_mild", "dev_course_disability_moderate", "dev_course_disability_profound", 
    "dev_course_delay", "dev_course_severe_delay", "dev_course_regression",
    "dev_course_encephalopathy", "dev_course_dee_swas_regression"];

export type Development = OnsetDevelopment | CourseDevelopment;


export const OnsetExam = {
    exam_normal: "exam_normal",
    exam_abnormal: "exam_abnormal",
    exam_unavailable: "exam_unavailable",
    exam_focal: "exam_focal",
    exam_sensory: "exam_sensory",
    exam_cortical_visual: "exam_cortical_visual",
    exam_visual_field_deficit: "exam_visual_field_deficit",
    exam_hemiparesis_onset: "exam_hemiparesis_onset",
    exam_microcephaly: "exam_microcephaly",
    exam_dysmorphism: "exam_dysmorphism",
    exam_other_congenital: "exam_other_congenital",
    exam_decelarated_head_growth: "exam_decelarated_head_growth",
    exam_eye_head_gaze_saccades: "exam_eye_head_gaze_saccades",
    exam_tone_posture_movement: "exam_tone_posture_movement",
    exam_movement_disorder: "exam_movement_disorder",
    exam_progressive_myoclonus: "exam_progressive_myoclonus",
    exam_spasticity: "exam_spasticity",
    exam_ataxia: "exam_ataxia",
    exam_stormy_ataxia: "exam_stormy_ataxia",
    exam_encephalopathy: "exam_encephalopathy",
    exam_irritability: "exam_irritability",
    exam_vomiting: "exam_vomiting"
} as const;

export type OnsetExam = (typeof OnsetExam)[keyof typeof OnsetExam];

export const onsetExamMinor = ["exam_focal", "exam_sensory", "exam_cortical_visual", "exam_visual_field_deficit", "exam_hemiparesis_onset", 
    "exam_microcephaly", "exam_dysmorphism", "exam_other_congenital", "exam_decelarated_head_growth", "exam_eye_head_gaze_saccades", "exam_tone_posture_movement",
    "exam_movement_disorder", "exam_progressive_myoclonus", "exam_spasticity", "exam_ataxia", "exam_stormy_ataxia", "exam_encephalopathy",
    "exam_irritability", "exam_vomiting"];

export const CourseExam = {
    exam_course_normal: "exam_course_normal",
    exam_course_abnormal: "exam_course_abnormal",
    exam_course_unavailable: "exam_course_unavailable",
    exam_course_focal: "exam_course_focal",
    exam_course_sensory: "exam_course_sensory",
    exam_course_cortical_visual: "exam_course_cortical_visual",
    exam_course_visual_field_deficit: "exam_course_visual_field_deficit",
    exam_course_decelarated_head_growth: "exam_course_decelarated_head_growth",
    exam_course_eye_head_gaze_saccades: "exam_course_eye_head_gaze_saccades",
    exam_course_tone_posture_movement: "exam_course_tone_posture_movement",
    exam_course_movement_disorder: "exam_course_movement_disorder",
    exam_course_progressive_myoclonus: "exam_course_progressive_myoclonus",
    exam_course_spasticity: "exam_course_spasticity",
    exam_course_ataxia: "exam_course_ataxia",
    exam_late_hemiparesis: "exam_late_hemiparesis",
    exam_course_irritability: "exam_course_irritability",
    exam_course_vomiting: "exam_course_vomiting"
} as const;

export type CourseExam = (typeof CourseExam)[keyof typeof CourseExam];

export const courseExamMinor = ["exam_course_focal", "exam_course_sensory", "exam_course_cortical_visual", "exam_course_visual_field_deficit",
    "exam_course_decelarated_head_growth", "exam_course_eye_head_gaze_saccades", "exam_course_tone_posture_movement", "exam_course_movement_disorder",
    "exam_course_progressive_myoclonus", "exam_late_hemiparesis", "exam_course_irritability", "exam_course_vomiting"];

export type Exam = OnsetExam | CourseDevelopment;


export const InterictalEEG = {
    eeg_interictal_unavailable: "eeg_interictal_unavailable",
    eeg_interictal_normal: "eeg_interictal_normal",
    eeg_interictal_abnormal: "eeg_interictal_abnormal",
    eeg_epilep_abnorm: "eeg_epilep_abnorm",
    eeg_ied: "eeg_ied",
    eeg_ied_central: "eeg_ied_central",
    eeg_ied_temporal: "eeg_ied_temporal",
    eeg_ied_frontal: "eeg_ied_frontal",
    eeg_ied_occipital: "eeg_ied_occipital",
    eeg_ied_parietal: "eeg_ied_parietal", 
    eeg_hemispheric_abnorm: "eeg_hemispheric_abnorm", 
    eeg_focal: "eeg_focal", 
    eeg_focal_multifocal: "eeg_focal_multifocal", 
    eeg_multifocal: "eeg_multifocal", 
    eeg_unilateral_discharges: "eeg_unilateral_discharges", 
    eeg_occipital_ied: "eeg_occipital_ied", 
    eeg_trigger_IPS: "eeg_trigger_IPS", 
    eeg_focal_abnormalities: "eeg_focal_abnormalities", 
    
    eeg_slowing: "eeg_slowing",
    eeg_background_slowing: "eeg_background_slowing", 
    eeg_hemifocal_slowing: "eeg_hemifocal_slowing", 
    eeg_hemispheric_slowing: "eeg_hemispheric_slowing", 
    eeg_focal_slowing: "eeg_focal_slowing",
    eeg_progressive_slowing: "eeg_progressive_slowing",

    eeg_gen_ps: "eeg_gen_ps",
    eeg_gen_sw: "eeg_gen_sw",
    eeg_gen_sw_3_5_5: "eeg_gen_sw_3_5_5",
    eeg_gen_sw_lt_25: "eeg_gen_sw_lt_25", 
    eeg_gen_sw_2_6: "eeg_gen_sw_2_6", 
    eeg_gen_sw_2_5_4: "eeg_gen_sw_2_5_4", 
    eeg_gen_sw_psw: "eeg_gen_sw_psw",

    eeg_burst_supression: "eeg_burst_supression",
    eeg_discontinuity: "eeg_discontinuity",
    eeg_hemispheric_supression: "eeg_hemispheric_supression",
    eeg_hypsarrhythmia: "eeg_hypsarrhythmia"
};

export type InterictalEEG = (typeof InterictalEEG)[keyof typeof InterictalEEG];

export const IctalEEG = {
    eeg_ictal_unavailable: "eeg_ictal_unavailable",
    eeg_ictal_normal: "eeg_ictal_normal",
    eeg_ictal_abnormal: "eeg_ictal_abnormal",

    eeg_staring_spell: "eeg_staring_spell",
    eeg_myoclonic_event: "eeg_myoclonic_event",
    eeg_seizures_generalized: "eeg_seizures_generalized",
    eeg_seizures_outside_TL: "eeg_seizures_outside_TL",
    eeg_focal_ictal_discharges: "eeg_focal_ictal_discharges",
    eeg_ictal_onset_gen_3Hz_discharges: "eeg_ictal_onset_gen_3Hz_discharges",
    eeg_ictal_sw_jerks: "eeg_ictal_sw_jerks",
    eeg_ictal_gen_fast: "eeg_ictal_gen_fast",
    eeg_ictal_lack_EEG_correlate: "eeg_ictal_lack_EEG_correlate",
    eeg_ictal_neonatal_myoclonus_no_EEG: "eeg_ictal_neonatal_myoclonus_no_EEG",
    eeg_ictal_low_fast: "eeg_ictal_low_fast",
    eeg_ictal_migrating: "eeg_ictal_migrating"
};

export type IctalEEG = (typeof IctalEEG)[keyof typeof IctalEEG];

export const SleepEEG = {
    eeg_sleep_unavailable: "eeg_sleep_unavailable",
    eeg_sleep_normal: "eeg_sleep_normal",
    eeg_sleep_abnormal: "eeg_sleep_abnormal",
    eeg_sw_nrem_sleep: "eeg_sw_nrem_sleep",
    eeg_sleep_drowsiness_increase: "eeg_sleep_drowsiness_increase",
    eeg_sleep_increase: "eeg_sleep_increase",
    eeg_sleep_burst_supression: "eeg_sleep_burst_supression",
    eeg_gen_fast_sleep: "eeg_gen_fast_sleep"
} as const;

export type SleepEEG = (typeof SleepEEG)[keyof typeof SleepEEG];

export type EEG = InterictalEEG | IctalEEG | SleepEEG;

export const Semiology = {
    nonmotor_sensory: "nonmotor_sensory",
    nonmotor_sensory_visual: "nonmotor_sensory_visual"
}

export type Semiology = (typeof Semiology) [keyof typeof Semiology];

export type Feature = OnsetDevelopment | EEG | Semiology;