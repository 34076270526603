import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';
import { hasPosteriorIED } from './EEG';
import { Development } from './Constants';

export class SeLFNIE extends Syndrome {
    
    getName() {
        return "SeLFNIE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 2/365, 7/12);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age range neonatal, but also infantile (day 2 to 7 months).");
        }
                
        return new No("Mandatory missing: Age range neonatal, but also infantile (day 2 to 7 months).");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let cluster = false;

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_clonic"];
        let result = this.getContainingSeizures(seizures, s1, true, annot, "focal clonic seizures", true, false);
        const clonic = result.found;
        
        if(clonic) {
            // Is the seizure type occuring in clusters?
            let s2 = defaultSeizure();
            s2.frequency = ["seizure_freq_cluster"];
            cluster = this.containsSeizure(result.seizures, s1);
        }
      
        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_tonic"];
        result = this.getContainingSeizures(seizures, s1, true, annot, "focal tonic seizures", true, false);
        const tonic = result.found;
        
        if(!cluster && tonic) {
            // Is the seizure type occuring in clusters? We only need to test this here if clustering
            // has not been found so far
            let s2 = defaultSeizure();
            s2.frequency = ["seizure_freq_cluster"];
            cluster = this.containsSeizure(result.seizures, s2);
        }

        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic"];
        result = this.getContainingSeizures(seizures, s1, true, annot, "foal autonomic seizures", true, false);
        const autonomic = result.found;

        if(!cluster && autonomic) {
            // Is the seizure type occuring in clusters? We only need to test this here if clustering
            // has not been found so far
            let s2 = defaultSeizure();
            s2.frequency = ["seizure_freq_cluster"];
            cluster = this.containsSeizure(result.seizures, s2);
        }

        s1 = defaultSeizure();
        s1.details = ["focbilattc"];
        const foc2bilat = this.containsSeizure(seizures, s1, true, annot, "focal to bilateral tonic-clonic seizures", true, false);
        
        if(!cluster && foc2bilat) {
            // Is the seizure type occuring in clusters? We only need to test this here if clustering
            // has not been found so far
            s1.frequency = ["seizure_freq_cluster"];
            cluster = this.containsSeizure(seizures, s1);
        }        

        if(cluster) {
            annot.push("Mandatory present: Some or all of the specific seizures occur in clusters.");
        } else {
            annot.push("Mandatory missing: Some or all of the specific seizures occur in clusters.");
        }

        if((clonic || tonic || foc2bilat) && cluster) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        const gen = this.containsSeizure(seizures, s1, false, annot, "Generalized seizures");

        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_myclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Focal myoclonic seizures");

        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Focal myoclonic seizures");
        
        if(gen || myoclonic || spasms) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine "
                +"mandatory normal development at onset and during the course and exclusionary encephalopathy.");
        }

        const annot: string[] = new Array();
        const normal = this.checkFeatures(dev, ["dev_normal", "dev_course_normal"], true, annot);
        const encephalopathy = hasFeature(dev, "dev_encephalopathy");
        
        if(encephalopathy) {
            annot.push("Exlusionary: Encephalopathy at onset.");
        }

        if(normal && !encephalopathy) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine normal exam at onset and during the course.");
        }

        const annot: string[] = new Array();
        const normal = hasFeature(exam, "exam_normal") && hasFeature(exam, "exam_course_normal");
        if(normal) {
            annot.push("Mandatory present: Normal neurological examination");
        } else {
            annot.push("Mandatory missing: Normal neurological examination");
        } 

        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        // Mandatory : focal interictal epileptiform abnormalities in posterior regions
        // Exclusionary : burst-supression, persistent focal slowing, Hypsarrhytmia

        const annot: string[] = new Array();
        const posterior = hasPosteriorIED(eeg, false, false);
        if(posterior) {
            annot.push("Mandatory present: Either posterior IED or IED without further specification.");
        } else {
            annot.push("Mandatory missing: Either posterior IED or IED without further specification.");
        }
        
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_burst_supression", "eeg_focal_slowing", "eeg_hypsarrhythmia", "eeg_ictal_lack_EEG_correlate"], false, annot);
        
        if(posterior && exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory family history of SeLNE.");
        }

        const annot: string[] = new Array();
        const selne = this.checkFeature(history, "history_family_SeLNE", true, annot);

        if(selne) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory normal imaging.");
        }
        
        const normal = hasFeature(imaging, "imaging_normal");
        if(normal) {
            return new Yes("Mandatory present: Neuroimaging normal");
        } else {
            return new No("Exlsionary present: Abnormal neuroimaging");
        }
    }

    getAlertCriteria(): string[] {
        return ["Sequential seizures", "Significant abnormalities on neurological examination", "Mild neurodevelopment delay long-term", "drug resistant epilepsy",
            "Mild background slowing on EEG"];
    }

}