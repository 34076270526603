import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AlertsState {
    alerts: string[];
}

const initialState: AlertsState = {
    alerts: []
}

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlerts: (state, action: PayloadAction<string[]>) => {
            state.alerts = action.payload;
        }
    }
});

export const { setAlerts } = alertsSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts;

export default alertsSlice.reducer;