import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { defaultSeizure, getCompatibleSeizures } from './Seizures';
import { Syndrome } from "./Syndrome";
import { hasFeature } from './Utils';

export class RS extends Syndrome {

    constructor() {
        super();
    }

    getName() {
        return "RS";
    }

    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, true, annot, "Focal seizures", false, false);
        
        const s = getCompatibleSeizures(seizures, s1);
        let inc = false;
        for(let seizure of s) {
            inc = seizure.frequency.indexOf("seizure_freq_inc") > -1;
            if(inc) {
                annot.push("Mandatory present: Increasing seizure frequency over weeks to months.");
                break;
            }
        }
        if(!inc) {
            annot.push("Mandatory missing: Increasing seizure frequency over weeks to months. Syndrome is however still kept as likely.");
        }

        if(focal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        const generalized = this.containsSeizure(seizures, s1, false, annot, "Generalized seizures", false, false); 

        if(generalized) {
            return new No(annot);
        } else {
            return new Unclear(annot);
        }
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine exlusionary features: Hemiparesis at onset.");
        }

        if(hasFeature(exam, "exam_hemiparesis_onset")) {
            return new No("Exlusionary present: Hemiparesis at onset");
        } else {
            return new Unclear("Exlusionary not present: Hemiparesis at onset");
        }
                
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        // Mandatory: hemispheric slowing and epileptiform abnormality
        const slow = eeg.indexOf("eeg_hemispheric_slowing") > -1;
        if(slow) {
            annot.push("Mandatory present: Hemispheric slowing");
        } else {
            annot.push("Mandatory missing: Hemispheric slowing");
        }

        const abnorm = eeg.indexOf("eeg_hemispheric_abnorm") > -1;
        if(abnorm) {
            annot.push("Mandatory present: Hemispheric epileptiform abnormalities");
        } else {
            annot.push("Mandatory missing: Hemispheric epileptiform abnormalities");
        }

        const focal_ictal = eeg.indexOf("eeg_focal_ictal_discharges") > -1;
        if(focal_ictal) {
            annot.push("Mandatory present: Focal ictal discharges");
        } else {
            annot.push("Mandatory missing: Focal ictal discharges");
        }

        if(focal_ictal && slow && abnorm) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const sturge = imaging.indexOf("imaging_sturge_weber") > -1;
        if(sturge) {
            annot.push("Exclusionary present: Signs of Sturge-Weber syndrome on imaging.");
        } else {
            annot.push("Exclusionary not present: Signs of Sturge-Weber syndrome on imaging.");
        }

        const hemi = imaging.indexOf("imaging_progressive_hemiatrophy") > -1;
        if(hemi) {
            annot.push("Mandatory present: Progressive hemiatrophy.");
        } else {
            annot.push("Mandatory missing: Progressive hemiatrophy.");
        }

        if(hemi && !sturge) {
            return new Yes(annot);
        }
        if(sturge) {
            return new No(annot);
        }
        return new Unclear(annot);
    }

    getAlertCriteria(): string[] {
        return ["Age at onset > 10 years", "Abnormal development prior to seizure onset", "Generalized spike-and-wave", 
            "Focal seizure onset independently in both hemispheres (only 2% of RS is bilateral)",
            "Lack of hyperintense signal and/or atrophy of the ipsilateral caudate head, and/or lack of T2/FLAIR hyperintense signal of gray or white matter"];
    }

}