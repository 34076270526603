import { Button, Modal } from "react-bootstrap";
import intl from 'react-intl-universal';
import { Classificator, ClassificatorStage } from "../../classification/Classificator";
import { Syndrome } from "../../classification/Syndrome";

type ExplanationModelProps = {
    classifier: Classificator,
    classifierStage: ClassificatorStage,
    syndrome: Syndrome,
    onHide: () => void,
    show: boolean
}

function ExplanationModal(props: ExplanationModelProps) {
    const annotations = props.classifier.getAnnotations(props.classifierStage, props.syndrome.getName());
    let list = [];
    for (let n=0;n<annotations.length;n++) {
        list.push(<li key={n}>{annotations[n]}</li>);
    }

    return (
        <div className="modal show" style={{ display: "block", position: "initial" }}>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.get(props.syndrome.getName())}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ul>{list}</ul>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ExplanationModal;