import { Outlet } from "react-router-dom";
import Navigation from "../../components/Navigation";
import ClassifierPanel from "../Results/ClassifierPanel";
import AccountView from "../../components/AccountView";
import CommentPanel from "../DevelopPages/CommentPanel";

function FeaturePageLayout() {
	return (
		<div>
			<Navigation />
			<div className="gap_above_large">
                <Outlet />
            </div>
			<ClassifierPanel />
			<CommentPanel />
			<AccountView />
		</div>
	);
}

export default FeaturePageLayout;
