import intl from 'react-intl-universal';
import { Onset, MainType, TimeOfDay } from './Constants';

/**
 * Interface for a seizure
 * @param remarks optional Map with further remarks, which are only used for documentation, 
 *  not for classification or comparison with other seizures
 */
export interface Seizure {
    mainType: MainType;
    details: string[];
	onset: Onset;
	awareness: string;
	timepoint: string;
    timeOfDay: TimeOfDay[];
	duration: string[];
	triggers: string[];
	frequency: string[];
    other: string[];
    remarks?: Map<string, string>;
}

export function defaultSeizure(): Seizure {
    return {
        mainType: "*",
        details: ["*"],
        onset: "*",
        awareness: "*",
        timepoint: "*",
        timeOfDay: ["*"],
        duration: ["*"],
        triggers: ["*"],
        frequency: ["*"],
        other: ["*"]
    };
}

export function emptySeizure(): Seizure {
    return {
        mainType: "",
        details: [],
        onset: "",
        awareness: "",
        timepoint: "",
        timeOfDay: [""],
        duration: [""],
        triggers: [""],
        frequency: [""],
        other: [""]
    };
}

export function getCompatibleSeizures(seizures: Seizure[], s: Seizure) {
    let result: Seizure[] = [];
    for(let seizure of seizures) {
        let comp = compareSeizures(seizure, s);
        if(comp > 0) {
            result.push(seizure);
        }
    }
    return result;
}

/**
 * Compare seizures s1 and s2
 * @param s1 seizure 1
 * @param s2 seizure 2
 * @returns 0 if the seizures are not compatible (e.g. different mainTypes, etc.), not identical or s2 is not contained in s1, 
 * 1 if they are exactly the same and 2 if s2 is contained in s1 (i.e. s1 has additional triggers, frequencies or details).
 */
export function compareSeizures(s1: Seizure, s2: Seizure) {
    // Check main features. If these are not identical (or specified as "*"),
    // then the seizures are not compatible
    if(!compareStringWithWildcard(s1.mainType, s2.mainType) 
        || !compareStringWithWildcard(s1.onset, s2.onset)
        || !compareStringWithWildcard(s1.awareness, s2.awareness)
        || !compareStringWithWildcard(s1.timepoint, s2.timepoint)) {
        return 0;
    }

    // Compare details
    let comparison = 1;
    let comp = compareArrays(s1.details, s2.details);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    }

    // Compare triggers
    comp = compareArrays(s1.triggers, s2.triggers);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    }  
    
    // Compare durations
    comp = compareArrays(s1.duration, s2.duration);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    }  
    
    // Compare frequency
    comp = compareArrays(s1.frequency, s2.frequency);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    } 

    // Compare time of day
    comp = compareArrays(s1.timeOfDay, s2.timeOfDay);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    } 
    
    // Compare other
    comp = compareArrays(s1.other, s2.other);
    switch(comp) {
        case 0: 
        case 2: return 0;
        case 3: comparison = 2; break; 
    } 

    return comparison;
}

export function compareStringWithWildcard(s1: string, s2: string) {
    if(s1 === "*") {
        return true;
    }

    let options: string[];
    if(s2.indexOf('|') >= 0) {
        options = s2.split("|");
    } else {
        options = [s2];
    }
    
    for(let option of options) {
        if(s1 === option || option === "*") {
            return true;
        }
    }
    return false;
}

/**
 * Compare 2 arrays
 * @param a1 array 1
 * @param a2 array 2
 * @returns 0 if the two arrays are completely different,
 * 1 if a1 and a2 are identical (in terms of elements, not necessarily sequence), 
 * 2 if a1 is contained in a2, 3 if a2 is contained in a1.
 * If either array contains a "*" wildcard in the first field, the arrays are considered 
 * identical and 1 is returned.
 */
export function compareArrays(a1: string[], a2: string[]) {
    if(a1 === undefined) {
        a1 = [];
    }   
    if(a2 === undefined) {
        a2 = [];
    }
    // if both arrays are empty, consider them identical
    if(a1.length === 0 && a2.length === 0)
        return 1;

    // If either array contains a "*" wildcard in the first field,
    // consider the arrays identical
    if((a1.length > 0 && a1[0] === "*") || (a2.length > 0 && a2[0] === "*"))
        return 1;
    
    // if either array is empty, consider them different
    if(a1.length === 0 || a2.length === 0)
        return 0;

    let a1MissingFromA2 = a1.filter(item => a2.indexOf(item) < 0);
    let a2MissingFromA1 = a2.filter(item => a1.indexOf(item) < 0);

    if(a1MissingFromA2.length === 0 && a2MissingFromA1.length === 0) {
        return 1;
    } else if (a1MissingFromA2.length === 0) {
        return 2;
    } else if (a2MissingFromA1.length === 0) {
        return 3;
    }
    return 0;
}

export function constructSemiology(details: string[]) {
    let semiology = [];
    let k = 0;
    for (let n = 0; n < details.length; n++) {
        if (details[n].startsWith("motor_") || details[n].startsWith("nonmotor_")) {
            if (k > 0) {
                semiology.push(", " + intl.get(details[n]));
            } else {
                semiology.push(intl.get(details[n]));
            }

            k++;
        }
    }
    if (details.indexOf("focbilattc") > -1) {
        if (k > 0) semiology.push(", " + intl.get("focbilattc"));
        else semiology.push(intl.get("focbilattc"));
    }
    return semiology;
}

export function constructList(items: string[]) {
    let s = "";
    if(items.length > 0) {
        if(items[0] !== "") 
            s = intl.get(items[0]);
    }
    for(let i=1;i<items.length;i++) {
        if(s !== "") {
            s += ", ";
        }
        s += intl.get(items[i]);
    }

    return s;
}

export function constructSeizureType(mainType: string, awareness: string) {
    let seizureType = mainType;
    if (mainType === "focal") {
        if (awareness === "aware") {
            seizureType = "Focal aware";
        } else if (awareness === "impaired awareness") {
            seizureType = "Focal with impaired awareness";
        } else {
            seizureType = "Focal, awareness unknown";
        }
    }
    return seizureType;
}

export function constructItem(item: string) {
    return intl.get(item);
}

function ensureSeizureExists(index: number, features: Map<string, any>): Seizure {
    let seizures = features.get("seizures");
    if (seizures === undefined) {
        seizures = [];
        features.set("seizures", seizures);
    }

    let seizure = seizures[index];
    if(seizure === undefined) {
        seizure = emptySeizure();
        seizures[index] = seizure;
    }
    return seizure;
}

export function getNumberOfSeizures(features: Map<string, any>): number {
    let seizures = features.get("seizures");
    if (seizures === undefined) {
        return 0;
    } else {
        return seizures.length;
    }
}

export function setMainType(index: number, features: Map<string, any> | null, mainType: MainType) {
    if(features !== null) {
        let seizure = ensureSeizureExists(index, features);
        seizure.mainType = mainType;
    }
}

export function setDetails(index: number, features: Map<string, any>, details: string[], onset: Onset, awareness: string) {
    let seizure = ensureSeizureExists(index, features);
    seizure.details = details;
    seizure.onset = onset;
    seizure.awareness = awareness;
}

export function setExtraFeatures(index: number, features: Map<string, any>, timepoint: string, timeOfDay: TimeOfDay[], 
    duration: string[], triggers: string[], frequency: string[], other: string[]) {
    let seizure = ensureSeizureExists(index, features);
    seizure.timepoint = timepoint;
    seizure.timeOfDay = timeOfDay;
    seizure.duration = duration;
    seizure.triggers = triggers;
    seizure.frequency = frequency;
    seizure.other = other;
}

export function setRemark(index: number, features: Map<string, any>, remarkKey: string, remarkValue: string) {
    let seizures = features.get("seizures");
    // Only add the remark when the seizure exists, don't create a new seizure instance only for the remark.
    if (seizures === undefined || index >= seizures.length || index < 0) {
        return;
    }
    seizures = [...seizures];
    let seizure = seizures[index];
    if(seizure.remarks === undefined) {
        seizure.remarks = new Map();
    }
    seizure.remarks.set(remarkKey, remarkValue);
    return seizures;
}

export function deleteSeizure(index: number, features: Map<string, any>) {
    let seizures = features.get("seizures");
    if (seizures === undefined || index >= seizures.length || index < 0) {
        return;
    }
    seizures = [...seizures];
    seizures.splice(index, 1);
    return seizures
}

export function getSeizure(index: number, features: Map<string, any>): Seizure | null {
    let seizures = features.get("seizures");
    if (seizures === undefined || index >= seizures.length) {
        return null;
    }
    return seizures[index];
}