import { Development } from "./Constants";
import { FeatureResponse, Yes, No, Unclear, Unresolved } from "./FeatureResponse";
import { defaultSeizure } from "./Seizures";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class JME extends Syndrome {

    getName() {
        return "JME";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        if(super.isAgeInRange(features, 0, 8)) {
            return new No("Exclusionary: Age < 8 years");
        }
        if(super.isAgeInRange(features, 40, 999)) {
            return new No("Exclusionary: Age > 40 years");
        }
        if(super.isAgeInRange(features, 10, 24)) {
            return new Yes("Mandatory present: Age between 10 and 24 years");
        }
        return new Unclear();
    }

    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let mandatoryOk = false;
        for (const seizure of seizures) {
            if (seizure.mainType === "generalized" && seizure.onset === "motor") {
                mandatoryOk = seizure.details.indexOf("motor_gen_myoclonic") > -1;
                if (mandatoryOk) {
                    break;
                }
            }
        }
        
        if(mandatoryOk) {
            return new Yes("Mandatory seizure types present: Myoclonic generalized seizures");
        } else {
            return new No("Mandatory seizure types missing: Myoclonic generalized seizures");
        }
    }

    checkExclusionarySeizures(seizures: any): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic"];
        const tonic = this.containsSeizure(seizures, s1, false, annot, "Generalized tonic seizures");
        
        s1.details = ["motor_gen_atonic"];
        const atonic = this.containsSeizure(seizures, s1, false, annot, "Generalized atonic seizures");

        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_atypical"];
        const atypical = this.containsSeizure(seizures, s1, false, annot, "Atypical absences");
        
        s1.details = ["nonmotor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Myoclonic absences");

        s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, false, annot, "Focal onset seizures");

        let exclusionaryOk = !tonic && !atonic && !atypical && !myoclonic && !focal;
        
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const normal = hasFeature(dev, "dev_normal"); // specifying normal overrules other disability specifications
        const exclusionaryOk = normal || this.checkFeatures(dev, ["dev_disability_moderate", "dev_disability_profound"], false, annot);
        if(!exclusionaryOk)
            return new No(annot);
        
        return new Unclear();
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(exam, ["exam_progressive_myoclonus", "exam_course_progressive_myoclonus"], false, annot);

        if(!exclusionaryOk) {
            return new No(annot);
        }
        return new Unclear();
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Imaging not available, no abnormal imaging known, which would otherwise argue against JAE");
        }

        const annot: string[] = [];
        const normal = this.checkFeatures(imaging, ["imaging_normal"], true, annot);
        if(normal) {
            return new Yes(annot);
        }
        return new No("Abnormal imaging argues against JME");
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_gen_sw"], true, annot);
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_unilateral_discharges", "eeg_focal_slowing", "eeg_gen_slow_sw", "eeg_background_slowing", "eeg_myoclonic_event"], false, annot);
        
        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot); // only yes, if no exclusionary features
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset 8-9 or 25-40 years", "Mild intellectual disability", "Abnormal neurological exam"];
    }

}