import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';
import { Development } from './Constants';
import { hasOnlyImpairedAtOnset } from './Development';

export class SWS extends Syndrome {
    
    getName() {
        return "SWS";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic", "focbilattc"];
        const autonomic = this.containsSeizure(seizures, s1, true, annot, "Focal autonomic seizures with focal to bilateral tonic-clonic evolution", false, false);     
        
        const annot2: string[] = [];
        s1.details = ["nonmotor_autonomic"];
        const autonomic2 = this.containsSeizure(seizures, s1, true, annot2, "Focal autonomic seizures. Focal to bilateral tonic-clonic evolution "
            + "is considered mandatory but was not specified: Syndrome not excluded.", false, false);     

        if(autonomic) {   
            return new Yes(annot);
        } else if(autonomic2) {
            return new Yes(annot2);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        const annot: string[] = new Array();
        let disability = hasAnyFeatures(dev, ["dev_disability_ns", "dev_disability_mild", "dev_disability_moderate", "dev_disability_profound"]);
        
        if(disability) {
            annot.push("Mandatory present: Mild to profound (or not further specified) intellectual disability at onset.");
        } else {
            if(hasOnlyImpairedAtOnset(dev)) {
                annot.push("Mandatory missing: Mild to profound intellectual disability (or not further specified), but impaired development at onset without further details specified, syndrome not ruled out.");
                disability = true;
            } else {
                annot.push("Mandatory missing: Mild to profound intellectual disability (or not further specified).");
            }  
        }
        
        if(disability) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const normal = hasFeature(imaging, "imaging_sws");
        if(normal) {
            return new Yes("Mandatory present: MRI showing leptomeningeal enhancement suggestive of leptomeningeal angioma, with cortical calcification and focal cerebral atrophy developing with time.");
        } else {
            return new No("Mandatory missing: MRI showing leptomeningeal enhancement suggestive of leptomeningeal angioma, with cortical calcification and focal cerebral atrophy developing with time");
        }
    }

    getAlertCriteria(): string[] {
        return ["Lack of abnormal neurological examination", "Lack of facial capillary hemangioma affecting the V1 dermatome.", 
            "Lack of asymmetrical background with reduction in voltage and slowing over the affected hemisphere (especially >12m of age)",
            "MRI done prior to 2 months of age normal"];
    }

}