import { Container, Form, FormGroup, Stack } from "react-bootstrap";
import { FeatureCheck } from "../../components/FeatureCheck";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateFromEvent } from "./Utils";
import { addFeature, addFeatures, removeFeature, removeFeatures } from "../../classification/Utils";
import { GroupedFeatureRadio } from "../../components/GroupedFeatureRadio";
import { setDevelopmentFeatures } from "../../features/features/featuresSlice";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureRadio } from "../../components/FeatureRadio";
import { GroupedFeatureCheck } from "../../components/GroupedFeatureCheck";

function DevelopmentPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);

    let temp = features.get("development") === undefined ? ["dev_normal", "dev_course_normal"] as string[] : features.get("development");
    const [development, setDevelopment] = useState(temp);

    // Main state of development at onset (normal, impaired/abnormal or not available)
    const [devAtOnset, setDevAtOnset] = useState("dev_normal");
    const devAtOnsetChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let dev = [...development];
        removeFeatures(dev, ["dev_normal", "dev_abnormal", "dev_unavailable"]);
        addFeature(dev, e.currentTarget.value);
        setDevAtOnset(e.currentTarget.value);
        setDevelopment(dev);
    }

    // Main state of development during the course (normal, impaired/abnormal or not available)
    const [devAtCourse, setDevAtCourse] = useState("dev_course_normal");
    const devAtCourseChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let dev = [...development];
        removeFeatures(dev, ["dev_course_normal", "dev_course_abnormal", "dev_course_unavailable"]);
        addFeature(dev, e.currentTarget.value);
        setDevAtCourse(e.currentTarget.value);
        setDevelopment(dev);
    }
    
    // Further infos
    temp = features.get("devAge") === undefined ? "" : features.get("devAge");
    const [devAge, setDevAge] = useState(temp);

    temp = features.get("devAgeUnit") === undefined ? "years" : features.get("devAgeUnit");
    const [devAgeUnit, setDevAgeUnit] = useState(temp);

    temp = features.get("devAgeTest") === undefined ? "" : features.get("devAgeTest");
    const [devAgeTest, setDevAgeTest] = useState(temp);

    temp = features.get("devFreeText") === undefined ? "" : features.get("devFreeText");
    const [devFreeText, setDevFreeText] = useState(temp);

    // Further event handlers
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, development, setDevelopment);
    const devIntChanged = (mainFeatureSelected: boolean, mainFeature: string, option: string, previous: string) => {
        let dev = [...development];
        removeFeature(dev, previous);    
        if(mainFeatureSelected) {
            addFeature(dev, option); 
            addFeature(dev, mainFeature);       
        } else {
            removeFeature(dev, option);
            removeFeature(dev, mainFeature);
        }      
        setDevelopment(dev);
    }

    const groupedSelectionChanged = (mainFeature: string, mainFeatureSelected: boolean, selection: string[], features: string[]) => {
        let temp = [...development];
        removeFeatures(temp, features);
        removeFeature(temp, mainFeature);
        if(mainFeatureSelected) {
            addFeatures(temp, selection);
            addFeature(temp, mainFeature);
        }
        setDevelopment(temp);
    }

    const abnormal = devAtOnset === "dev_abnormal";
    const abnormalCourse = devAtCourse === "dev_course_abnormal";

    useEffect(() => {
        dispatch(setDevelopmentFeatures({development: development, devAge: devAge, devAgeUnit: devAgeUnit, devFreeText: devFreeText, devAgeTest: devAgeTest}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.DEVELOPMENT}));
    });

    return (
        <Container>
            <Form>
                <h2>Development</h2>
                <h3>Development at onset</h3>
                <p>Did the patient show any developmental abnormalities at onset?</p>

                <FormGroup>
                    <FeatureRadio feature="dev_normal" label="Normal development" selection={devAtOnset} onChange={devAtOnsetChanged} />
                    <FeatureRadio feature="dev_abnormal" label="Impaired development" selection={devAtOnset} onChange={devAtOnsetChanged} />
                    <FeatureRadio feature="dev_unavailable" label="Info not available" selection={devAtOnset} onChange={devAtOnsetChanged} />
                </FormGroup>
                
                { abnormal && <div className="gap_above_small">
                    <GroupedFeatureRadio mainFeature={"dev_int_disability"} mainLabel={"Intellectual disability"} 
                        features={["dev_disability_ns", "dev_disability_mild", "dev_disability_moderate", "dev_disability_profound"]} 
                        labels={["Intellectual disability (not specified)", "Mild intellectual disability", "Moderate intellectual disability", "Profound intellectual disability"]} 
                        featureArray={development} onChange={devIntChanged} />

                    <FormGroup>
                        <GroupedFeatureCheck mainFeature={"dev_impairment"} mainLabel={"Neurodevelopmental impairment"} 
                            features={["dev_delay", "dev_severe_delay"]}
                            labels={["Delay of psychomotor development (any degree)", "Severe developmental delay"]}
                            onChange={groupedSelectionChanged}/>
                        <FeatureCheck feature="dev_regression" label="Progressive neurocognitive regression/decline or developmental slowing/plateauing/stagnation" featureArray={development} onChange={handleChange} />                         
                        <FeatureCheck feature="dev_encephalopathy" label="Encephalopathy" featureArray={development} onChange={handleChange} />
                        <FeatureCheck feature="dev_dee_swas_regression" label="Cognitive, behavioral or motor regression or plateauing temporally related to spike-wave activation in sleep on EEG" featureArray={development} onChange={handleChange} /> 
                        <FeatureCheck feature="dev_asd" label="Autism spectrum disorder" featureArray={development} onChange={handleChange} /> 
                    </FormGroup>
                </div> }
                
                <h3 className="gap_above_small">Development during the further course</h3>
                <p>Did the patient show any developmental abnormalities in the further course?</p>
                <FormGroup>
                    <FeatureRadio feature="dev_course_normal" label="Normal development" selection={devAtCourse} onChange={devAtCourseChanged} />
                    <FeatureRadio feature="dev_course_abnormal" label="Impaired development" selection={devAtCourse} onChange={devAtCourseChanged} />
                    <FeatureRadio feature="dev_course_unavailable" label="Info not available" selection={devAtCourse} onChange={devAtCourseChanged} />
                </FormGroup>
                
                { abnormalCourse && <div className="gap_above_small">
                    <GroupedFeatureRadio mainFeature={"dev_course_int_disability"} mainLabel={"Intellectual disability"} 
                        features={["dev_course_disability_ns", "dev_course_disability_mild", "dev_course_disability_moderate", "dev_course_disability_profound"]} 
                        labels={["Intellectual disability (not specified)", "Mild intellectual disability", "Moderate intellectual disability", "Profound intellectual disability"]} 
                        featureArray={development} onChange={devIntChanged} />

                    <FormGroup>
                    <GroupedFeatureCheck mainFeature={"dev_course_impairment"} mainLabel={"Neurodevelopmental impairment"} 
                            features={["dev_course_delay", "dev_course_severe_delay"]}
                            labels={["Delay of psychomotor development (any degree)", "Severe developmental delay"]}
                            onChange={groupedSelectionChanged}/>
                        <FeatureCheck feature="dev_course_regression" label="Progressive neurocognitive regression/decline or developmental slowing/plateauing/stagnation" featureArray={development} onChange={handleChange} />
                        <FeatureCheck feature="dev_course_encephalopathy" label="Encephalopathy" featureArray={development} onChange={handleChange} />
                        <FeatureCheck feature="dev_course_dee_swas_regression" label="Cognitive, behavioral or motor regression or plateauing temporally related to spike-wave activation in sleep on EEG" featureArray={development} onChange={handleChange} /> 
                        <FeatureCheck feature="dev_course_asd" label="Autism spectrum disorder" featureArray={development} onChange={handleChange} /> 
                    </FormGroup>
                </div> }

                <Form.Group className="gap_above">
                    <Form.Label>Developmental age if available:</Form.Label>
                    <Stack direction="horizontal" gap={2}>
                            <Form.Control type="text" placeholder="Enter developmental age" value={devAge} onChange={(e) => setDevAge(e.target.value)} />
                            <Form.Check type="radio" label="years" checked={devAgeUnit === "years"} value="years" onChange={(e) => setDevAgeUnit("years")} />
                            <Form.Check type="radio" label="months" checked={devAgeUnit === "months"} value="months" onChange={(e) => setDevAgeUnit("months")}
                            />
                    </Stack>
                    <Form.Label>Test used to determine developmental age:</Form.Label>
                    <Form.Control type="text" placeholder="Enter test use to determine developmental age" value={devAgeTest} onChange={(e) => setDevAgeTest(e.target.value)} />
                </Form.Group>
                <Form.Group className="gap_above_small">
                    <Form.Label>Other (please specify free text):</Form.Label>
                    <Form.Control as="textarea" rows={3} value={devFreeText} onChange={(e) => setDevFreeText(e.target.value)} />
                </Form.Group>
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/NeuroExam")} previous={() => navigate("/pages/SeizureSummary")} />
        </Container>
        );
}

export default DevelopmentPage;