import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import ReactMarkdown from "react-markdown";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Classificator, ClassificatorStage } from "../../classification/Classificator";
import { getSyndrome } from "../../classification/Syndromes";

type SyndromeData = {
    syndrome: string,
    description: string
}

export async function loader({params}: any): Promise<SyndromeData> {
    const url = "/data/"+params.syndrome + ".md";
    const md = await fetch(url, { method: "get" });
    const description = await md.text();
    return { syndrome: params.syndrome, description: description };
}

function SyndromePage() {
    const data = useLoaderData() as SyndromeData; 
    const navigate = useNavigate();
    const features = useAppSelector(state => state.features.features);
    const alerts = useAppSelector(state => state.alerts.alerts);
    
    // alerts contains all selected alert criteria (format: <syndrome name>-<index in syndrome alert list>)
    // therefore, we have to select the relevant ones here.
    let syndromeAlerts: string[] = [];
    const syndrome = getSyndrome(data.syndrome); 

    if(syndrome !== null) {
        const allAlerts = syndrome.getAlertCriteria();
        if(allAlerts !== null) {    
            for(let alert of alerts) {
                const elem = alert.split("-");
                if(data.syndrome === elem[0]) {
                    const index = +elem[1];
                    syndromeAlerts.push(allAlerts[index]);
                }
            }
        }
    }

    const anyAlert = syndromeAlerts === undefined ? false : syndromeAlerts.length > 0;

    // Now get annotations about the classification decisions
    const classifier = new Classificator();
	const results = classifier.check(features, ClassificatorStage.RESULTS);
	const annotations = classifier.getAnnotations(ClassificatorStage.RESULTS, data.syndrome);

    let alertText = "You have indicated the presence of one alert criterion";
    let alertList = [];
    if(syndromeAlerts.length> 1) {
        alertText = `You have indicated the presence of ${syndromeAlerts.length} alert criteria`;
        for (let n=0;n<syndromeAlerts.length;n++) {
            alertList.push(<li key={n}>{syndromeAlerts[n]}</li>);
        }
    }

    let list = [];
    for (let n=0;n<annotations.length;n++) {
        list.push(<li key={n}>{annotations[n]}</li>);
    }

    let classificationResult = "";
    let found = false;
    for(let syndrome of results.likely) {
        if(syndrome.getName() === data.syndrome) {
            classificationResult = "Likely";
            found = true;
            break;
        }
    }

    if(!found) {
        for(let syndrome of results.unclear) {
            if(syndrome.getName() === data.syndrome) {
                classificationResult = "Unclear";
                found = true;
                break;
            }
        }
    }

    if(!found) {
        classificationResult = "Unlikely";
    }


    return (
        <div>
            <Container>
                {anyAlert && (
                    <Row>
                        <Col md={12}>
                            <Alert variant="danger">
                                {alertText}. Alert criteria are absent in the vast majority of cases, but
                                rarely can be seen. Their presence should result in caution in diagnosing the syndrome and consideration of other
                                conditions.
                                {alertList}
                            </Alert>
                        </Col>
                    </Row>
                )}
                
                <Row>
                    <Col md={12}>
                        <ReactMarkdown children={data.description} />
                    </Col>
                </Row>
                <Row className="gap_above">
                    <Col md={12}>
                        <h2>Algorithm results</h2>
                        <p><b>Result:</b> {classificationResult}</p>
                        {list}
                    </Col>
                </Row>
                <Row>
                    <Button className="gap_above" variant="secondary" onClick={(e) => navigate("/Pages/Results")}>
                        Back
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

export default SyndromePage;