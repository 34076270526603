import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { Development } from './Constants';
import { hasMandatoryDevFeature } from './Development';

export class CDKL5DEE extends Syndrome {
    
    getName() {
        return "CDKL5DEE";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "unknown";
        const unknown = this.containsSeizure(seizures, s1, true, annot, "Unknown seizures", false, false);

        s1.mainType = "unclassified";
        const unclassified = this.containsSeizure(seizures, s1, true, annot, "Unclassified seizures", false, false);

        s1 = defaultSeizure();
        s1.mainType = "focal|sequential";
        s1.onset = "motor";
        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, true, annot, "Focal/sequential seizures with epileptic spasms", false, false);

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_epileptic_spasms"];
        const gen_spasms = this.containsSeizure(seizures, s1, true, annot, "Generalized epileptic spasms", false, false);

        s1.details = ["motor_gen_tonic"];
        const gen_tonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic seizures", false, false);

        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tonic_clonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic-clonic seizures", false, false);

        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistant seizures", false, true);

        // s1 = defaultSeizure();
        // s1.mainType = "seizure_sequential";
        // const sequential = this.containsSeizure(seizures, s1, true, annot, "Sequential seizures");

        if(dre && (unknown || unclassified || spasms || gen_spasms || gen_tonic || gen_tonic_clonic)) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = hasMandatoryDevFeature(dev, "dev_disability_profound", annot, "Profound intellectual disability", false, true);
                
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        const geneticTestsAvailable = this.checkFeatures(tests, ["test_genetic"], true, [], true);
        if(!testsAvailable || !geneticTestsAvailable) {
            return new Yes("Genetic testing is not available however should be performed to identify CDKL5 mutations.");
        }
        
        const annot: string[] = [];
        const gene = this.checkFeature(tests, "test_genetics_cdkl5", true, annot);
        
        return gene ? new Yes(annot) : new Unclear(annot);
    }

    getAlertCriteria(): string[] {
        return ["Absence of epileptic spasms in the first year of life", "Onset > 3 months", "Normal development prior to seizure onset", "Normal tone and lack of encephalopathy", 
                "Normal EEG background without interictal discharges after 4 months of age"];
    }

}