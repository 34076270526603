import React from "react";
import { Col, Form } from "react-bootstrap";

export interface FeatureCheckProps {
    feature: string;
    label: string;
    featureArray: string[];
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    prerequisites?: string[];
    offset?: number;
}

export const FeatureCheck = (props: FeatureCheckProps) => {
    let show = true;
    if(props.prerequisites !== undefined) {
        for(let prereq of props.prerequisites) {
            show = show && props.featureArray.includes(prereq);
        }
    }
    const offset = props.offset !== undefined? props.offset : 0;
    
    if(show) {
        return <Col md={{offset: offset}}>
            <Form.Check checked={props.featureArray.indexOf(props.feature) > -1} type="checkbox" label={props.label} 
            value={props.feature} onChange={props.onChange}/>
        </Col>;
    } else {
        return <></>
    }
}