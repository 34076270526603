import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setOpen } from "../../features/devUI/devUISlice";
import { submitComment } from "../../requests/store";
import { useState } from "react";

function CommentPanel() {
    const dispatch = useAppDispatch();
    const allowed = useAppSelector(state => state.devUI.allowed);
    const open = useAppSelector(state => state.devUI.open);
    const [showMessage, setShowMessage] = useState(false);
    const [comment, setComment] = useState("");
    const features = useAppSelector(state => state.features.features);

    const submit = async () => {
        const caseId = features.get("id");
        await submitComment(caseId, document.URL, comment);
        setComment("");
        setShowMessage(true);
        setTimeout(() => { setShowMessage(false) }, 3000);
    }

    return (
        <div>
            <Offcanvas show={open} placement="start" onHide={(e: any) => dispatch(setOpen(false))}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Comments</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>Add a comment about the current page here. It will be uploaded and used for validation and improvement of the app.</p>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control as="textarea" rows={15} value={comment} onChange={(e) => setComment(e.target.value)} />
                    <Button className="gap-above-small" variant={"secondary"} type="submit" onClick={submit}>
                        Submit
                    </Button>
                    {showMessage &&
                        <Alert className="gap-above-small" key='success' variant='success'>
                            Comment submitted.
                        </Alert>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );

}

export default CommentPanel;