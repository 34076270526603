import axios from 'axios';

export async function submit(userId: number, features: Map<string, any>): Promise<void> {
    const json = JSON.stringify(features, replacer);
    console.log(features);
    console.log(json);
    const response = await axios.post('/api/stores', {
        text: json
    });
    return response.data;
}

export async function submitFeedback(caseId: any, rating: number, feedback: string, diagnosis: string): Promise<void> {
    const response = await axios.post('/api/feedback', {
        caseId: caseId,
        text: feedback,
        rating: rating,
        diagnosis: diagnosis
    });
    return response.data;
}

export async function submitComment(caseId: any, page: string, comment: string): Promise<void> {
    const response = await axios.post('/api/comment', {
        caseId: caseId,
        page: page,
        comment: comment
    });
    return response.data;
}

export async function submitValidation(caseId: number, containedInLikely: boolean, othersInLikely: boolean, correctDiagnosis: string, comment: string): Promise<void> {
    const response = await axios.post('/api/validation', {
        caseId: caseId,
        containedInLikely: containedInLikely,
        othersInLikely: othersInLikely,
        correctDiagnosis: correctDiagnosis,
        comment: comment
    });
    return response.data;
}

function replacer(key: string, value: any) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

function reviver(key: string, value: any) {
    if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }