import { Development } from "./Constants";
import { FeatureResponse, No, Unclear, Unresolved, Yes } from "./FeatureResponse";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class GTCA extends Syndrome {
    
    getName(): string {
        return "GTCA";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        if(super.isAgeInRange(features, 0, 5)) {
            return new No("Exclusionary: Age at onset < 5 years");
        }
        if(super.isAgeInRange(features, 40, 999)) {
            return new No("Exclusionary: Age at onset > 40 years");
        }
        if(super.isAgeInRange(features, 10, 25)) {
            return new Yes("Mandatory: Age at onset between 10 and 25 years");
        }
        return new Unclear("Age not in a mandatory or exclusionary range.");
    }
    
    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let annotations: string[] = [];
        let mandatoryOk = false;
        for (const seizure of seizures) {
            const generalized = seizure.mainType === "generalized";
            const motor = seizure.onset === "motor";
            const tc = seizure.details.indexOf("motor_gen_tonic_clonic") > -1;
            mandatoryOk = generalized && motor && tc;
            if (mandatoryOk) {
                annotations.push("Mandatory: Generalized tonic-clonic seizures are present.");
                break;
            }
        }

        if(mandatoryOk) {
            return new Yes(annotations);
        } else {
            annotations.push("Mandatory missing: No generalized tonic-clonic seizures are present.");
            return new No(annotations);
        }
    }
    
    checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        let annotations: string[] = [];
        let exclusionaryOk = true;
        for (const seizure of seizures) {
            const generalized = seizure.mainType === "generalized";
            const motor = seizure.onset === "motor";
            const tc = seizure.details.indexOf("motor_gen_tonic_clonic") > -1;
            exclusionaryOk = (generalized && motor && tc) || seizure.mainType === "unclassified"; // everything else is exclusionary
            if (!exclusionaryOk) {
                annotations.push("Exclusionary: Seizures other than generalized tonic-clonic seizures are present.");
                break;
            }
        }
        
        if(exclusionaryOk) {
            annotations.push("Exclusionary not present: No seizures other than generalized tonic-clonic seizures are present.");
            return new Yes(annotations);
        } else {
            return new No(annotations);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine exlusionary features.");
        }

        if (hasFeature(dev, "dev_disability_moderate") || hasFeature(dev, "dev_disability_profound") || hasFeature(dev, "dev_regression")) {
            return new No("Exclusionary: Moderate or profound intellectual disability at onset or progressive cognitive decline.");
        }
        return new Unclear(); // No mandatory features, so everything else not checked up here doesn't change anything
    }

    checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_gen_sw"], true, annot);
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_unilateral_discharges", "eeg_focal_slowing", "eeg_gen_slow_sw", "eeg_background_slowing"], false, annot);

        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot); // only yes, if no exclusionary features
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Imaging not available, no abnormal imaging known, which would otherwise argue against JAE");
        }

        const annot: string[] = [];
        const normal = this.checkFeatures(imaging, ["imaging_normal"], true, annot);
        if(normal) {
            return new Yes(annot);
        }
        return new No("Abnormal imaging argues against GTCA");
    }
    
    getAlertCriteria(): string[] {
        return ["Age at onset 5-9 or 26-40 years", "Mild intellectual disability", "Abnormal neurological exam", "Abnormal neuroimaging"];
    }
}