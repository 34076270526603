import { Syndrome } from './Syndrome';
import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class SHE extends Syndrome {

    getName() {
        return "SHE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 2 / 12, 64);
    }

    checkMandatorySeizures(seizures: any): FeatureResponse {
        const annot: string[] = [];

        // Check for the mandatory hyperkinetic, tonic motor focal seizures
        let mandatoryOk = false;
        for (const seizure of seizures) {
            if (seizure.mainType === "focal" && seizure.onset === "motor") {
                const hyperkinetic = seizure.details.indexOf("motor_hyperkinetic") > -1;
                if(hyperkinetic) {
                    annot.push("Mandatory hyperkinetic seizures present.");
                }
                const tonic = seizure.details.indexOf("motor_tonic") > -1;
                if(tonic) {
                    annot.push("Mandatory tonic seizures present.");
                }

                if(!hyperkinetic && !tonic) {
                    annot.push("Neither hyperkinetic nor tonic mandatory seizures present.")
                }

                const sleep = seizure.timepoint === "wake_sleep_unknown" || seizure.timepoint === "sleep_predominant" || seizure.timepoint === "sleep_only";
                if(sleep) {
                    annot.push("Mandatory present (referring to seizure type above): Seizures either predominantly or only from sleep or not known.");
                } else {
                    annot.push("Mandatory missing (referring to seizure type above): Seizures either predominantly or only from sleep or not known.");
                }

                const duration = seizure.duration === "seizure_duration_unknown" || seizure.duration === "seizure_duration_brief";
                if(duration) {
                    annot.push("Mandatory present (referring to seizure type above): Seizure duration either brief or not known.");
                } else {
                    annot.push("Mandatory missing (referring to seizure type above): Seizure duration either brief or not known.");
                }
                
                // Only check if the mandatory seizures have not been found yet.
                // Don't break then though to keep collection explanations.
                if (!mandatoryOk) {
                    mandatoryOk = (hyperkinetic || tonic) && sleep && duration;
                }
            }
        }

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    checkExclusionarySeizures(seizures: any): FeatureResponse {
        let exclusionaryOk = true;
        for (const seizure of seizures) {
            exclusionaryOk = seizure.mainType !== "generalized" && seizure.timepoint !== "wake_only";
            if (!exclusionaryOk) {
                break;
            }
        }
        
        if(exclusionaryOk) {
            return new Yes();
        } else {
            return new No();
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine exlusionary features.");
        }

        if(hasFeature(dev, "dev_disability_moderate") || hasFeature(dev, "dev_disability_profound"))
            return new No("Exlusionary: Moderate or profound intellectual disability at onset.");
        return new Unclear();
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        if (hasFeature(exam, "exam_normal") && hasFeature(exam, "exam_course_normal")) {
            return new Yes("Mandatory present: Normal neurological examination");
        } else if (hasFeature(exam, "exam_focal") || hasFeature(exam, "exam_course_focal")) {
            return new No("Exclusionary: Focal neurological sign");
        } else {
            return new Unclear();
        }
    }

    getAlertCriteria(): string[] {
        return ["Seizures predominantly from the awake state", "Age at onset <10 or >20 years", "Frequent epileptiform abnormality outside of the frontal regions and/or generalized epileptiform abnormality"];
    }
}