import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import intl from 'react-intl-universal';
import App from "./App";
require('intl/locale-data/jsonp/en.js');


const locales = {
    "en-US": require('./locales/en-US.json')
};

const container = document.getElementById("root")!;
const root = createRoot(container);

intl.init({
	currentLocale: 'en-US',
	locales,
}).then(() => {
	root.render(
		<React.StrictMode>
			<Provider store={store}>
				{/**<RouterProvider router={router} /> */}
				<App />
			</Provider>
		</React.StrictMode>
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
}).catch((reason: any) => {
	console.log(reason);
})
