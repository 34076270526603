import { Container, Form, FormGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSeizure } from "../../classification/Seizures";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setSeizureMainType } from "../../features/features/featuresSlice";
import { setAllowed, setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";

function SeizureTypePage() {
    const features = useAppSelector(state => state.features.features);
    const currentSeizure = useAppSelector(state => state.seizure.currentSeizure);
    
    const seizure = getSeizure(currentSeizure, features);
    let temp = seizure === undefined ? 'focal' : seizure?.mainType;
    const [mainType, setMainType] = useState(temp === undefined ? 'focal' : temp);

    const popoverMigrating = (
        <Popover id="popover-basic" arrowProps={{left: '10%'}}>
            <Popover.Header as="h3">Migrating seizures</Popover.Header>
            <Popover.Body>
                Focal seizures can arise in both hemispheres and migrate from one cortical region to another within a seizure. 
                Clinically, migration is characterized by unilateral focal tonic or clonic activity at seizure onset, 
                which then evolves to contralateral focal tonic or clonic activity over the course of the seizure.
            </Popover.Body>
        </Popover>
    );

    const popoverSequential = (
        <Popover id="popover-basic" arrowProps={{left: '10%'}}>
            <Popover.Header as="h3">Sequential seizures</Popover.Header>
            <Popover.Body>
                Sequential refers to several seizure manifestations occurring in sequence (not necessarily simultaneously) in a given seizure, 
                and not manifestations in different seizure types.
            </Popover.Body>
        </Popover>
    );

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
        
    useEffect(() => {
        dispatch(setSeizureMainType({currentSeizure: currentSeizure, mainType: mainType}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: false, stage: ClassificatorStage.SEIZURES}));
    });

    return (
        <Container>
            <Form>
                <h2>Seizure types</h2>
                <p>What types of seizures does the patient suffer from?</p>
                <p>Please define a fist type of seizures that the patient suffers from. In subsquent steps, you can define further types if needed.</p>
                <p>Reference: <a rel="noopener noreferrer" href="https://www.ilae.org/guidelines/definition-and-classification/operational-classification-2017" target="_blank">https://www.ilae.org/guidelines/definition-and-classification/operational-classification-2017</a></p>
                <FormGroup>
                    <Form.Check checked={mainType === "focal"} type="radio" label="Focal onset" id="Focal" onChange={(e: any) => setMainType("focal")}/>
                    <Form.Check checked={mainType === "generalized"} type="radio" label="Generalized onset" id="Generalized" onChange={(e: any) => setMainType("generalized")}/>
                    <Form.Check checked={mainType === "unknown"} type="radio" label="Unknown onset" id="Unknown"  onChange={(e: any) => setMainType("unknown")}/>
                    <Form.Check checked={mainType === "unclassified"} type="radio" label="Unclassified seizures" id="Unclassified" onChange={(e: any) => setMainType("unclassified")}/>

                    <OverlayTrigger overlay={popoverSequential} placement="right-start">
                        <div className="fit">
                        <Form.Check className="gap_above_small" checked={mainType === "sequential"} type="radio" label="Sequential seizures" id="Sequential" 
                            onChange={(e: any) => setMainType("sequential")}/>
                        </div>
                    </OverlayTrigger>

                    <OverlayTrigger overlay={popoverMigrating} placement="right-start">
                        <div className="fit">
                        <Form.Check checked={mainType === "migrating"} type="radio" label="Migrating seizures" id="Migrating" 
                            value="migrating" onChange={(e: any) => setMainType("migrating")} />
                        </div>
                    </OverlayTrigger>
                </FormGroup>
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/SeizureDetails")} previous={() => navigate("/pages/Age")} />
        </Container>
        );
}

export default SeizureTypePage;