import { SetStateAction } from "react";

export function updateFromEvent(event: React.FormEvent<HTMLInputElement>, array: string[], updateFn: (value: SetStateAction<string[]>) => void): void {
    let value = event.currentTarget.value;
    let values = [...array];
    const index = values.indexOf(value);
    if(index > -1) {
        values.splice(index, 1);
    } else {
        values.push(value);
    }

    updateFn(values);
}