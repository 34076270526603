import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { deleteSeizure, setDetails, setExtraFeatures, setMainType, setRemark } from "../../classification/Seizures";
import { Onset } from '../../classification/Constants';

export interface FeaturesState {
    features: Map<string, any>;
    submitted: boolean;
}

const initialState: FeaturesState = {
    features: new Map(),
    submitted: false
}

export const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {
        setFeatures: (state, action: PayloadAction<Map<string, any>>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            
            action.payload.forEach((value: any, key: string) => {
                features.set(key, value);
            });
            state.features = features;
            return state;      
        },
        clearFeatures: (state) => {
            return {
                features: new Map<string, any>(), 
                submitted: false
            };
        },
        setSeizureMainType: (state, action: PayloadAction<{currentSeizure: number, mainType: string}>) => {
            setMainType(action.payload.currentSeizure, state.features, action.payload.mainType);
            return state;
        },
        setSeizureDetails: (state, action: PayloadAction<{currentSeizure: number, details: string[], onset: Onset, awareness: string}>) => {
            setDetails(action.payload.currentSeizure, state.features, action.payload.details, action.payload.onset, action.payload.awareness);
            return state;
        },
        setSeizureExtraFeatures: (state, action: PayloadAction<{currentSeizure: number, timepoint: string, timeOfDay: string[], duration: string[], triggers: string[], frequency: string[], other: string[]}>) => {
            setExtraFeatures(action.payload.currentSeizure, state.features, action.payload.timepoint, 
                action.payload.timeOfDay, action.payload.duration, action.payload.triggers, 
                action.payload.frequency, action.payload.other);
            return state;
        },
        setSeizureRemark: (state, action: PayloadAction<{currentSeizure: number, remarkKey: string, remarkValue: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            let seizures = setRemark(action.payload.currentSeizure, features, action.payload.remarkKey, action.payload.remarkValue);
            features.set("seizures", seizures);

            state.features = features;
            return state;
        },
        removeSeizure: (state, action: PayloadAction<number>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            let seizures = deleteSeizure(action.payload, features);
            features.set("seizures", seizures);

            state.features = features;
            return state;
        },
        setDevelopmentFeatures: (state, action: PayloadAction<{development: string[], devAge: string, devAgeUnit: string, devFreeText: string, devAgeTest: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("development", action.payload.development);
            features.set("devAge", action.payload.devAge);
            features.set("devAgeUnit", action.payload.devAgeUnit);
            features.set("devFreeText", action.payload.devFreeText);
            features.set("devAgeTest", action.payload.devAgeTest);

            state.features = features;
            return state;
        },
        setNeuroExamFeatures: (state, action: PayloadAction<{exam: string[], examFreeText: string, examCourseFreeText: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("neuroexam", action.payload.exam);
            features.set("neuroexamFreeText", action.payload.examFreeText);
            features.set("neuroexamCourseFreeText", action.payload.examCourseFreeText);

            state.features = features;
            return state;
        },
        setEEGFeatures: (state, action: PayloadAction<{eeg: string[], swFreq: string, otherSleepFindings: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }

            features.set("eeg", action.payload.eeg);
            features.set("swFreq", action.payload.swFreq);
            features.set("eeg_other_sleep_findings", action.payload.otherSleepFindings);
            
            state.features = features;
            return state;
        },
        setHistoryFeatures: (state, action: PayloadAction<{history: string[], personalFreeText: string, 
            familyFreeText: string, treatmentFreeText: string, otherFreeText: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("history", action.payload.history);
            features.set("personalFreeText", action.payload.personalFreeText);
            features.set("familyFreeText", action.payload.familyFreeText);
            features.set("treatmentFreeText", action.payload.treatmentFreeText);
            features.set("otherFreeText", action.payload.otherFreeText);
            
            state.features = features;
            return state;
        },
        setImagingFeatures: (state, action: PayloadAction<{imaging: string[], otherImaging: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("imaging", action.payload.imaging);
            features.set("otherImaging", action.payload.otherImaging);
            
            state.features = features;
            return state;
        },
        setTestsFeatures: (state, action: PayloadAction<{tests: string[], genTestsFree: string, bloodTestsFree: string}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("tests", action.payload.tests);
            features.set("genTestsFree", action.payload.genTestsFree);
            features.set("bloodTestsFree", action.payload.bloodTestsFree);
            
            state.features = features;
            return state;
        },
        setAlertCriteria: (state, action: PayloadAction<{alerts: string[]}>) => {
            let features: Map<string, any>;
            if(state.features !== null) {
                features = state.features;
            } else {
                features = new Map<string, any>();
            }
            features.set("alertCriteria", action.payload.alerts);
            
            state.features = features;
            return state;
        },
        setSubmitted: (state) => {
            state.submitted = true;
            return state;
        },

    }
});

export const { setFeatures, clearFeatures, setSeizureMainType, setSeizureDetails, setSeizureExtraFeatures, setSeizureRemark,
    removeSeizure, setDevelopmentFeatures, setNeuroExamFeatures, setEEGFeatures, setHistoryFeatures, setImagingFeatures,
    setTestsFeatures, setAlertCriteria, setSubmitted } = featuresSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFeatures = (state: RootState) => state.features.features;
export const selectSubmitted = (state: RootState) => state.features.submitted;

export default featuresSlice.reducer;