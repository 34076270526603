import { FeatureResponse, No, Unclear, Unresolved, Yes } from "./FeatureResponse";
import { defaultSeizure, getCompatibleSeizures } from "./Seizures";
import { Syndrome } from "./Syndrome";
import { hasAnyFeatures } from "./Utils";

export class EAF extends Syndrome {
    
    getName() {
        return "EAF";
    }
    
    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 0.5, 54);
    }
    
    checkMandatorySeizures(seizures: any): FeatureResponse {
        // Check for the mandatory nonmotor cognitive or sensory seizures
        let annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.details = [];
        const focalMainType = getCompatibleSeizures(seizures, s1).length > 0;
        
        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_cognitive"];
        let cognitive = this.containsSeizure(seizures, s1, true, annot, "Focal cognitive seizures");

        s1.details = ["nonmotor_sensory_auditory"];
        let sensory = this.containsSeizure(seizures, s1, true, annot, "Focal sensory seizures");
        
        if(cognitive || sensory) {
            return new Yes(annot);
        } else if(focalMainType) {
            return new Yes("Mandatory present: Exact seizure type (focal cognitive or sensory seizures) not found, but focal seizure without further info specified.");
        } else {
            return new No(annot);
        }
    }

    checkExclusionarySeizures(seizures: any): FeatureResponse {
        let annotations: string[] = new Array();
        let exclusionaryOk = true;
        for (const seizure of seizures) {
            exclusionaryOk = seizure.mainType !== "generalized";
            if (seizure.mainType === "focal") {
                const motor = seizure.onset == "motor";
                const autonomic = seizure.details.indexOf("nonmotor_autonomic") > -1;
                const behaviorarrest = seizure.details.indexOf("nonmotor_behavioral_arrest") > -1;
                const emotional = seizure.details.indexOf("nonmotor_emotional") > -1;
                exclusionaryOk = exclusionaryOk && !motor && !autonomic && !behaviorarrest && ! emotional;
            }
            if (!exclusionaryOk) {
                annotations.push("Exclusionary: Generalized or focal motor, autonomic, emotional or focal seizures with behavioral arrest are present.");
                break;
            }
        }
        
        if(exclusionaryOk) {
            annotations.push("Exclusionary not present: No generalized or focal motor, autonomic, emotional or focal seizures with behavioral arrest are present.");
            return new Yes(annotations);
        } else {
            return new No(annotations);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const response = this.checkFeatures(imaging, ["imaging_normal", "imaging_fcd"], false, annot);
        if(response) {
            return new Yes(annot);
        } else {
            return new Unclear(); // no exclusionary imaging findings
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset <0.5 or >54 years", "Intellectual disability of any degree", "Abnormal neurological exam", "Generalized epileptiform abnormalities on EEG"];
    }
}