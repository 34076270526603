import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';

export class HHE extends Syndrome {
    
    getName() {
        return "HHE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let older = super.checkAge(features, 6, 999);
        if(older.isYes()) {
            return new No("Exclusionary: Age at onset older than 6y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.details = ["*"];
        s1.other = ["seizure_other_hhe"];
        const hasSeizure = this.containsSeizure(seizures, s1, true, annot, "focal seizures with acute (febrile, hemiclonic status epilepticus) and chronic stage (focal motor, focal to bilateral tonic-clonic seizures)", false, false);

        if(hasSeizure) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.other = ["seizure_other_todd"];
        const hasS2 = this.containsSeizure(seizures, s1);

        s1.onset = "motor";
        s1.other = ["*"];
        s1.frequency = ["seizure_freq_crescendo"];        
        const hasS3 = this.containsSeizure(seizures, s1);

        if(hasS2) {
            annot.push("Exclusionary present: Transient hemiparesis (Todd paresis)");
        } 
        if(hasS3) {
            annot.push("Exclusionary present: Focal motor seizures that progress in crescendo pattern over months to years");
        } 

        if(!hasS2 && !hasS3) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(exam, ["exam_late_hemiparesis"], false, annot);
                
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const focal_multifocal = this.checkFeature(eeg, "eeg_ied", true, annot);
                
        const slow = hasFeature(eeg, "eeg_slowing");
        const background_slowing = hasFeature(eeg, "eeg_background_slowing");
        const other_slowing = hasAnyFeatures(eeg, ["eeg_hemifocal_slowing", "eeg_hemispheric_slowing", "eeg_focal_slowing"]);
        let slowingOk = false;
        if(slow) {
            if(background_slowing) {
                slowingOk = true;
                annot.push("Mandatory present: Background slowing");
            } else if(other_slowing) {
                slowingOk = false;
                annot.push("Mandatory missing: Slowing specifies, but only types not typical for HHE");
            } else {
                slowingOk = true;
                annot.push("Mandatory present: Slowing");
            }
        }
            
        if(slowingOk && focal_multifocal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Imaging is not available however a lesion on MRI explaining the hemiplegia would exclude HHE.");
        }
        
        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(imaging, ["imaging_lesion_hemiplegia"], false, annot);
        
        if(!exclusionaryOk) {
            return new No(annot);
        } 
        return new Unclear();
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        if(!testsAvailable) {
            return new Unclear("Test results are not available however may contain exlusionary features: Lumbar puncture showing evidence of central nervous system infection.");
        }

        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(tests, ["test_lp_infection"], false, annot);

        if(!exclusionaryOk) {
            return new No(annot);
        } 
        return new Unclear();
    }

    getAlertCriteria(): string[] {
        return ["Age >4y", "Intellectual disability of any degree", "Any abnormal neurological sign", "Facial angioma"];
    }

}