import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class SeLEAS extends Syndrome {
    
    getName() {
        return "SeLEAS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 3, 6);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset between 4 and 17y.");
        }
        let younger = super.checkAge(features, 0, 1);
        if(younger.isYes()) {
            return new No("Exclusionary: Age at onset <1y.");
        }
        let older = super.checkAge(features, 14, 999);
        if(older.isYes()) {
            return new No("Exclusionary: Age at onset >14y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic"];
        const hasS1 = this.containsSeizure(seizures, s1);

        const annot: string[] = new Array();
        if(hasS1) {
            annot.push("Mandatory: Focal autonomic seizures present.");
        } else {
            annot.push("Mandatory focal autonomic seizures missing.");
        }

        if(hasS1) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine exlusionary neurocognitive regression.");
        }

        if(hasFeature(dev, "dev_regression")) {
            return new No("Exlusionary: Neurocognitive regression");
        }
        return new Unclear();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();
        const focal = this.checkFeature(eeg, "eeg_focal", true, annot);
        const multifocal = this.checkFeature(eeg, "eeg_multifocal", true, annot);
        const increaseDrowsiness = this.checkFeatures(eeg, ["eeg_sleep_drowsiness_increase"], true, annot);
        const increaseSleep = this.checkFeatures(eeg, ["eeg_sleep_increase"], true, annot);
        
        if((focal || multifocal) && (increaseDrowsiness || increaseSleep)) {
            return new Yes(annot);
        } else {
            return new Unclear();
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(imaging, ["imaging_lesion"], false, annot);

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new Unclear();
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset <3y or >8y", "Seizure frequency greater than monthly", "Moderate or profound intellectual disability", "Any abnormal neurological sign or sensory deficit",
            "Sustained focal slowing not limited to the post-ictal phase", "Unilateral focal abnormalities in a consistent focal area across serial EEGs"];
    }

}