import { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import Form from 'react-bootstrap/Form';
import { useAppDispatch } from "../app/hooks";
import { submitFeedback } from "../requests/store";

function Survey(params: any) {
    const [submitted, setSubmitted] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const dispatch = useAppDispatch();

    const handleRatingChange = (value: number) => {
        if (value >= 0 && value <= 5) {
            setRating(value);
        }
    }

    const onSubmit = async() => {
        await submitFeedback(params.caseId, rating, feedback, diagnosis);
        setSubmitted(true);
    }

    if (!submitted)
            return (
                <Col md={12}>
                    <Row>
                        <h3>Rating and Feedback</h3>
                        <p>How do you like ENGenE?</p>
                    </Row>
                    <Row>
                        <Rating style={{maxWidth: "300px"}} value={rating} onChange={handleRatingChange} />
                    </Row>
                    <Row className="gap_above">
                        <h3>Feedback and Comments</h3>
                        <p>Any feedback, suggestions or comments? Let us know!</p>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="feedbackForm.textarea">
                            <Form.Control as="textarea" rows={3} value={feedback} onChange={event => setFeedback(event.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row className="gap_above">
                        <h3>Information for ENGenE research</h3>
                        <p>Information provided here will be used for research using ENGenE, e.g. to investigate diagnostic accuracy of the app.</p>
                    </Row>
                    <Row>
                    <Form.Group className="mb-3" controlId="feedbackForm.textarea">
                            <Form.Label>Actual diagnosis (if known)</Form.Label>
                            <Form.Control type="text" value={diagnosis} onChange={event => setDiagnosis(event.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Button variant={"secondary"} onClick={onSubmit}>Submit</Button>
                    </Row>
                </Col>
            );
        else if (showMessage)
            return (
                <Alert>
                    Thank you!
                </Alert>
            );
        else
            return null;
}

export default Survey;