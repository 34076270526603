import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures, hasFeature, hasNoneOfFeatures } from './Utils';
import { OnsetDevelopment } from './Constants';

export class CAE extends Syndrome {
    
    getName() {
        return "CAE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 4, 13);
    }

    getAlertCriteria(): string[] {
        return ["Age <2y or >13y", "Seizures occur < daily in an untreated patient", "Atypical seizure duration (> 30s)", "Generalized tonic-clonic seizures", "Mild intellectual disability at onset",
                "Any abnormal neurological examination", "Consistently unilateral epileptiform discharges", "Lack of HV activated 2.5-4HZ generalized Spike-wave in untreated patients (HV >3 min)",
                "Recording a typical starting spell without EEG correlate in a child with a history of 2.5-4 Hz generalized spike-wave"];
    }

    checkMandatorySeizures(seizures: any): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        const generalized = this.containsSeizure(seizures, s1);
        if(generalized) {
            return new Yes("Mandatory present: Generalized seizures");
        }
        return new No("Mandatory missing: Generalized seizures");
    }

    checkExclusionarySeizures(seizures: any): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1);
        if(focal) {
            return new No("Exclusionary present: Focal seizures");
        }
        return new Yes("Exclusionary not present: Focal seizures");
    }

    protected checkDevelopmentDetail(dev: OnsetDevelopment[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {   
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        if(hasFeature(dev, "dev_normal")) {
            return new Yes("Mandatory present: Normal development at onset");
        }
        
        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(dev, ["dev_disability_moderate", "dev_disability_profound", "dev_regression"], false, annot);
        if(!exclusionaryOk)
            return new No(annot);
        
        return new Unclear();
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        if (hasFeature(exam, "exam_focal")) {
            return new No("Exclusionary: Any focal neurological sign.");
        } else {
            return new Unclear();
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const mandatoryOk = hasAnyFeatures(eeg, ["eeg_ictal_onset_gen_3Hz_discharges"]);
        const exlusionaryOk = hasNoneOfFeatures(eeg, ["eeg_background_slowing"])

        if(mandatoryOk) {
            if(exlusionaryOk) {
                return new Yes("Mandatory 3Hz paroxysms at seizure onset and no exclusionary background slowing present.");
            } else {
                return new No("Mandatory 3Hz paroxysms at seizure onset but exclusionary background slowing present.");
            }
        } else {
            if(exlusionaryOk) {
                return new No("Mandatory 3Hz paroxysms at seizure onset missing.");
            } else {
                return new No("Mandatory 3Hz paroxysms at seizure onset missing and exclusionary background slowing present.");
            }
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        if(hasAnyFeatures(imaging, ["imaging_normal"])) {
            return new Yes("Mandatory: Imaging normal");
        } else {
            return new Unclear("Unclear: Imaging not normal");
        }
    }
}