import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature, hasOnlyAllowedFeatures } from './Utils';
import { hasNormalBackground } from './EEG';
import { Development } from './Constants';

export class GEFS extends Syndrome {
    
    getName() {
        return "GEFS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 0, 6);
        if(mandatory.isYes()) {
            return new Yes("Age at onset usually <6y.");
        }
                
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        return new Yes("No mandatory seizure types.");
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Generalized seizures with epileptic spasms");

        if(!spasms) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine normal development at onset and during the course.");
        }

        const annot: string[] = [];
        const normal = this.checkFeatures(dev, ["dev_normal", "dev_course_normal"], true, annot);
        
        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine normal exam at onset and during the course.");
        }

        const annot: string[] = [];
        const normal = hasFeature(exam, "exam_normal") && hasFeature(exam, "exam_course_normal");
        if(normal) {
            annot.push("Mandatory present: Normal neurological examination");
        } else {
            annot.push("Mandatory missing: Normal neurological examination");
        } 

        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        // Exclusionary : burst-supression, persistent focal slowing, Hypsarrhytmia
        const annot: string[] = [];
        const normalBackground = hasNormalBackground(eeg);

        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_burst_supression", "eeg_focal_slowing", "eeg_hypsarrhythmia", "eeg_ictal_lack_EEG_correlate"], false, annot);
        
        if(normalBackground && exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory febrile seizures (focal orgeneralized), "
                + "persisting >6 years of age or associated with non-febrile seizures.");
        }

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeature(history, "history_febrile_GEFS", true, annot);
       
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory normal imaging.");
        }
        
        const normal = hasFeature(imaging, "imaging_normal");
        if(normal) {
            return new Yes("Mandatory present: Neuroimaging normal");
        } else {
            return new No("Exclusionary present: Abnormal neuroimaging");
        }
    }
}