import { Table } from "react-bootstrap";
import intl from "react-intl-universal";
import { SECTION } from "../../classification/Constants";
import { constructSeizureType, constructSemiology, constructItem, constructList } from "../../classification/Seizures";

export class Features {
	private features: Map<string, any>;

	constructor(features: Map<string, any>) {
		this.features = features;
	}

	generateInfo(section: SECTION) {
		const info = this.getSectionDescriptions(section);
		const list = [];
		if (info.length === 0) {
			list.push(<li key={0}>No info</li>);
		} else {
			for (let n = 0; n < info.length; n++) {
				list.push(<li key={n}>{info[n]}</li>);
			}
		}

		return list;
	}

	generateAgeInfos() {
		const age = this.getAgeDescription();
		const onsetAge = this.getAgeAtOnsetDescription();
		const gender = this.getGender();

		const list = [];
		list.push(<li key={0}>Gender: {gender}</li>);
		list.push(<li key={1}>Current age: {age}</li>);
		list.push(<li key={2}>Age at onset: {onsetAge}</li>);

		return list;
	}

	generateSeizuresInfo() {
		return this.getSeizureDescriptions();
	}

	getAgeDescription() {
		const age = this.features.get("age");
		const unit = this.features.get("ageUnit");
		if (age === undefined || age === "") {
			return "Unknown";
		}
		return age + " " + unit;
	}

	getAgeAtOnsetDescription() {
		const age = this.features.get("ageAtOnset");
		const unit = this.features.get("ageAtOnsetUnit");
		if (age === undefined || age === "") {
			return "Unknown";
		}
		return age + " " + unit;
	}

	getGender() {
		const gender = this.features.get("gender");
		if (gender === undefined || gender === "") return "Unkown";
		return intl.get(gender);
	}

	getSeizureDescriptions() {
		const rows = this.createSeizureTypeTableRows();
		return (
			<Table>
				<thead>
					<tr>
						<th>Type</th>
						<th>Onset</th>
						<th>Semiology</th>
						<th>Wake/Sleep</th>
						<th>Duration</th>
						<th>Triggers</th>
						<th>Frequency</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		);
	}

	private createSeizureTypeTableRows() {
		let seizures = this.features.get("seizures");
		if (seizures === undefined) {
			return;
		}

		let rows = [];
		for (const seizure of seizures) {
			const row = (
				<tr>
					<td>{constructSeizureType(seizure.mainType, seizure.awareness)}</td>
					<td>{seizure.onset}</td>
					<td>{constructSemiology(seizure.details)}</td>
					<td>{constructItem(seizure.timepoint)}</td>
					<td>{constructList(seizure.duration)}</td>
					<td>{constructList(seizure.triggers)}</td>
					<td>{constructList(seizure.frequency)}</td>
				</tr>
			);
			rows.push(row);
		}

		return rows;
	}

	getSectionDescriptions(section: SECTION): string | string[] {
		const data = this.features.get(section);
        
		if(data === undefined) {
			return "";
		}

		if (data instanceof Array) {
			const features = [];
			for (const element of data) {
                features.push(intl.get(element));
			}
			return features;
		} else {
			return intl.get(data);
		}
	}
}
