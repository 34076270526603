import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';
import { hasMandatoryDevFeature } from './Development';

export class IESS extends Syndrome {
    
    getName() {
        return "IESS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 1/12, 24/12);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset between 1 and 24 months.");
        }
        let older = super.checkAge(features, 24/12, 999);
        if(older.isYes()) {
            return new No("Exclusionary: Age at onset >24 months.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, true, annot, "Focal seizures with epileptic spasms", false, true);

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_epileptic_spasms"];
        const gen_spasms = this.containsSeizure(seizures, s1, true, annot, "Generalized seizures with epileptic spasms", false, true);

        s1 = defaultSeizure();
        s1.mainType = "unknown";
        s1.onset = "motor";
        s1.details = ["motor_unknown_epileptic_spasms"];
        const unknown_spasms = this.containsSeizure(seizures, s1, true, annot, "Seizures with unknown onsets and epileptic spasms", false, true);

        if(spasms || gen_spasms || unknown_spasms) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic"];
        const autonomic = this.containsSeizure(seizures, s1);

        s1.details = ["nonmotor_emotional"];
        const emotional = this.containsSeizure(seizures, s1);

        s1.details = ["nonmotor_sensory"];
        const sensory = this.containsSeizure(seizures, s1);

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tc = this.containsSeizure(seizures, s1);

        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_atypical"];
        const gen_atypical = this.containsSeizure(seizures, s1);

        const annot: string[] = [];
        if(autonomic) {
            annot.push("Exclusionary: Focal nonmotor onset autonomic seizures present.");
        }
        if(emotional) {
            annot.push("Exclusionary: Focal nonmotor emotional seizures present.");
        } 
        if(sensory) {
            annot.push("Exclusionary: Focal nonmotor sensory seizures present.");
        } 
        if(gen_tc) {
            annot.push("Exclusionary: Generalized tonic-clonic seizures present.");
        }
        if(gen_atypical) {
            annot.push("Exclusionary: Generalized atypical absence seizures present.");
        } 

        if(autonomic || emotional || sensory || gen_tc || gen_atypical) {
            return new No(annot);
        }
        return new Yes();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        // Todo: check for more specific focal abnormality features
        const focal = this.checkFeatures(eeg, ["eeg_focal"], true, annot);
        const multifocal = this.checkFeatures(eeg, ["eeg_multifocal"], true, annot);
        
        const lack = this.checkFeatures(eeg, ["eeg_ictal_lack_EEG_correlate"], false, annot);

        if(focal && multifocal && !lack) {
            return new Yes(annot);
        } else {
            return new Unclear(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset 1-2 months", "Focal tonic seizures", "Normal interictal EEG", "Suppression-burst patterns on EEG"];
    }

}