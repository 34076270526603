import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures } from './Utils';

export class FIRES extends Syndrome {
    
    getName() {
        return "FIRES";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let exclusionary = super.checkAge(features, 1, 30);
        if(exclusionary.isYes()) {
            return new No("Exlusionary: Age between 1 and 30 years.");
        }

        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.frequency = ["seizure_freq_inc_status"];
        const result = this.getContainingSeizures(seizures, s1, true, annot, "Focal seizures with increasing frequency", false, false);
        
        let foc2bilat = false;
        if(result.found) {
            s1 = defaultSeizure();
            s1.mainType = "focal";
            s1.details = ["focbilattc"];
            foc2bilat = this.containsSeizure(result.seizures, s1);
        }

        if(foc2bilat) {
            annot.push("Mandatory evolution to focal to bilateral tonic-clonic seizures present.");
        } else {
            annot.push("Mandatory evolution to focal to bilateral tonic-clonic seizures not found, but keeping syndrome.");
        }

        if(result.found) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }   
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeatures(exam, ["exam_encephalopathy"], true, annot);
        
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeatures(history, ["history_nonspecific_febrile"], true, annot);
        const exclusionaryOk = this.checkFeatures(history, 
            ["history_previous_epilepsy", "history_csf_antibodies"], 
            false, annot);
                
        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const ieds = hasAnyFeatures(eeg, ["eeg_multifocal"]);
        const slow = hasAnyFeatures(eeg, ["eeg_background_slowing"]);  
        const focalIctal = hasAnyFeatures(eeg, ["eeg_focal_ictal_discharges"]);
        
        const annot: string[] = [];
        if(ieds) {
            annot.push("Mandatory present: Multifocal epileptiform abnormalities");
        } else {
            annot.push("Mandatory missing: Multifocal epileptiform abnormalities");
        }
        if(slow) {
            annot.push("Mandatory present: Background slowing");
        } else {
            annot.push("Mandatory missing: Background slowing");
        }
        if(focalIctal) {
            annot.push("Mandatory present: Focal electrographic seizures");
        } else {
            annot.push("Mandatory missing: Focal electrographic seizures");
        }

        if(ieds && slow && focalIctal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        const lesion = hasAnyFeatures(imaging, ["imaging_lesion"]);
        
        if(lesion) {
            return new No("Exlusionary: Causal lesion on MRI.");
        } 
        return new Unclear();
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        if(!testsAvailable) {
            return new Unresolved("Further test results are not available however is critical to determine exlusionary features.");
        }

        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(tests, ["test_lp_infection", "test_csf_antibodies", "test_etiology_documented"], false, annot);
                
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age <2y", "Intellectual disability of any degree", "Any abnormal neurological sign prior to seizure onset", "Lack of drug-resistant focal or multifocal epilepsy", 
            "Lack of learning difficulties or intelectual disability", "Lack of variable degrees of cerebral atrophy on MRI", "Unifocal seizures"];
    }

}