import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';

export class GSHH extends Syndrome {
    
    getName() {
        return "GSHH";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_emotional"];
        const gelastic = this.containsSeizure(seizures, s1, true, annot, "Focal emotional seizures: Gelastic seizures with mechanical, mirthless laughter, inappropriate to context.", false, false);
        
        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistant seizures", false, true);

        if(dre && gelastic) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
        
    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeature(imaging, "imaging_hypothalamic_hamartoma", true, annot);

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset > 5y", "Clear developmental delay at seizure onset.", "Seizure frequency less than daily", "Focal neurological findings (other than Todd’s paresis) or generalized hypotonia.",
            "Lack of behavioral problems including aggression, impulsivity, and hyperactivity during the course of the illness.", "Generalized or focal background slowing (excluding immediate postictal period)"];
    }

}