import { hasFeature, hasNoneOfFeatures } from "./Utils"
import { onsetDevelopmentMinor, courseDevelopmentMinor } from "./Constants";
import intl from 'react-intl-universal';

export const hasOnlyImpairedAtOnset = (dev: string[]) => {
    const impaired = hasFeature(dev, "dev_abnormal");
    const noOther = hasNoneOfFeatures(dev, onsetDevelopmentMinor);

    return impaired && noOther;
}

export const hasOnlyImpairedDuringCourse = (dev: string[]) => {
    const impaired = hasFeature(dev, "dev_course_abnormal");
    const noOther = hasNoneOfFeatures(dev, courseDevelopmentMinor);

    return impaired && noOther;
}

export const hasOnlyImpaired = (dev: string[]) => {
    return hasOnlyImpairedAtOnset(dev) && hasOnlyImpairedDuringCourse(dev);
}

export const hasMandatoryDevFeature = (dev: string[], feature: string, annot: string[], desc: string, strict: boolean, atOnset: boolean) => {
    const featureFound = hasFeature(dev, feature);
    let general = false;
    if(!strict) {
        general = atOnset ? hasOnlyImpairedAtOnset(dev) : hasOnlyImpairedDuringCourse(dev);
    }

    if(featureFound) {
        annot.push(`Mandatory present: ${desc}`);
    } else {
        if(general) {
            const time = atOnset ? "at onset" : "during the course";
            annot.push(`Mandatory missing: ${desc}, but impaired development ${time} without further details specified, syndrome not ruled out.`);
        } else {
            annot.push(`Mandatory missing: ${desc}`);
        }    
    }
    
    return general || featureFound;
}

export const hasMandatoryDevFeatures = (dev: string[], features: string[], annot: string[], strict: boolean, atOnset: boolean, all: boolean) => {
    let ok = all;
    for(let feature of features) {
        let desc = intl.get(feature);
        let result = hasMandatoryDevFeature(dev, feature, annot, desc, strict, atOnset);
        ok = all ? ok && result : ok || result;
    }
    return ok;
}
