import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures } from './Seizures';
import { hasFeature, hasAnyFeatures } from './Utils';

export class DS extends Syndrome {
    
    getName() {
        return "DS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 1/12, 20/12);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Onset within 1-20 months of life.");
        }
                
        return new No("Mandatory missing: Onset within 1-20 months of life.");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        // TODO: Differentiate hemiclonic (i.e. cloni on alternating sides)?
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_clonic"];
        const clonic = this.containsSeizure(seizures, s1, true, [], "Focal clonic seizures");

        s1 = defaultSeizure();
        s1.details = ["focbilattc"];
        const foc2bilat = this.containsSeizure(seizures, s1, true, [], "Focal to bilateral tonic-clonic seizures");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_clonic"];
        const gen_clonic = this.containsSeizure(seizures, s1, true, [], "Generalized clonic seizures");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tonic_clonic = this.containsSeizure(seizures, s1, true, [], "Generalized tonic-clonic seizures (DS requires only clonic generalized seizures, but determination of tonic components may be difficult)");

        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.details = [];
        const focal_only = getCompatibleSeizures(seizures, s1).length > 0;

        s1.mainType = "generalized";
        const gen_only = getCompatibleSeizures(seizures, s1).length > 0;
        
        if(clonic && foc2bilat) {
            return new Yes("Mandatory present: Focal clonic and focal to bilateral tonic-clonic seizures");
        } else if(gen_clonic || gen_tonic_clonic) {
            return new Yes("Mandatory present: Generalized clonic seizures");
        } else if(focal_only) {
            return new Yes("Mandatory present: Exact seizure type not found (Focal clonic and focal to bilateral tonic-clonic seizure), but concordant focal seizures without further details.");
        } else if(gen_only) {
            return new Yes("Mandatory present: Exact seizure type not found (Generalized clonic seiuzres), but concordant generalized seizures without further details.");
        } else {
            return new No("Mandatory missing: Either focal clonic and focal to bilateral tonic-clonic seizures or generalized clonic seizures");
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Focal epileptic spasms");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_epileptic_spasms"];
        const gen_spasms = this.containsSeizure(seizures, s1, false, annot, "Generalized epileptic spasms");

        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistant seizures", false, true);

        if(dre && (spasms || gen_spasms)) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Imaging is not available, syndrome is not excluded. However a causal focal lesion would be exclusionary.");
        }
        
        const lesion = hasFeature(imaging, "imaging_lesion");
        if(lesion) {
            return new No("Exclusionary present: Causal focal lesion on imaging");
        } else {
            return new Unclear();
        }
    }

    getAlertCriteria(): string[] {
        return ["Onset > 15 months or < 2 months", "No history of prolonged seizures (>10 min)", "Lack of fever sensitivity as a seizure trigger",
            "Developmental delay at seizure onset", "Focal Neurological signs (other than Todd’s paralysis)", "Good efficacy with prophylactic sodium-channel agents including carbamazepine, oxcarbazepine, and phenytoin",
            "Normal EEG background without interictal discharges after age 2 years"];
    }

}