import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasFeature } from './Utils';
import { CourseExam, Development, OnsetExam } from './Constants';
import { hasMandatoryDevFeature } from './Development';

export class SeLIE extends Syndrome {
    
    getName() {
        return "SeLIE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 1/12, 3);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age range 1-36 months.");
        }
                
        return new No("Mandatory missing: Age range 1-36 months");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.awareness = "impaired awareness";
        s1.details = ["motor_clonic"];
        const clonic = this.containsSeizure(seizures, s1, true, annot, "focal clonic seizures with impaired awareness", true, false);
              
        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.awareness = "impaired awareness";
        s1.details = ["motor_automatisms"];
        const automatisms = this.containsSeizure(seizures, s1, true, annot, "focal seizures with automatisms and impaired awareness", true, false);

        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.awareness = "impaired awareness";
        s1.details = ["nonmotor_autonomic"];
        const autonomic = this.containsSeizure(seizures, s1, true, annot, "focal autonomic seizures (cyanosis) with impaired awareness", true, false);      

        if(clonic || automatisms || autonomic) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        const gen = this.containsSeizure(seizures, s1, false, annot, "Generalized seizures");

        s1 = defaultSeizure();
        s1.mainType = "sequential";
        const sequential = this.containsSeizure(seizures, s1, false, annot, "Sequential seizures");

        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_myclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Focal myoclonic seizures");

        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Focal epileptic spasms");

        s1.details = ["motor_tonic"];
        const tonic = this.containsSeizure(seizures, s1, false, annot, "Focal tonic seizures");
        
        if(gen || myoclonic || spasms || sequential || tonic) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine "
                +"exclusionary neurocognitive regression.");
        }

        const annot: string[] = [];
        let regression = hasMandatoryDevFeature(dev, "dev_regression", annot, "neurocognitive regression at onset", true, true);
        regression = regression && hasMandatoryDevFeature(dev, "dev_course_regression", annot, "neurocognitive regression during the course", false, false);
        
        if(!regression) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkNeuroExamDetails(exam: string[], examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological exam at onset and/or during the course is not available however is critical to determine "
                +"exclusionary ataxia with spasticity.");
        }

        const annot: string[] = [];
        let ataxia = hasFeature(exam, OnsetExam.exam_ataxia);
        let spasticity = hasFeature(exam, OnsetExam.exam_spasticity);
        if(ataxia) {
            if(spasticity) {
                annot.push("Exclusionary ataxia with spasticity at onset present.");
            } else {
                annot.push("Ataxia without spasticity at onset present, not excluded.");
            }            
        } else {
            if(spasticity) {
                annot.push("Spasticity without ataxia at onset present, not excluded.");
            } 
        }

        let ataxia_course = hasFeature(exam, CourseExam.exam_course_ataxia);
        let spasticity_course = hasFeature(exam, CourseExam.exam_course_spasticity);
        if(ataxia_course) {
            if(spasticity_course) {
                annot.push("Exclusionary ataxia with spasticity during the course present.");
            } else {
                annot.push("Ataxia without spasticity during the course present, not excluded.");
            }            
        } else {
            if(spasticity_course) {
                annot.push("Spasticity without ataxia during the course present, not excluded.");
            } 
        }

        if((ataxia && spasticity) || (ataxia_course && spasticity_course)) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }
    
    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine exlusionary features.");
        }

        // Exclusionary: persistent focal slowing or moderate or greater background slowing not limited to the postictal period, Hypsarrhythmia
        const annot: string[] = [];         
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_background_slowing", "eeg_focal_slowing", "eeg_hypsarrhythmia"], false, annot);
        
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory family history of SeLNE.");
        }

        const annot: string[] = [];
        const regressionPresent = this.checkFeature(history, "history_regression_SeLIE", false, annot);

        if(!regressionPresent) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine exlusionary causal lesion on imaging.");
        }
        
        if(hasFeature(imaging, "imaging_lesion")) {
            return new No("Exclusionary: Abnormal imaging with structural causal lesion");
        } 
        return new Unclear("Exclusionary not present: Abnormal imaging with structural causal lesion");
    }

    getAlertCriteria(): string[] {
        return ["Onset at 18-36 months of age", "Prolonged or focal clonic (hemiclonic) seizures (>10 min)", "Mild developmental delay",
            "Significant neurological examination abnormalities, excluding incidental findings", "Lack of remission by late childhood",
            "Mild background slowing on EEG"];
    }

}