import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures } from './Utils';

export class EEM extends Syndrome {
    
    getName() {
        return "EEM";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 6, 8);
        if(mandatory.isYes()) {
            return new Yes(mandatory.getAnnotations());
        }
        
        let younger = super.checkAge(features, 0, 2);
        if(younger.isYes()) {
            return new No("Exlusionary: Age at onset younger than 2y.");
        }

        let older = super.checkAge(features, 14, 999);
        if(older.isYes()) {
            return new No("Exclusionary: Age at onset older than 14y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_eyelid_myoclonia"];
        const hasS1 = this.containsSeizure(seizures, s1);

        if(hasS1) {
            return new Yes();
        }
        return new No();
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1);

        if(focal) {
            return new No("Exclusionary: Focal seizures present");
        }
        return new Yes();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();


        const eyeClosure = hasAnyFeatures(eeg, ["eeg_trigger_IPS"]);
        const sw_psw = hasAnyFeatures(eeg, ["eeg_gen_sw_psw"]);
        const ps = hasAnyFeatures(eeg, ["eeg_gen_ps"]);
        if(eyeClosure && sw_psw || eyeClosure && ps) {
            annot.push("Mandatory present: Eye closure and photic stimulation elicits generalized polyspikes or polyspike-and-wave complexes");
        } else {
            annot.push("Mandatory missing: Eye closure and photic stimulation elicits generalized polyspikes or polyspike-and-wave complexes");
        }

        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_focal_slowing", "eeg_unilateral_discharges", "eeg_background_slowing", "eeg_gen_slow_sw", "eeg_ictal_lack_EEG_correlate"], false, annot);
        
        if((eyeClosure && sw_psw) || (eyeClosure && ps)) {
            if(!exclusionaryOk) {
                return new No(annot);
            } else {
                return new Yes(annot);
            }
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        const lesion = hasAnyFeatures(imaging, ["imaging_lesion"]);
        const normal = hasAnyFeatures(imaging, ["imaging_normal"]);
        
        if(!normal) {
            if(lesion) {
                return new No("Exlusionary: Causal lesion on MRI.");
            } else {
                return new No("Mandatory missing: MRI is not normal.");
            }
        } else {
            return new Yes("Mandatory: MRI is normal.");
        }
    }

    getAlertCriteria(): string[] {
        return ["Age 2-6y or 8-14y", "(Mild) intellectual disability at onset of any degree", "Any abnormal neurological sign"];
    }

}