import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { clearFeatures } from "../features/features/featuresSlice";
import { setOpen } from "../features/classificationUI/classificationUISlice";
import { setOpen as setDevOpen } from "../features/devUI/devUISlice";
import { setUser } from "../features/user/userSlice";
import { showAccountView } from "../features/account/accountUISlice";
import { logOut } from "../requests/auth";

function Navigation() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const handleNav = (key: string | null) => {
		if (key === "NewCase") {
			dispatch(clearFeatures());
			navigate("/pages/Age");
		} else if (key === "Classification") {
			dispatch(setOpen(true));
		} else if (key === "Logout") {
			logOut().then(() => {
				dispatch(setUser(null));
			});
		} else if(key === "Account") {
			dispatch(showAccountView(true));
		} else if (key === "Comment") {
			dispatch(setDevOpen(true));
		} 
	};

	const user = useAppSelector((state) => state.user);

	const allowed = useAppSelector((state) => state.classificationUI.allowed);
	const devAllowed = useAppSelector((state) => state.devUI.allowed);

	return (
		<Navbar bg="dark" variant="dark" fixed="top" className="gap_below">
			<Container>
				<Navbar.Brand>ENGenE</Navbar.Brand>
				<Nav className="me-auto" onSelect={(selectedKey) => handleNav(selectedKey)}>
					<Nav.Link eventKey="NewCase">New case</Nav.Link>
				</Nav>
				{devAllowed && (
					<Nav onSelect={(selectedKey) => handleNav(selectedKey)}>
						<Nav.Link eventKey="Comment">Add comment</Nav.Link>
					</Nav>
				)}
				{allowed && (
					<Nav onSelect={(selectedKey) => handleNav(selectedKey)}>
						<Nav.Link eventKey="Classification">Classification</Nav.Link>
					</Nav>
				)}
				{user.user !== null &&
					<Nav onSelect={(selectedKey) => handleNav(selectedKey)}>
						<NavDropdown title={user.user.firstName+" "+user.user.name} id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"Account"}>Account</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"Logout"}>Logout</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				}
			</Container>
		</Navbar>
	);
}

export default Navigation;
