import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';

export class MEI extends Syndrome {
    
    getName() {
        return "MEI";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let inRange = super.checkAge(features, 4/12, 3);
        if(inRange.isYes()) {
            return new Yes("Age not in exclusionary range (<4 months or >3 years).");
        }
                
        return new No("Exclusionary present: Age <4 months or >3 years.");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, true, annot, "Generalized myoclonic seizures.", false, false);
        
        if(myoclonic) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, false, annot, "Focal seizures.");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "nonmotor";
        const gen_nonmotor = this.containsSeizure(seizures, s1, false, annot, "Generalized nonmotor seizures.");

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic"];
        const gen_tonic = this.containsSeizure(seizures, s1, false, annot, "Generalized tonic seizures.");

        s1.details = ["motor_gen_atonic"];
        const gen_atonic = this.containsSeizure(seizures, s1, false, annot, "Generalized atonic seizures.");

        s1.details = ["motor_gen_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Generalized epileptic spasms.");

        s1.details = ["motor_gen_clonic"];
        const gen_clonic = this.containsSeizure(seizures, s1, false, annot, "Generalized clonic seizures.");

        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tonic_clonic = this.containsSeizure(seizures, s1, false, annot, "Generalized tonic clonic seizures.");

        s1 = defaultSeizure();
        s1.mainType = "unknown";
        s1.onset = "motor";
        s1.details = ["motor_unknown_tonic_clonic"];
        const uk_tonic_clonic = this.containsSeizure(seizures, s1, false, annot, "Unknown type tonic clonic seizures.");

        s1.details = ["motor_unknown_epileptic_spasms"];
        const uk_spasms = this.containsSeizure(seizures, s1, false, annot, "Unknown type epileptic spasms.");

        s1.onset = "nonmotor";
        s1.details = ["nonmotor_unknown_behavioral_arrest"];
        const uk_behav = this.containsSeizure(seizures, s1, false, annot, "Unknown type tonic clonic seizures.");

        if(focal || gen_nonmotor || gen_tonic || gen_atonic || spasms || gen_clonic || gen_tonic_clonic || uk_tonic_clonic || uk_spasms || uk_behav) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable) {
            return new Unresolved("Info on neurological examination at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        const exclusionaryOk = this.checkFeatures(exam, ["exam_microcephaly", "exam_dysmorphism", "exam_other_congenital"], false, annot);

        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }


    /**
     * Check eeg features. This function takes care of the initial checks, 
     * i.e. if the section exists and eeg info is available.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkEEGDetails(eeg: string[], eegAvailable: boolean, swFreq: number): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();        
        let exclusionaryOk = this.checkFeatures(eeg, ["eeg_slowing", "eeg_hypsarrhythmia", "eeg_burst_supression", "eeg_discontinuity", "eeg_hemispheric_supression", "eeg_ictal_lack_EEG_correlate"], false, annot);

        const gen_sw = hasFeature(eeg, "eeg_gen_sw");
        if(gen_sw) {
            if(Number.isNaN(swFreq)) {
                annot.push("Generalized pike wave present, but frequency unclear. Fequency <2.5Hz would be exclusionary.");
            } else {
                if(swFreq < 2.5) {
                    annot.push("Exclusionary present: Generalized spike wave < 2.5 Hz");
                    exclusionaryOk = false;
                } else {
                    annot.push("Generalized spike wave present and frequency >= 2.5Hz. Frequency <2.5Hz would be exclusionary.");
                }
            }
        } else {
            annot.push("Exlusionary missing: Generalized spike wave <2.5Hz");
        }      


        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
        
    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory normal imaging.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeature(imaging, "imaging_normal", true, annot);

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        if(!testsAvailable) {
            return new Unresolved("Further test results are not available however is critical to determine exlusionary features: Low CSF glucose and SLCA1.");
        }

        const annot: string[] = new Array();
        const exclusionaryOk = this.checkFeatures(tests, ["test_gene_SLC2A1", "test_low_csf_glucose"], false, annot);

        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["(Afebrile) tonic-clonic seizures at onset", "(Afebrile) clonic seizures at onset", "Moderate or profound intellectual disability at onset", "Speech delay at diagnosis",
            "Significant abnormal neurological exam", "Lack of generalized spike-wave discharge on EEG sleep recordings", "PPR at low frequency photic stimulation"];
    }

}