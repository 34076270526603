import { Container, Form, FormGroup } from "react-bootstrap";
import { FeatureCheck } from "../../components/FeatureCheck";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { updateFromEvent } from "./Utils";
import { setHistoryFeatures } from "../../features/features/featuresSlice";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { FeatureRadio } from "../../components/FeatureRadio";
import { addFeature, removeFeatures } from "../../classification/Utils";

function HistoryPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    
    let temp = features.get("history") === undefined ? [] as string[] : features.get("history");
    const [history, setHistory] = useState(temp);

    temp = features.get("personalFreeText") === undefined ? "" : features.get("personalFreeText");
    const [personalFreeText, setPersonalFreeText] = useState(temp);

    temp = features.get("familyFreeText") === undefined ? "" : features.get("familyFreeText");
    const [familyFreeText, setFamilyFreeText] = useState(temp);

    temp = features.get("treatmentFreeText") === undefined ? "" : features.get("treatmentFreeText");
    const [treatmentFreeText, setTreatmentFreeText] = useState(temp);

    temp = features.get("otherFreeText") === undefined ? "" : features.get("otherFreeText");
    const [otherFreeText, setOtherFreeText] = useState(temp);

    // Main state of family history
    temp = "history_family_unknown";
    if(history.indexOf("history_family_positive") > -1) {
        temp = "history_family_positive";
    } else if(history.indexOf("history_family_negative") > -1) {
        temp = "history_family_negative";
    }
    const [anyFamilyHistory, setAnyFamilyHistory] = useState(temp);
    const anyFamilyHistoryChanged = (e: React.FormEvent<HTMLInputElement>) => {
        let temp = [...history];
        removeFeatures(temp, ["history_family_unknown", "history_family_positive", "history_family_negative"]);
        addFeature(temp, e.currentTarget.value);
        setAnyFamilyHistory(e.currentTarget.value);
        setHistory(temp);
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, history, setHistory);

    useEffect(() => {
        dispatch(setHistoryFeatures({history: history, personalFreeText: personalFreeText, 
            familyFreeText: familyFreeText, treatmentFreeText: treatmentFreeText, otherFreeText: otherFreeText}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.HISTORY}));
    });

    return (
        <Container>
            <h2>Other history features</h2>
            <p>Are there any further history features available?</p>
            
            <Form className="gap_above_small">
                <FormGroup>
                    <h3>Personal history</h3>
                    <FeatureCheck feature="history_neonatal_distress" label="Neonatal distress" featureArray={history} onChange={handleChange} />
                    <FeatureCheck feature="history_low_APGAR" label="Low APGAR scores" featureArray={history} onChange={handleChange} />
                    <FeatureCheck feature="history_psychiatric_comorbidities" label="Psychiatric comorbidities" featureArray={history} onChange={handleChange} />
                    <Form.Group className="gap_above_small">
                        <Form.Label>Other infos on personal history (please specify free text):</Form.Label>
                        <Form.Control as="textarea" rows={3} value={personalFreeText} onChange={(e) => setPersonalFreeText(e.target.value)} />
                    </Form.Group>

                    <h3 className="gap_above_small">Treatment</h3>
                    <FeatureCheck
                        feature="history_sodium_blockers"
                        label="Response to sodium-channel blockers"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_response_keto"
                        label="Response to ketogenic diet"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_response_pyr"
                        label="Response to pharmacological doses of pyridoxine or pyridoxal-5-phosphate"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <Form.Group className="gap_above_small">
                        <Form.Label>Other info on treatment (please specify free text):</Form.Label>
                        <Form.Control as="textarea" rows={3} value={treatmentFreeText} onChange={(e) => setTreatmentFreeText(e.target.value)} />
                    </Form.Group>

                    <h3 className="gap_above_small">Family history</h3>
                    <FormGroup>
                        <FeatureRadio feature="history_family_unknown" label="Unknown family history for epilepsy" selection={anyFamilyHistory} onChange={anyFamilyHistoryChanged} />
                        <FeatureRadio feature="history_family_negative" label="Negative family history for epilepsy" selection={anyFamilyHistory} onChange={anyFamilyHistoryChanged} />
                        <FeatureRadio feature="history_family_positive" label="Positive family history for epilepsy" selection={anyFamilyHistory} onChange={anyFamilyHistoryChanged} />
                    </FormGroup>
                    {anyFamilyHistory === "history_family_positive" &&
                        <>
                            <FeatureCheck
                                offset={1}
                                feature="history_family_focal"
                                label="Family history of individuals with focal seizures that arise from cortical regions that differ between family members"
                                featureArray={history}
                                onChange={handleChange}
                            />
                            <FeatureCheck
                                offset={1}
                                feature="history_family_EIDEE"
                                label="Family history of early infantile developmental and epileptic encephalopathy"
                                featureArray={history}
                                onChange={handleChange}
                            />
                            <FeatureCheck
                                offset={1}
                                feature="history_family_infertility"
                                label="Family history of infertility"
                                featureArray={history}
                                onChange={handleChange}
                            />
                            <FeatureCheck
                                offset={1}
                                feature="history_family_SeLNE"
                                label="Family history of self-limited neonatal epilepsy"
                                featureArray={history}
                                onChange={handleChange}
                            />
                        </>
                    }
                    <FeatureCheck
                        feature="history_death_siblings"
                        label="Family history of death in siblings"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <Form.Group className="gap_above_small">
                        <Form.Label>Other info on family history (please specify free text):</Form.Label>
                        <Form.Control as="textarea" rows={3} value={familyFreeText} onChange={(e) => setFamilyFreeText(e.target.value)} />
                    </Form.Group>

                    <h3 className="gap_above_small">Other</h3>
                    <FeatureCheck
                        feature="history_previous_epilepsy"
                        label="Diagnosis of a specific epileptic syndrome in the past but epilepsy features changed over time"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_nonspecific_febrile"
                        label="History of nonspecific febrile illness in the 2 weeks preceding seizure onset.
                        Seizures progress in frequency and severity to culminate in superrefractory status epilepticus typically within 2 weeks of onset"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_febrile_GEFS"
                        label="Febrile seizures (focal orgeneralized), persisting >6 years of age or associated with non-febrile seizures"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_two_stage_hhe"
                        label="Development with an acute and chronic stage: 
                            Acute: Episode of febrile, hemiclonic status epilepticus which is immediately followed by permantent hemiparesis. 
                            Chronic: After variable time (usually <3years after initial status epilepticus) unilateral focal motor or focal to bilateral tonic clonic seizures appear."
                        featureArray={history}
                        onChange={handleChange}
                    />						
                    <FeatureCheck
                        feature="history_feeding_difficulties"
                        label="Feeding difficulties"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_behavioral_problems"
                        label="Behavioral problems/psychiatric comorbidities"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_learning_difficulties"
                        label="Learning difficulties (any)"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    <FeatureCheck
                        feature="history_regression_SeLIE"
                        label="Neurocognitive regression with myoclonic seizures, ataxia, spasticity"
                        featureArray={history}
                        onChange={handleChange}
                    />
                    
                    <Form.Group className="gap_above_small">
                        <Form.Label>Other (please specify free text):</Form.Label>
                        <Form.Control as="textarea" rows={3} value={otherFreeText} onChange={(e) => setOtherFreeText(e.target.value)} />
                    </Form.Group>
                </FormGroup>
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/Imaging")} previous={() => navigate("/pages/EEG")} />
        </Container>
    );
}

export default HistoryPage;