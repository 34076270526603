import { FeatureResponse, Yes, Unclear, No, Unresolved } from "./FeatureResponse";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class EwRIS extends Syndrome {
    
    getName() {
        return "EwRIS";
    }
    
    checkMandatorySeizures(seizures: any): FeatureResponse {
        let annotations: string[] = new Array();
        let mandatoryOk = false;
        for (const seizure of seizures) {
            if (seizure.mainType === "focal") {
                const myoclonic = seizure.details.indexOf("motor_myoclonic") > -1;
                const reading = seizure.triggers.indexOf("seizure_trigger_reading") > -1;
                mandatoryOk = myoclonic && reading;
                if (mandatoryOk) {
                    annotations.push("Mandatory: Focal myoclonic seizures triggered by reading present.");
                    break;
                }
            } else if (seizure.mainType === "generalized") {
                const myoclonic = seizure.details.indexOf("motor_gen_myoclonic") > -1;
                const reading = seizure.triggers.indexOf("seizure_trigger_reading") > -1;
                mandatoryOk = myoclonic && reading;
                if (mandatoryOk) {
                    annotations.push("Mandatory: Generalized myoclonic seizures triggered by reading present.");
                    break;
                }
            }
        }
        
        if(mandatoryOk) {
            return new Yes(annotations);
        } else {
            annotations.push("Mandatory missing: Generalized or focal myoclonic seizures triggered by reading.");
            return new No(annotations);
        }
    }

    checkExclusionarySeizures(seizures: any): FeatureResponse {
        let annotations: string[] = new Array();
        let exclusionaryOk = true;
        for (const seizure of seizures) {
            exclusionaryOk = true;
            if (seizure.mainType === "focal") {
                const myoclonic = seizure.details.indexOf("motor_myoclonic") > -1;
                exclusionaryOk = myoclonic;
            } else if (seizure.mainType === "generalized") {
                const myoclonic = seizure.details.indexOf("motor_gen_myoclonic") > -1;
                exclusionaryOk = myoclonic;
            }
            if(seizure.timepoint !== "wake_sleep_unknown" && seizure.timepoint !== "wake_only") {
                exclusionaryOk = false;
                annotations.push("Exclusionary: Seizures occur also occur from sleep.");
            }
            if (!exclusionaryOk) {
                break;
            }
        }
        
        if(exclusionaryOk) {
            annotations.push("Exclusionary not present: No seizures other than generalized or focal myoclonic seizures triggered by reading.");
            return new Yes(annotations);
        } else {
            annotations.push("Exclusionary: Seizures other than generalized or focal myoclonic seizures triggered by reading.");
            return new No(annotations);
        }
    }

    protected checkDevelopmentDetail(dev: string[], devAvailable: boolean): FeatureResponse {
        if(!devAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        if (hasFeature(dev, "dev_normal")) {
            return new Yes("Mandatory present: Normal development at onset");
        } 
        return new No("Development at onset not normal.");
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        const normal = this.checkFeatures(exam, ["exam_normal", "exam_course_normal"], true, annot);

        if (normal) {
            return new Yes(annot);
        }
        return new No(annot);
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();
        const normal = this.checkFeatures(imaging, ["imaging_normal"], true, annot);

        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot); 
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const slowing = eeg.indexOf("eeg_background_slowing") > -1;
        if(slowing) {
            return new No("eeg_background_slowing");
        }
        return new Unclear();
    }

    getAlertCriteria(): string[] {
        return ["Prominent myoclonic jerks affecting the upper limbs", "Age at onset > 20 years"];
    }
}