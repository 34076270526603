import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCurrentSeizure } from "../../features/seizure/seizureSlice";
import { constructItem, constructList, constructSeizureType, constructSemiology } from "../../classification/Seizures";
import { useEffect } from "react";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { removeSeizure } from "../../features/features/featuresSlice";

function SeizureSummaryPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);

    const seizures = features.get("seizures");
    let numberOfSeizures = 0;
    if (seizures !== undefined) {
        numberOfSeizures = seizures.length;
    }

    const handleAddSeizureType = () => {
		dispatch(setCurrentSeizure(numberOfSeizures));
		navigate("/pages/SeizureType");
	}

    const editTableRow =(index: number) => {
		dispatch(setCurrentSeizure(index));
		navigate("/pages/SeizureType");
	}

    const deleteTableRow = (index: number) => {
        dispatch(removeSeizure(index));
    }

    let rows = [];
	if(seizures !== undefined) {
		for (let n = 0; n < seizures.length; n++) {
			const row = (
				<tr key={n}>
					<td>{constructSeizureType(seizures[n].mainType, seizures[n].awareness)}</td>
					<td>{seizures[n].onset}</td>
					<td>{constructSemiology(seizures[n].details)}</td>
					<td>{constructItem(seizures[n].timepoint)}</td>
					<td>{constructList(seizures[n].timeOfDay)}</td>
					<td>{constructList(seizures[n].duration)}</td>
					<td>{constructList(seizures[n].triggers)}</td>
					<td>{constructList(seizures[n].frequency)}</td>
					<td>{constructList(seizures[n].other)}</td>
					<td><Button variant="link" onClick={e => editTableRow(n)}>Edit</Button></td>
					<td><Button variant="link" onClick={e => deleteTableRow(n)}>Delete</Button></td>
				</tr>
			);
			rows.push(row);
		}
	}

	useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.SEIZURES}));
    });

	return (
		<Container>
			<Row>
				<Col md={12}>
					<h2>Seizure types</h2>
					<Table>
						<thead>
							<tr>
								<th>Type</th>
								<th>Onset</th>
								<th>Semiology</th>
								<th>Wake/Sleep</th>
								<th>Time of day</th>
								<th>Duration</th>
								<th>Triggers</th>
								<th>Frequency</th>
								<th>Other</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</Col>
			</Row>
			<Row className="gap_above">
				<Col md={12}>
					<Button variant="secondary" onClick={handleAddSeizureType}>
						Add another seizure type
					</Button>
				</Col>
			</Row>
			<NextPreviousButtons next={() => navigate("/pages/Development")} previous={() => navigate("/pages/SeizureExtra")} nextDisabled={numberOfSeizures === 0} />
		</Container>
	);
}

export default SeizureSummaryPage;
