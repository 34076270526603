import React, { useState } from "react";
import { FormGroup, Form, Col } from 'react-bootstrap';
import { Feature } from "../classification/Constants";

export interface FeatureCheckProps {
    mainFeature: Feature;
    mainLabel: string;
    features: Feature[];
    labels: string[];
    onChange: (mainFeauture: string, mainFeatureSelected: boolean, selected: string[], features: string[]) => void;
}

const toggleOption = (array: string[], feature: string) => {
    const index = array.indexOf(feature);
    const clone = [...array];
    if(index > -1) {
        clone.splice(index, 1);
    } else {
        clone.push(feature);
    }
    return clone;
}

export const GroupedFeatureCheck = (props: FeatureCheckProps) => {
    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState([] as string[]);
    
    let onOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value; 
        const newSelection = toggleOption(selection, value);
        setSelection(newSelection);
        props.onChange(props.mainFeature, open, newSelection, props.features);
    };

    let onMainFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOpen(!open);
        props.onChange(props.mainFeature, !open, selection, props.features);
    };

    let options = [];
    for(let i=0;i<props.features.length;i++) {
        const feature = props.features[i];
        options.push(    
            <Form.Check key={feature} checked={selection.indexOf(feature) > -1} type="checkbox" label={props.labels[i]} value={feature} onChange={onOptionsChange}/>
       );
    }
    
    return <FormGroup>
        <Form.Check checked={open} type="checkbox" label={props.mainLabel} value={props.mainFeature} onChange={onMainFeatureChange}/>
        { open &&
            <FormGroup>
                <Col md={{offset: 1}}>
                    {options}
                </Col>
            </FormGroup>
        }
    </FormGroup>
}