import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice';
import featuresReducer from '../features/features/featuresSlice';
import seizureReducer from '../features/seizure/seizureSlice';
import classificationUIReducer from '../features/classificationUI/classificationUISlice';
import alertsReducer from '../features/alerts/alertsSlice';
import accountUIReducer from '../features/account/accountUISlice'
import devUIReducer from '../features/devUI/devUISlice';
import validationReducer from '../features/validation/validationSlice';
import { enableMapSet } from 'immer';

enableMapSet();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    features: featuresReducer,
    seizure: seizureReducer,
    classificationUI: classificationUIReducer,
    alerts: alertsReducer,
    accountUI: accountUIReducer,
    devUI: devUIReducer,
    validation: validationReducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['features/setFeatures', 'features/setImagingFeatures'],
        ignoredPaths: ['features.features', 'validation.info']
    }}
    )
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
