import { useState } from "react";
import { Alert, Button, Col, Container, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Jumbotron from "../../components/Jumbotron";
import { signUp } from "../../requests/auth";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../features/user/userSlice";
import { validateEmail } from "../../components/Utils";

function RegisterPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
	const [email, setEmail] = useState("");
	const onChangeEmail = (event: any) => {
		setEmail(event.target.value);
		setShowAlert(false);
	};
	
	const emailOk = validateEmail(email);
	
    const [password, setPassword] = useState("");
	const onChangePassword = (event: any) => {
		setPassword(event.target.value);
		setShowAlert(false);
	};

    const [password2, setPassword2] = useState("");
	const onChangePassword2 = (event: any) => {
		setPassword2(event.target.value);
		setShowAlert(false);
	};

	const [firstName, setFirstName] = useState("");
	const onChangeFirstName = (event: any) => {
		setFirstName(event.target.value);
		setShowAlert(false);
	};

	const [name, setName] = useState("");
	const onChangeName = (event: any) => {
		setName(event.target.value);
		setShowAlert(false);
	};

	const [institution, setInstitution] = useState("");
	const onChangeInstitution = (event: any) => {
		setInstitution(event.target.value);
		setShowAlert(false);
	};

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleRegisterButton = async () => {
        try {
            const response = await signUp({ email: email, password: password, name: name, firstName: firstName, institution: institution });
            setShowAlert(false);
            dispatch(setUser({id: response.id, name: response.name, firstName: response.firstName, institution: response.institution, email: response.email}));
            navigate("/pages/Age");
        } catch(error: any) {
			console.log(error);
			if(error.response.status === 403) {
				setAlertMessage("User with this email already exists!");
			}		
            setShowAlert(true);
        }
        
    }

    const passwordIdentical = password === password2;
	
	return (
		<Container>
			<Col>
                <Row>
					<Jumbotron title="ENGenE App" subheading="An educational tool on Epilepsy Nosology and Genetic Etiologies" />
				</Row>
				<Row>
					<FormGroup controlId="firstName">
						<FormLabel>First Name</FormLabel>
						<FormControl type="text" placeholder="Enter first name" value={firstName} onChange={onChangeFirstName} />
					</FormGroup>
					<FormGroup controlId="name">
						<FormLabel>Last Name</FormLabel>
						<FormControl type="text" placeholder="Enter last name" value={name} onChange={onChangeName} />
					</FormGroup>
					<FormGroup controlId="institution">
						<FormLabel>Institution</FormLabel>
						<FormControl type="text" placeholder="Enter institution" value={institution} onChange={onChangeInstitution} />
					</FormGroup>
                    <FormGroup controlId="email">
						<FormLabel>Email address</FormLabel>
						<FormControl type="email" placeholder="Enter email" value={email} onChange={onChangeEmail} />
						{ !emailOk && <Alert variant="danger">Not an email address!</Alert>}
					</FormGroup>
					
                    <FormGroup controlId="password">
						<FormLabel>Password</FormLabel>
						<FormControl type="password" placeholder="Enter password" value={password} onChange={onChangePassword} />
					</FormGroup>
                    <FormGroup controlId="password2">
						<FormLabel>Repeat password</FormLabel>
						<FormControl type="password" placeholder="Enter password again" value={password2} onChange={onChangePassword2} />
						{ !passwordIdentical && <Alert variant="danger">Password and repeated password are different!</Alert>}
					</FormGroup>
				</Row>
				<Row>
					<p className="gap-above">
						This interactive decision support tool on Epilepsy Nosology and Genetic Etiologies is an educational tool funded by the CEF Telecom 2020 project. 
						Its usage is reserved to healthcare professionals for educational reasons only. The GDPR conformity of the ENGenE App has been validated by the 
						University Hospitals of Lyon. More information can be found <a href="./data/Mentions HCL_ENGenE App_english.pdf">here</a>.
					</p>
					<p>
						The decisional support Algorithm was based on the Mandatory/Exclusionary/Alert criteria for each epileptic syndrome as defined by the last classification 
						of the Nosology Committee of the International Ligue Against Epilepsy. The methodology to define those criteria is detailed in{" "} 
						<a href="https://pubmed.ncbi.nlm.nih.gov/21940184/">this paper</a> and was not intended for the built-up of an Algorithm. Although a validation procedure was followed, the Algorithm may be subject to 
						mistakes and is not intended to be used as a diagnostic tool and by no means to replace/delay/interfere with the established diagnostic procedure of each center/clinician.  
					</p>
					<p>
						Furthermore, the results provided by the Algorithm are based on the information that is entered by the user at a specific time-point and are subject to data 
						inaccuracy or variability at different time-points of a disease course. The information included for each syndrome is also according to the detailed description of the epileptic syndromes 
						(<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17240">Riney et al., 2022</a>, 
						<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17241">Specchio et al., 2022</a>, 
						<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17239">Zuberi et al., 2022</a>,
						<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17237">Wirell et al., 2022</a>)
						and further developed/revised with focus on genetic aetiologies. The list of genetic causes is however not exhaustive and is not intended to replace 
						standard procedures for genetic diagnosis or genetic counselling. 
					</p>
					<p>
						You agree that by creating an account and accessing the web-based tool, you have read, understood, and agree to be bound by all of these Terms and Conditions Use. 
						IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING IT AND YOU MUST DISCONTINUE USE IMMEDIATELY.
					</p>
				</Row>
				<Row className="gap_above">
					{ showAlert && <Alert variant="danger">{alertMessage}</Alert>}
					<Button variant="secondary" disabled={!passwordIdentical || !emailOk} onClick={handleRegisterButton}>Register</Button>
				</Row>
			</Col>
		</Container>
	);
}

export default RegisterPage;
