import '../css/styles.css'

function Jumbotron(props: {title: string, subheading: string}) {
    return (       
        <div className="p-5 mb-4 bg-light rounded-3 border shadow-lg">
            <div className="container-fluid py-5">
                <h1 className="display-5 fw-bold">{props.title}</h1>
                <p className="col-md-8 fs-4">{props.subheading}</p>
            </div>
        </div>
    );
}

export default Jumbotron;