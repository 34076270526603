import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DevUIState {
    allowed: boolean;
    open: boolean;
}

const initialState: DevUIState = {
    allowed: true,
    open: false
}

export const devUISlice = createSlice({
    name: 'devUI',
    initialState,
    reducers: {
        setAllowed: (state, action: PayloadAction<boolean>) => {
            state.allowed = action.payload;
            return state;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
            return state;
        }
    }
});

export const { setAllowed, setOpen } = devUISlice.actions;

export const selectDevUI = (state: RootState) => state.devUI;

export default devUISlice.reducer;