export enum Response {
    YES, NO, UNCLEAR, UNRESOLVED
}

export class FeatureResponse {

    private response: Response;
    private annotations: string[];

    constructor(response: Response, annotation?: string | string[]) {
        this.response = response;
        this.annotations = new Array();
        if(annotation !== undefined) {
            if(Array.isArray(annotation)) {
                this.addAnnotations(annotation);
            } else {
                this.addAnnotation(annotation);
            }
        }
    }

    isYes() {
        return this.response === Response.YES;
    }

    isUnclear() {
        return this.response === Response.UNCLEAR;
    }

    isUnresolved() {
        return this.response === Response.UNRESOLVED;
    }

    isNo() {
        return this.response === Response.NO;
    }

    addAnnotation(annotation: string) {
        this.annotations.push(annotation);
    }

    addAnnotations(annotations: string[]) {
        for(let annotation of annotations) {
            this.annotations.push(annotation);
        }
    }

    getAnnotations() {
        return this.annotations;
    }

}

export class Yes extends FeatureResponse {

    constructor(annotation?: string | string[]) {
        super(Response.YES, annotation);
    }

}

export class No extends FeatureResponse {

    constructor(annotation?: string | string[]) {
        super(Response.NO, annotation);
    }

}

export class Unclear extends FeatureResponse {

    constructor(annotation?: string | string[]) {
        super(Response.UNCLEAR, annotation);
    }

}

export class Unresolved extends FeatureResponse {
    constructor(annotation?: string | string[]) {
        super(Response.UNRESOLVED, annotation);
    }
}