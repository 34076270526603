import "./App.css";
import { BrowserRouter, Navigate, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import LandingPage from "./containers/LandingPage/LandingPage";
import FeaturePageLayout from "./containers/FeaturesPages/FeaturePageLayout";
import AgePage from "./containers/FeaturesPages/AgePage";
import { useAppSelector } from "./app/hooks";
import { selectUser } from "./features/user/userSlice";
import SeizureTypePage from "./containers/FeaturesPages/SeizureTypePage";
import SeizureDetailsPage from "./containers/FeaturesPages/SeizureDetailsPage";
import SeizureExtraFeaturePage from "./containers/FeaturesPages/SeizureExtraFeaturePage";
import SeizureSummaryPage from "./containers/FeaturesPages/SeizureSummaryPage";
import DevelopmentPage from "./containers/FeaturesPages/DevelopmentPage";
import AlertsPage from "./containers/FeaturesPages/AlertsPage";
import EEGPage from "./containers/FeaturesPages/EEGPage";
import HistoryPage from "./containers/FeaturesPages/HistoryPage";
import ImagingPage from "./containers/FeaturesPages/ImagingPage";
import NeuroExamPage from "./containers/FeaturesPages/NeuroExamPage";
import TestsPage from "./containers/FeaturesPages/TestsPage";
import ResultsPage from "./containers/Results/ResultsPage";
import SyndromePage, { loader as SyndromeLoader } from "./containers/Results/SyndromePage";
import RegisterPage from "./containers/AccountPages/RegisterPage";
import InitResetPasswordPage from "./containers/AccountPages/InitResetPasswordPage";
import ResetPasswordPage from "./containers/AccountPages/ResetPasswordPage";
import ValidationPage from "./containers/Validation/ValidationPage";

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path="/" element={<LandingPage />} />
				<Route path="/Register" element={<RegisterPage />} />
				<Route path="/Reset" element={<InitResetPasswordPage />} />
				<Route path="ResetPassword">
					<Route path=":token" element={<ResetPasswordPage />} />
				</Route>
				<Route path="/pages" element={<FeaturePageLayout />}>
					<Route
						path="Age"
						element={
							<RequireAuth redirectTo={"/"}>
								<AgePage />
							</RequireAuth>
						}
					/>
					<Route
						path="SeizureType"
						element={
							<RequireAuth redirectTo={"/"}>
								<SeizureTypePage />
							</RequireAuth>
						}
					/>
					<Route
						path="SeizureDetails"
						element={
							<RequireAuth redirectTo={"/"}>
								<SeizureDetailsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="SeizureExtra"
						element={
							<RequireAuth redirectTo={"/"}>
								<SeizureExtraFeaturePage />
							</RequireAuth>
						}
					/>
					<Route
						path="SeizureSummary"
						element={
							<RequireAuth redirectTo={"/"}>
								<SeizureSummaryPage />
							</RequireAuth>
						}
					/>
					<Route
						path="Development"
						element={
							<RequireAuth redirectTo={"/"}>
								<DevelopmentPage />
							</RequireAuth>
						}
					/>
					<Route
						path="NeuroExam"
						element={
							<RequireAuth redirectTo={"/"}>
								<NeuroExamPage />
							</RequireAuth>
						}
					/>
					<Route
						path="EEG"
						element={
							<RequireAuth redirectTo={"/"}>
								<EEGPage />
							</RequireAuth>
						}
					/>
					<Route
						path="History"
						element={
							<RequireAuth redirectTo={"/"}>
								<HistoryPage />
							</RequireAuth>
						}
					/>
					<Route
						path="Imaging"
						element={
							<RequireAuth redirectTo={"/"}>
								<ImagingPage />
							</RequireAuth>
						}
					/>
					<Route
						path="Tests"
						element={
							<RequireAuth redirectTo={"/"}>
								<TestsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="AlertCriteria"
						element={
							<RequireAuth redirectTo={"/"}>
								<AlertsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="Results"
						element={
							<RequireAuth redirectTo={"/"}>
								<ResultsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="Syndrome/:syndrome"
						loader={SyndromeLoader}
						element={
							<RequireAuth redirectTo={"/"}>
								<SyndromePage />
							</RequireAuth>
						}
					/>
					<Route
						path="Validation"
						element={
							// <RequireAuth redirectTo={"/"}>
								<ValidationPage />
							// </RequireAuth>
						}
					/>
				</Route>
			</>
		)
	);

	return <RouterProvider router={router} />;
}

export default App;

type RequireAuthProps = {
	children: any;
	redirectTo: string;
};

function RequireAuth({ children, redirectTo }: RequireAuthProps) {
	const user = useAppSelector(selectUser);
	let isAuthenticated = user !== null;
	return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
