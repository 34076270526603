import { EAF } from "./EAF";
import { SHE } from "./SHE";
import { Syndrome } from './Syndrome';
import { EwRIS } from './EwRIS';
import { FFEVF } from "./FFEVF";
import { GTCA } from "./GTCA";
import { JAE } from './JAE';
import { JME } from "./JME";
import { mTLE_HS } from "./mTLE_HS";
import { PME } from "./PME";
import { CAE } from "./CAE";
import { COVE } from "./COVE";
import { EEM } from "./EEM";
import { EMA } from "./EMA";
import { EMAtS } from "./EMAtS";
import { FIRES } from "./FIRES";
import { HHE } from "./HHE";
import { DEESWAS } from "./DEESWAS";
import { EESWAS } from "./EESWAS";
import { LGS } from "./LGS";
import { POLE } from "./POLE";
import { SeLEAS } from './SeLEAS';
import { SeLECTS } from './SeLECTS';
import { EIDEE } from "./EIDEE";
import { Glut1DS } from "./Glut1DS";
import { KCNQ2DEE } from "./KCNQ2DEE";
import { PDDEE } from "./PDDEE";
import { PNPODEE } from "./PNPODEE";
import { SeLFNIE } from "./SeLFNIE";
import { SeLNE } from './SeLNE';
import { CDKL5DEE } from './CDKL5DEE';
import { DS } from "./DS";
import { EIMFS } from "./EIMFS";
import { IESS } from "./IESS";
import { GEFS } from "./GEFS";
import { GSHH } from "./GSHH";
import { MEI } from "./MEI";
import { PCDH19 } from "./PCDH19";
import { SWS } from "./SWS";
import { RS } from "./RS";
import { SeLIE } from "./SeLIE";

export interface ClassificatorResults {
    likely: Syndrome[];
    likelyWithAlerts?: Syndrome[];
    unclear: Syndrome[];
    unresolved: Syndrome[];
    excluded: Syndrome[];
}

export enum ClassificatorStage {
    UNDEFINED = 0,
    AGE_GENDER = 1,
    SEIZURES = 2,
    DEVELOPMENT = 3,
    NEURO_EXAM = 4,
    EEG = 5,
    HISTORY = 6,
    IMAGING = 7,
    TESTS = 8,

    RESULTS = 99
}

export class Classificator {

    syndromes: Syndrome[] = new Array();
    annotations: Map<ClassificatorStage, Map<string, string[]>> = new Map();

    constructor() {
        this.syndromes.push(new SHE());
        this.syndromes.push(new EAF());
        this.syndromes.push(new EwRIS());
        this.syndromes.push(new FFEVF());
        this.syndromes.push(new GTCA());
        this.syndromes.push(new JAE());
        this.syndromes.push(new JME());
        this.syndromes.push(new mTLE_HS());
        this.syndromes.push(new PME());
        this.syndromes.push(new CAE());
        this.syndromes.push(new COVE());
        this.syndromes.push(new EEM());
        this.syndromes.push(new EMA());
        this.syndromes.push(new EMAtS());
        this.syndromes.push(new FIRES());
        this.syndromes.push(new HHE());
        this.syndromes.push(new DEESWAS());
        this.syndromes.push(new EESWAS());
        this.syndromes.push(new LGS());
        this.syndromes.push(new POLE());
        this.syndromes.push(new SeLEAS());
        this.syndromes.push(new SeLECTS());
        this.syndromes.push(new EIDEE());
        this.syndromes.push(new Glut1DS());
        this.syndromes.push(new KCNQ2DEE());
        this.syndromes.push(new PDDEE());
        this.syndromes.push(new SeLFNIE());
        this.syndromes.push(new SeLNE());
        this.syndromes.push(new CDKL5DEE());
        this.syndromes.push(new DS());
        this.syndromes.push(new EIMFS());
        this.syndromes.push(new IESS());
        this.syndromes.push(new GEFS());
        this.syndromes.push(new GSHH());
        this.syndromes.push(new MEI());
        this.syndromes.push(new PCDH19());
        this.syndromes.push(new SWS());
        this.syndromes.push(new RS());
        this.syndromes.push(new SeLIE());
    }

    public check(features: Map<string, any>, stage: ClassificatorStage): ClassificatorResults{
        let excluded: Syndrome[] = new Array();
        this.annotations.clear();

        let result: ClassificatorResults = {
            likely: new Array(),
            unclear: new Array(),
            unresolved: new Array(),
            excluded: new Array()
        };

        if (stage >= ClassificatorStage.AGE_GENDER) {
            result = this.checkAgeGender(features);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.SEIZURES) {
            result = this.checkSeizures(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.DEVELOPMENT) {
            result = this.checkDevelopment(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.NEURO_EXAM) {
            result = this.checkNeuroExam(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.EEG) {
            result = this.checkEEG(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.HISTORY) {
            result = this.checkHistory(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.IMAGING) {
            result = this.checkImaging(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }
        if (stage >= ClassificatorStage.TESTS) {
            result = this.checkTests(features, result.likely, result.unclear, result.unresolved);
            excluded = excluded.concat(result.excluded);
        }

        if (stage >= ClassificatorStage.RESULTS) {
            const alerts = this.parseAlerts(features.get("alertCriteria"));
            const alertedLikely = new Array();
            result.likely.forEach(syndrome => {
                if(alerts.has(syndrome.getName())) {
                    alertedLikely.push(syndrome);
                    let index = result.likely.indexOf(syndrome);
                    if (index > -1) {
                        result.likely.splice(index, 1);
                    }
                }
            });
            return { likely: this.sort(result.likely), likelyWithAlerts: this.sort(alertedLikely), unclear: this.sort(result.unclear), unresolved: this.sort(result.unresolved), excluded: this.sort(excluded) };
        } else {
            return { likely: this.sort(result.likely), unclear: this.sort(result.unclear), unresolved: this.sort(result.unresolved), excluded: this.sort(excluded) };
        }
    }

    sort(syndromes: Syndrome[]): Syndrome[] {
        syndromes.sort((first: Syndrome, second: Syndrome): number => {
            return first.getName() > second.getName() ? 1 : -1;
        });
        return syndromes;
    }

    /**
     * Parse the alert criteria strings, which have the form <syndrome>-<index of selectd criterion>
     * @param alerts alerts to parse
     * @returns Map with syndrome keys and stored arrays of criteria indices
     */
    private parseAlerts(alerts: string[]) {
        const parsed: Map<string, number[]> = new Map();
        if(alerts !== undefined) {
            alerts.forEach(alert => {
                let tokens = alert.split('-');
                let syndrome = tokens[0];
                let indices = parsed.get(syndrome);
                if(indices == undefined) {
                    indices = new Array();
                }
                indices.push(+tokens[1]);
                parsed.set(syndrome, indices);
            })
        }
        return parsed;
    }

    private checkAgeGender(features: Map<string, any>): ClassificatorResults {
        let likely: Syndrome[] = new Array();
        let excluded: Syndrome[] = new Array();
        let unresolved: Syndrome[] = new Array();
        let unclear: Syndrome[] = new Array();

        this.syndromes.forEach(syndrome => {
            const response = syndrome.checkAgeAndGender(features);
            if (response.isYes()) {
                likely.push(syndrome);
            } else if (response.isNo()) {
                excluded.push(syndrome);
            } else if (response.isUnresolved()) {
                unresolved.push(syndrome);
            } else {
                unclear.push(syndrome);
            }
            this.addAnnotations(ClassificatorStage.AGE_GENDER, syndrome.getName(), response.getAnnotations());
        })

        return { likely: likely, unclear: unclear, unresolved: unresolved, excluded: excluded };
    }

    private checkSeizures(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkSeizureTypes(features);
        }
        return this.checkSyndromes(ClassificatorStage.SEIZURES, func, likely, unclear, unresolved);
    }

    private checkDevelopment(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkDevelopment(features);
        }
        return this.checkSyndromes(ClassificatorStage.DEVELOPMENT, func, likely, unclear, unresolved);
    }

    private checkNeuroExam(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkNeuroExam(features);
        }
        return this.checkSyndromes(ClassificatorStage.NEURO_EXAM, func, likely, unclear, unresolved);
    }

    private checkEEG(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkEEG(features);
        }
        return this.checkSyndromes(ClassificatorStage.EEG, func, likely, unclear, unresolved);
    }

    private checkHistory(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkHistory(features);
        }
        return this.checkSyndromes(ClassificatorStage.HISTORY, func, likely, unclear, unresolved);
    }

    private checkImaging(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkImaging(features);
        }
        return this.checkSyndromes(ClassificatorStage.IMAGING, func, likely, unclear, unresolved);
    }

    private checkTests(features: Map<string, any>, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        const func = (syndrome: Syndrome) => {
            return syndrome.checkTests(features);
        }
        return this.checkSyndromes(ClassificatorStage.IMAGING, func, likely, unclear, unresolved);
    }

    private checkSyndromes(stage: ClassificatorStage, func: Function, likely: Syndrome[], unclear: Syndrome[], unresolved: Syndrome[]): ClassificatorResults {
        let newLikely: Syndrome[] = new Array();
        let newExcluded: Syndrome[] = new Array();
        let newUnclear: Syndrome[] = new Array();   
        let newUnresolved: Syndrome[] = new Array();    

        // Previously likely syndromes can be likely or excluded. An unclear response does not change the status.
        // A missing test (EEG, imaging, ...) results in a unresolved classification.
        likely.forEach((syndrome: Syndrome) => {
            const response = func(syndrome);
            if (response.isYes() || response.isUnclear()) {
                newLikely.push(syndrome);
            } else if(response.isUnresolved()) {
                newUnresolved.push(syndrome);
            } else {
                newExcluded.push(syndrome);
            } 
            this.addAnnotations(stage, syndrome.getName(), response.getAnnotations());
        })

        unclear.forEach(syndrome => {
            const response = func(syndrome);
            if (response.isYes()) {
                newLikely.push(syndrome);
            } else if(response.isUnclear()) {
                newUnclear.push(syndrome);
            } else if(response.isUnresolved()) {
                newUnresolved.push(syndrome);
            } else {
                newExcluded.push(syndrome);
            }
            this.addAnnotations(stage, syndrome.getName(), response.getAnnotations());
        })

        // Unresolved syndromes can either stay unresolved or excluded
        unresolved.forEach(syndrome => {
            const response = func(syndrome);
            if (response.isYes() || response.isUnclear()) {
                newUnresolved.push(syndrome);
            } else {
                newExcluded.push(syndrome);
            }
            this.addAnnotations(stage, syndrome.getName(), response.getAnnotations());
        })

        return { likely: newLikely, unclear: newUnclear, unresolved: newUnresolved, excluded: newExcluded };
    }

    protected addAnnotations(stage: ClassificatorStage, syndrome: string, annotations: string[]) {
        let stageData = this.annotations.get(stage);
        if(stageData === undefined) {
            stageData =  new Map<string, string[]>();
            this.annotations.set(stage, stageData);
        }
        let allAnnotations = stageData.get(syndrome);
        if(allAnnotations === undefined) {
            allAnnotations = annotations;
        } else {
            allAnnotations = allAnnotations.concat(annotations);
        }
        stageData.set(syndrome, allAnnotations);
    }

    getAnnotationsForStage(stage: ClassificatorStage, syndrome: string) : string[] {
        let stageData = this.annotations.get(stage);
        if(stageData === undefined) {
            return [];
        }
        let annotations = stageData.get(syndrome);
        if(annotations === undefined) { 
            return [];
        }
        return annotations;
    }

    getAnnotations(stage: ClassificatorStage, syndrome: string) : string[] {
        let annotations: string[] = new Array();
        for(let n=1;n<=stage;n++) {
            let stageAnnotations = this.getAnnotationsForStage(n, syndrome);
            annotations = annotations.concat(stageAnnotations);
        }
        return annotations;
    }

}