import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { hasFeature } from "../classification/Utils";
import { FeatureCheck } from "./FeatureCheck";

export interface FeatureCheckFieldProps {
    feature: string;
    label: string;
    featureArray: string[];
    fieldName: string;
    fieldDefaultValue?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onChangeField: React.ChangeEventHandler<HTMLInputElement>;
    size?: string
}

export const FeatureCheckField = (props: FeatureCheckFieldProps) => {
    const selected = hasFeature(props.featureArray, props.feature);
    let featureSize = 6;
    let fieldSize = 2;
    if(props.size !== undefined && props.size == "large") {
        featureSize = 2;
        fieldSize = 7;
    }

    let defaultValue = props.fieldDefaultValue ?? "";
    
    return <div>
        { selected &&
            <Form.Group className="mb-3">
                <Row className="align-items-center">
                    <Col sm={featureSize}>
                        <FeatureCheck feature={props.feature} label={props.label} featureArray={props.featureArray} onChange={props.onChange}/>
                    </Col>
                    <Col sm={"auto"}>
                        <Form.Label>{props.fieldName}</Form.Label>
                    </Col>
                    <Col sm={fieldSize}>
                        <Form.Control type="textarea" placeholder={props.fieldName} name={props.feature} onChange={props.onChangeField} defaultValue={defaultValue} />
                    </Col> 
                </Row>  
            </Form.Group>
        }
        { !selected &&
            <Row className="align-items-center">
                <Col sm={6}><FeatureCheck feature={props.feature} label={props.label} featureArray={props.featureArray} onChange={props.onChange}/></Col>
            </Row>
        }
    </div>;
    
    
}