import { Alert, Button, Col, Container, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Jumbotron from "../../components/Jumbotron";
import { useState } from "react";
import { validateEmail } from "../../components/Utils";
import { initResetPassword } from "../../requests/auth";
import { useNavigate } from "react-router-dom";

function InitResetPasswordPage() {
	const navigate = useNavigate();
	
	const [email, setEmail] = useState("");
	const emailOk = validateEmail(email);
	const onChangeEmail = (event: any) => {
		setEmail(event.target.value);
	};

	const [done, setDone] = useState(false);

	const handleButton = async () => {
		try {
			await initResetPassword({ email: email });
			console.log("done");
			setDone(true);
		} catch (error: any) {
			console.log(error);
		}
	};

	return (
		<Container>
			<Col>
				<Row>
					<Jumbotron title="ENGenE App" subheading="An educational tool on Epilepsy Nosology and Genetic Etiologies" />
				</Row>
				<Row>
					<p>Please enter your email address and we will send you a link to reset your password if you already have an account.</p>
				</Row>
				<Row>
					<FormGroup controlId="email">
						<FormLabel>Email address</FormLabel>
						<FormControl type="email" disabled={done} placeholder="Enter email" value={email} onChange={onChangeEmail} />
						{!emailOk && <Alert variant="danger">Not an email address!</Alert>}
					</FormGroup>
				</Row>
				{ !done &&
					<Row className="gap_above">
						<Button variant="secondary" disabled={!emailOk || done} onClick={handleButton}>
							Send
						</Button>
					</Row>
				}
				{done && (
					<>
						<Row className="gap_above_small">
							<Col>
								<Alert variant="info">Request sent. Please check your email.</Alert>
							</Col>
						</Row>
						<Row className="gap_above">
							<Button variant="secondary" onClick={() => navigate("/")}>
								Go back to login
							</Button>
						</Row>
					</>
				)}
			</Col>
		</Container>
	);
}

export default InitResetPasswordPage;
