import axios from 'axios';
import { ICredentials, IUser } from '../interfaces';
import { IInitResetPassword, IRegistrationInfo, IResetPassword } from '../interfaces/credentials.interface';

export async function logIn(credentials: ICredentials): Promise<IUser> {
    const response = await axios.post<IUser>('/api/auth/login', credentials);
    return response.data;
}

export async function logOut(): Promise<void> {
    await axios.post('/api/auth/logout');
}

export async function signUp(credentials: IRegistrationInfo): Promise<IUser> {
    const response = await axios.post<IUser>('/api/auth/signup', credentials);
    return response.data;
}

export async function initResetPassword(info: IInitResetPassword) {
    await axios.post<IInitResetPassword>('/api/auth/initReset', info);
}

export async function resetPassword(info: IResetPassword, token: string) {
    const config = {
        headers: {
           Authorization: "Bearer " + token
        }
     }
    await axios.post<IResetPassword>('/api/auth/reset', info, config);
}