import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';
import { OnsetDevelopment } from './Constants';

export class COVE extends Syndrome {
    
    getName() {
        return "COVE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 8, 9);
        if(mandatory.isYes()) {
            return new Yes(mandatory.getAnnotations());
        }
        
        let younger1y = super.checkAge(features, 0, 1);
        if(younger1y.isYes()) {
            return new No("Exlusionary: Age at onset younger than 1y.");
        }

        let older19y = super.checkAge(features, 19, 999);
        if(older19y.isYes()) {
            return new No("Exclusionary: Age at onset older than 19y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_sensory"];
        
        const result = this.getContainingSeizures(seizures, s1, true, annot, "focal sensory seizure", false, false);

        let mandatoryOk = false;
        if(result.found) {
            for(let seizure of result.seizures) {
                const visual = seizure.details.indexOf("nonmotor_sensory_visual") > -1;
                if(visual) {
                    annot.push("Mandatory present: Visual sensory seizures");
                } else {
                    annot.push("Mandatory missing: Visual sensory seizures");
                }

                const awake = seizure.timepoint === "wake_only";
                const wake_predom = seizure.timepoint === "wake_predominant";
                if(awake || wake_predom) {
                    annot.push("Mandatory present: Seizure only when awake or predominantly when awake.");
                } else {
                    annot.push("Mandatory missing: Seizure only when awake or predominantly when awake.");
                }

                mandatoryOk = visual && (awake || wake_predom);
                if(mandatoryOk)
                    break;
            }
        }
        

        if(mandatoryOk) {
            return new Yes(annot);
        }
        return new No(annot);
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_atypical"];
        const atypical = this.containsSeizure(seizures, s1, false, annot, "Atypical absences");

        s1.onset = "motor";
        s1.details = ["motor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Generalized myoclonic seizures");

        s1.details = ["motor_gen_atonic"];
        const atonic = this.containsSeizure(seizures, s1, false, annot, "Generalized atonic seizures");

        if(atypical || myoclonic || atonic) {
            return new No(annot);
        }
        return new Yes(annot);
    }

    protected checkDevelopmentDetails(dev: OnsetDevelopment[]): FeatureResponse {
        const annot: string[] = new Array();
        const exclusionaryOk = this.checkFeatures(dev, ["dev_regression", "dev_course_regression"], false, annot);
        if(!exclusionaryOk) {
            return new No(annot);
        }
        return new Unclear("No exlusionary developmental features.");
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        const exclusionaryOk = this.checkFeatures(exam, ["exam_visual_field_deficit", "exam_course_visual_field_deficit"], false, annot);
        if(!exclusionaryOk) {
            return new No(annot);
        }
        return new Unclear();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory features.");
        }

        const occ_ied = hasAnyFeatures(eeg, ["eeg_ied_occipital"]);
        if(occ_ied) {
            return new Yes("Mandatory present: Occipital spikes or spikes-and-wave abnormalities (awake or asleep).");
        } else {
            return new No("Mandatory missing: Occipital spikes or spikes-and-wave abnormalities (awake or asleep)");
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const lesion = hasAnyFeatures(imaging, ["imaging_lesion"]);
        const occ_calc = hasAnyFeatures(imaging, ["imaging_occ_calcifications"]);
        if(lesion || occ_calc) {
            let no = new No();
            if(lesion) {
                no.addAnnotation("Exlusionary: Causal lesion on MRI");
            }
            if(occ_calc) {
                no.addAnnotation("Exclusionary: Occipital lobe calcifications");
            }
            return no;
        } else {
            return new Unclear();
        }
    }

    getAlertCriteria(): string[] {
        return ["Age <6y or >14y", "Intellectual disability at onset of any degree", "Prolonged seizures (>15min)", "GTCS during wakefulness", "Generalized tonic-clonic seizures or typical absences",
                "Abnormal/focal neurological signs", "Sustained focal slowing on EEG not limited to the post-ictal phase"];
    }

}