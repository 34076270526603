import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';
import { hasMandatoryDevFeature, hasMandatoryDevFeatures } from './Development';

export class KCNQ2DEE extends Syndrome {
    
    getName() {
        return "KCNQ2DEE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 0, 3/12);
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_clonic"];
        const clonic = this.containsSeizure(seizures, s1, true, annot, "Focal clonic seizures", true, false);

        s1.details = ["motor_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, true, annot, "Focal myoclonic seizures", true, false);

        s1.details = ["motor_tonic"];
        const tonic = this.containsSeizure(seizures, s1, true, annot, "Focal tonic seizures", true, false);
        
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic"];
        const autonomic = this.containsSeizure(seizures, s1, true, annot, "Nonmotor autonomic seizures", true, false);

        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistant seizures", false, true);

        if(dre && (clonic || myoclonic || tonic || autonomic)) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        let mandatoryOk = hasMandatoryDevFeatures(dev, ["dev_severe_delay", "dev_encephalopathy"], annot, false, true, false);
        mandatoryOk = mandatoryOk && hasMandatoryDevFeatures(dev, ["dev_course_severe_delay", "dev_course_encephalopathy"], annot, false, false, false);
       
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
        
    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(history, ["history_family_negative", "history_sodium_blockers", 
            "history_feeding_difficulties", "history_behavioral_problems"], true, annot, true);
        
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();
        const interictalOk = this.checkFeatures(eeg, ["eeg_slowing", "eeg_burst_supression", "eeg_multifocal"], true, annot, true);
        const ictalOk = this.checkFeatures(eeg, ["eeg_ictal_low_fast", "eeg_focal_ictal_discharges"], true, annot, true);

        if(interictalOk && ictalOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        const geneticTestsAvailable = this.checkFeatures(tests, ["test_genetic"], true, [], true);
        if(!testsAvailable || !geneticTestsAvailable) {
            return new Yes("Genetic testing is not available however should be performed to identify KCNQ2 mutations.");
        }
        
        const annot: string[] = [];
        const gene = this.checkFeature(tests, "test_genetics_kcnq2", true, annot);
        
        return gene ? new Yes(annot) : new Unclear(annot);
    }

    getAlertCriteria(): string[] {
        return ["Normal development at onset", "Focal neurological signs"];
    }

}