import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures } from './Utils';

export class EMA extends Syndrome {
    
    getName() {
        return "EMA";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_myoclonic"];
        const hasS1 = this.containsSeizure(seizures, s1);

        if(hasS1) {
            return new Yes("Mandatory: Generalized nonmotor myoclonic absences present.");
        }
        return new No("Mandatory generalized nonmotor myoclonic absences missing.");
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1);

        if(focal) {
            return new No("Exclusionary: Focal seizures present");
        }
        return new Yes();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const ictal = hasAnyFeatures(eeg, ["eeg_ictal_sw_jerks"]);
        
        const focalSlowing = hasAnyFeatures(eeg, ["eeg_focal_slowing"]);
        const unilatFocalIED = hasAnyFeatures(eeg, ["eeg_unilateral_discharges"]);
        const diffuseSlowing = hasAnyFeatures(eeg, ["eeg_background_slowing"]);
        const genSw = hasAnyFeatures(eeg, ["eeg_gen_slow_sw"]);

        if(ictal) {
            if(focalSlowing || unilatFocalIED || diffuseSlowing || genSw) {
                return new No();
            } else {
                return new Yes();
            }
        } else {
            return new No();
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const normal = hasAnyFeatures(imaging, ["imaging_normal"]);
        
        if(normal) {
            return new Yes("Mandatory: MRI is normal.");
        } else {
            return new No("MRI is not normal, but is mandatory.");
        }
    }

    getAlertCriteria(): string[] {
        return ["Moderate or profound intellectual disability at onset", "Focal neurological signs"];
    }

}