import { hasAnyFeatures, hasFeature } from './Utils';

export const hasPosteriorIED = (eeg: string[], only: boolean, strict: boolean) => {
    if(!hasFeature(eeg, "eeg_ied")) {
        return false;
    }

    const ieds = hasFeature(eeg, "eeg_ied");
    let other = hasAnyFeatures(eeg, ["eeg_focal", "eeg_multifocal", "eeg_ied_frontal", "eeg_ied_central", "eeg_ied_parietal", "eeg_ied_temporal", "eeg_ied_occipital"]);
    if(strict) {
        // When strict, then only specifying ieds is not sufficient
        if(ieds && !other) {
            return false;
        }
    } else {
        // Non-strict, specifying only ieds would entail posterior ieds.
        if(ieds && !other) {
            return true;
        }
    }

    const focal = hasFeature(eeg, "eeg_focal");
    const multifocal = hasFeature(eeg, "eeg_multifocal");
    other = hasAnyFeatures(eeg, ["eeg_ied_frontal", "eeg_ied_central", "eeg_ied_parietal", "eeg_ied_temporal", "eeg_ied_occipital"]);
    if(strict) {
        // When strict, then only specifying focal/multifocal is not sufficient
        if((focal || multifocal) && !other) {
            return false;
        }
    } else {
        // Non-strict, specifying only focal/multifocal would entail posterior ieds.
        if((focal || multifocal) && !other) {
            return true;
        }
    }

    const posterior = hasAnyFeatures(eeg, ["eeg_ied_central", "eeg_ied_parietal", "eeg_ied_temporal", "eeg_ied_occipital"]);
    const frontal = hasFeature(eeg, "eeg_ied_frontal");
    if(only) {
        return posterior && !frontal;
    } else {
        return posterior;
    }
}

export const hasNormalBackground = (eeg: string[]) => {
    const excluded = ['eeg_hemispheric_abnorm', 'eeg_trigger_IPS', 'eeg_background_slowing', 
        'eeg_hemifocal_slowing', 'eeg_hemispheric_slowing', 'eeg_focal_slowing', 'eeg_slowing', 'eeg_progressive_slowing', 'eeg_burst_supression', 
        'eeg_discontinuity', 'eeg_hemispheric_supression', 'eeg_hypsarrhythmia'];

    return !hasAnyFeatures(eeg, excluded);
}