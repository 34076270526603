import { FeatureResponse, No, Unclear, Yes } from './FeatureResponse';
import { Seizure, compareSeizures, defaultSeizure, getCompatibleSeizures } from './Seizures';
import intl from 'react-intl-universal';
import { hasAnyFeatures } from './Utils';
import { Development } from './Constants';

export abstract class Syndrome {

    abstract getName(): string;
    
    public getAlertCriteria(): string[] | null {
        return null;
    }

    public checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return new Unclear("Age and gender not relevant.");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        return new Unclear();
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        return new Unclear();
    }

    /**
     * Check development features. This function takes care of the initial checks, 
     * i.e. if the development section exists and provides a non-empty value.
     * The specified development feature array is also checked and corrected for 
     * consistenty, e.g., if dev_normal is specified, abnormal development at onset
     * features are removed before passing to the checkDevelopmentDetails function.
     * @param features feature map
     * @returns whether development features are ok, unclear if no info is provided.
     */
    public checkDevelopment(features: Map<string, any>): FeatureResponse {
        let atOnsetAvailable = true;
        let atCourseAvailable = true;
        const development = features.get("development");
        if (development === undefined) {
            atOnsetAvailable = false;
            atCourseAvailable = false;
        } else {
            atOnsetAvailable = development.indexOf("dev_unavailable") === -1;
            atCourseAvailable = development.indexOf("dev_course_unavailable") === -1;
        }
        
        return this.checkDevelopmentDetails(development, atOnsetAvailable, atCourseAvailable);
    }

    /**
     * Check the development feature itself. Syndrome classes should override this function 
     * to perform the checks instead of checkDevelopment, which takes care of initial checks for empty sections.
     * @param dev the development feature
     * @param devAtOnsetAvailable info/features on development at onset available
     * @param devAtCourseAvailable info/features on development during the course available
     * @returns whether development features are ok, unclear or not ok
     */
    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        return new Unclear("Development not relevant.");
    }

    /**
     * Check neurological exam features. This function takes care of the initial checks, 
     * i.e. if the section exists and provides a non-empty value.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkNeuroExam(features: Map<string, any>): FeatureResponse {
        let atOnsetAvailable = true;
        let atCourseAvailable = true;
        const exam = features.get("neuroexam");
        if (exam === undefined) {
            atOnsetAvailable = false;
            atCourseAvailable = false;
        } else {
            atOnsetAvailable = exam.indexOf("exam_unavailable") === -1;
            atCourseAvailable = exam.indexOf("exam_course_unavailable") === -1;
        }
        
        return this.checkNeuroExamDetails(exam, atOnsetAvailable, atCourseAvailable);
    }

    /**
     * Check the neurological exam features. Syndrome classes should override this function 
     * to perform the checks instead of checkNeuroExam, which takes care of initial checks for empty sections.
     * @param exam the neurological exam features
     * @param devAtOnsetAvailable info/features on exam at onset available
     * @param devAtCourseAvailable info/features on exam during the course available
     * @returns whether features are ok, unclear or not ok
     */
    protected checkNeuroExamDetails(exam: string[], examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        return new Unclear("Neurological exam not relevant.");
    }

    /**
     * Check imaging features. This function takes care of the initial checks, 
     * i.e. if the section exists and imaging info is available.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkImaging(features: Map<string, any>): FeatureResponse {
        let available = true;
        const imaging = features.get("imaging");
        if (imaging === undefined) {
            available = false;
        } else {
            available = imaging.indexOf("imaging_unavailable") == -1;
        }
        
        return this.checkImagingDetails(imaging, available);
    }

    /**
     * Check the imaging features themselves. Syndrome classes should override this function 
     * to perform the checks instead of checkImaging, which takes care of initial checks for 
     * empty sections/missing info.
     * @param imaging the imaging features
     * @returns whether features are ok, unclear or not ok
     */
    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        return new Unclear("Imaging not revelant.");
    }

    /**
     * Check further tests features. This function takes care of the initial checks, 
     * i.e. if the section exists and tests info is available.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkTests(features: Map<string, any>): FeatureResponse {
        let available = true;
        const tests = features.get("tests");
        if (tests === undefined) {
            available = false;
        } else {
            available = tests.indexOf("tests_available") > -1;
        }
        
        return this.checkTestsDetails(tests, available);
    }

    /**
     * Check the further tests features themselves. Syndrome classes should override this function 
     * to perform the checks instead of checkTests, which takes care of initial checks for 
     * empty sections/missing info.
     * @param tests the imaging features
     * @returns whether features are ok, unclear or not ok
     */
    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        return new Unclear("Further tests not revelant.");
    }

    /**
     * Check history features. This function takes care of the initial checks, 
     * i.e. if the section exists and eeg info is available.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkHistory(features: Map<string, any>): FeatureResponse {
        let available = features.has("history");
        const history = features.get("history");
        
        return this.checkHistoryDetails(history, available);
    }

    /**
     * Check the history features themselves. Syndrome classes should override this function 
     * to perform the checks instead of checkHistory, which takes care of initial checks for 
     * empty sections/missing info.
     * @param history the history features
     * @returns whether features are ok, unclear or not ok
     */
    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        return new Unclear("History not relevant.");
    }

    /**
     * Check eeg features. This function takes care of the initial checks, 
     * i.e. if the section exists and eeg info is available. If inheriting syndromes are interested
     * in more than only the presence of certain eeg features, they should overwrite this
     * method and query the features of interest (e.g. the generalized spike wave frequency).
     * In this case, a call to this implementation via super.checkEEG can then take care
     * of the basic checks, e.g., whether eeg is available at all, etc.
     * @param features feature map
     * @returns whether features are ok, unclear if no info is provided.
     */
    public checkEEG(features: Map<string, any>): FeatureResponse {
        let eegAvailable = this.isEEGAvailable(features);
        let swFreq = features.get("swFreq");
        let sw = swFreq === "" ? NaN : Number(swFreq); 
        return this.checkEEGDetails(features.get("eeg"), eegAvailable, sw);
    }

    protected isEEGAvailable(features: Map<string, any>): boolean {
        if (!features.has("eeg"))
            return false;
        const eeg = features.get("eeg");
        return hasAnyFeatures(eeg, ["eeg_interictal_normal", "eeg_interictal_abnormal", 
            "eeg_ictal_normal", "eeg_ictal_abnormal", "eeg_sleep_normal", "eeg_sleep_abnormal"]);
    }

    /**
     * Check the eeg features themselves. Syndrome classes should override this function 
     * to perform the checks instead of checkEEG, which takes care of initial checks for 
     * empty sections/missing info, unless special or additional features of other sections
     * are required for a decision, see checkEEG function.
     * @param eeg the eeg features
     * @param eegAvailable whether information on EEG is available (has been performed). This 
     * already tested by the calling function (checkEEG) and provided for the syndrome to 
     * evaluate and include this in any decisions (e.g. also recommending to perform the 
     * investigation). Standard behavior is however to ignore this, i.e. the investigation
     * is not relevant.
     * @returns whether features are ok, unclear or not ok
     */
    protected checkEEGDetails(eeg: string[], eegAvailable: boolean, swFreq?: number): FeatureResponse {
        return new Unclear("EEG not relevant.");
    }

    /**
     * Check whether the onset age is between the min and max age (in years) as a mandatory feature
     * and add a respective annotation.
     * @param features map of features
     * @param minAge minimum age
     * @param maxAge maximum age
     * @return if age at onset is between min and max age or Unclear if ageOnset is not found in features
     */
    protected checkAge(features: Map<string, any>, minAge: number, maxAge: number): FeatureResponse {
        const inRange = this.isAgeInRange(features, minAge, maxAge);

        let minAgeFormatted: string;
        if (minAge < 1) {
            minAgeFormatted = `${minAge * 12} months`;
        } else {
            minAgeFormatted = `${minAge} years`;
        }

        let maxAgeFormatted: string;
        if (maxAge < 1) {
            maxAgeFormatted = `${maxAge * 12} months`;
        } else {
            maxAgeFormatted = `${maxAge} years`;
        }

        if (inRange) {
            return new Yes(`Mandatory: Age at onset between ${minAgeFormatted} and ${maxAgeFormatted}`);
        } else {
            // Since the correct age range is a boolean feature, age at onset not being in the correct range
            // is exclusionary.
            return new No(`Exclusinary: Age at onset not between ${minAgeFormatted} and ${maxAgeFormatted}`);
        }
    }

    /**
     * Check whether the onset age is between the min and max age (in years). This function can be used by 
     * inheriting classes for purposes other to check whether the age is in a certain range as a mandatory feature.
     * E.g. a typical application would be that age at onset in a certain age range (or below or above a certain age)
     * is exclusionary.
     * @param features map of features
     * @param minAge minimum age
     * @param maxAge maximum age
     * @return true if age at onset is between min and max age, false otherwise (also when age at onset is not defined in the features)
     */
    protected isAgeInRange(features: Map<string, any>, minAge: number, maxAge: number): boolean {
        if (!features.has("ageAtOnset"))
            return false;

        const ageTemp = features.get("ageAtOnset");
        if (ageTemp === "")
            return false;

        const ageAtOnsetUnit = features.get("ageAtOnsetUnit");
        const ageAtOnset = this.getAgeInYears(ageTemp, ageAtOnsetUnit);

        return ageAtOnset >= minAge && ageAtOnset <= maxAge;
    }

    protected getAgeInYears(age: number, unit: string) {
        let ageConverted: number;
        if (unit === "months") {
            ageConverted = age / 12;
        } else if (unit === "days") {
            ageConverted = age / 365;
        } else {
            ageConverted = age;
        }
        return ageConverted;
    }

    /**
     * Check presence of mandatory and exclusionary seizures
     * @param features map of features
     * @returns 
     */
    checkSeizureTypes(features: Map<string, any>): FeatureResponse {
        const seizures = features.get("seizures");
        if (seizures === undefined || seizures.length === 0) {
            return new Unclear();
        }

        // Check for the mandatory hyperkinetic, tonic motor focal seizures
        let mandatoryOk = this.checkMandatorySeizures(seizures);
        let exclusionaryOk = this.checkExclusionarySeizures(seizures);
        
        let response: FeatureResponse;
        if (mandatoryOk.isYes()) {
            if (exclusionaryOk.isYes() || exclusionaryOk.isUnclear()) {
                response = new Yes("All mandatory seizure types and no exclusionary seizure types present.");
            } else {
                response = new No("All mandatory seizure types, but also exclusionary seizure types present.");
            }
        } else {
            if (exclusionaryOk.isYes() || exclusionaryOk.isUnclear()) {
                response = new No("Not all mandatory seizure types present.");
            } else {
                response = new No("Not all mandatory seizure types but exclusionary seizure types present.");
            }
        }
        response.addAnnotations(mandatoryOk.getAnnotations());
        response.addAnnotations(exclusionaryOk.getAnnotations());
        
        return response;
    }

    /**
     * Check if the array of seizures contains the specified seizure (possibly with wildcards).
     * @param seizures array of seizures to search
     * @param seizure seizure to search for
     * @param mandatory option to specify whether the seizure is mandatory (true) or exclusionary (false) for
     * comments on decisions.
     * @param annot array of comments to add any further explanations
     * @param desc description of the seizure to add to the explanation
     * @param oneOfSeveral whether or not to add a description that this is one of several mandatory/exclusionary seizures
     * @param strict whether the general seizure category will also be accepted (false) instead of the exact seizure type (true)
     * @returns true if the array contains the specified seizure, false otherwise
     */
    containsSeizure(seizures: Seizure[], seizure: Seizure, mandatory?: boolean, annot?: string[], desc?: string, oneOfSeveral?: boolean, strict?: boolean) {
        if(strict === undefined) {
            strict = true;
        }

        // Check if the main type fits and details are not specified (remaining characteristics are ignored)
        const toFind = defaultSeizure();
        toFind.mainType = seizure.mainType;
        toFind.details = [];
        const response = getCompatibleSeizures(seizures, toFind);
        const generalOk = response.length > 0; // At least one seizure was found

        // Check specifics
        let found = false;
        for(let i=0;i<seizures.length;i++) {
            const v = compareSeizures(seizures[i], seizure);
            if(v > 0) {
                found = true;
                break;
            }
        };
        
        if(mandatory !== undefined && annot !== undefined) {            
            let presentMsg = found ? "present" : "not present";
            let mandatoryMsg = mandatory ? "Mandatory" : "Exclusionary";
            mandatoryMsg = oneOfSeveral ? "(One of several) " + mandatoryMsg : mandatoryMsg;
            let msg: string;
            
            if(!strict && (!found && generalOk)) {
                // Specific seizure was not found, but the main type fits and details are not specified.
                // Return an adjusted description
                msg = `${mandatoryMsg} present: Exact seizure type not found (${desc}), but main type is concordant and no further details have been specified`;
            } else {
                msg = `${mandatoryMsg} ${presentMsg}: ${desc}`;
            }
            annot.push(msg);
        }

        if(strict) {
            return found;
        } else {
            return found || generalOk;
        }
    }

    getContainingSeizures(seizures: Seizure[], seizure: Seizure, mandatory?: boolean, annot?: string[], desc?: string, oneOfSeveral?: boolean, strict?: boolean) {
        let result: Seizure[] = [];

        if(strict === undefined) {
            strict = true;
        }

        // Check if the main type fits and details are not specified (remaining characteristics are ignored)
        const toFind = defaultSeizure();
        toFind.mainType = seizure.mainType;
        toFind.details = [];
        const response = getCompatibleSeizures(seizures, toFind);
        const generalOk = response.length > 0; // At least one seizure was found
        
        // Check specifics
        let found = false;
        for(let i=0;i<seizures.length;i++) {
            const v = compareSeizures(seizures[i], seizure);
            if(v > 0) {
                found = true;
                result.push(seizures[i]);
            }
        };
        
        if(mandatory !== undefined && annot !== undefined) {            
            let presentMsg = found ? "present" : "not present";
            let mandatoryMsg = mandatory ? "Mandatory" : "Exclusionary";
            mandatoryMsg = oneOfSeveral ? "(One of several) " + mandatoryMsg : mandatoryMsg;
            let msg: string;
            
            if(!strict && (!found && generalOk)) {
                // Specific seizure was not found, but the main type fits and details are not specified.
                // Return an adjusted description
                msg = `${mandatoryMsg} present: Exact seizure type not found (${desc}), but main type is concordant and no further details have been specified`;
            } else {
                msg = `${mandatoryMsg} ${presentMsg}: ${desc}`;
            }
            annot.push(msg);
        }

        if(strict) {
            return {
                found: found,
                seizures: result
            };
        } else {
            const all = response.concat(result);
            return {
                found: found || generalOk,
                seizures: all
            };
        }
    }

    /**
     * Check whether the specified section contains the feature, adding respective comments to the annotations array.
     * @param section section to check
     * @param feature feature to check for
     * @param mandatory whether the feature is a mandatory or exclusionary feature (for selecting the correct comment)
     * @param annot array of comments
     * @returns whether the feature is present
     */
    protected checkFeature(section: string[], feature: string, mandatory: boolean, annot: string[]): boolean {
        const present = hasAnyFeatures(section, [feature]);
        let presentMsg: string;
        if(mandatory) {
            presentMsg = present ? "present" : "missing";
        } else {
            presentMsg = present ? "present" : "not present";
        }
        let mandatoryMsg = mandatory ? "Mandatory" : "Exclusionary";
        const msg = `${mandatoryMsg} ${presentMsg}: ${intl.get(feature)}`;
        annot.push(msg);
        return present;
    }

    /**
     * Check whether the specified section contains all of the features (mandatory true) or 
     * non of the features (mandatory false, thus exclusionary), adding respective features to the annotations.
     * @param section section to check
     * @param features features to check for
     * @param mandatory whether the features are mandatory or exclusionary
     * @param annot array of comments
     * @param some return ok if only some are present (mandatory) or not present (exclusionary). Default (undefined) is false.
     * @returns whether all of the features are present (mandatory true) or non of them (mandatory false, thus exclusionary)
     */
    checkFeatures(section: string[], features: string[], mandatory: boolean, annot: string[], some?: boolean): boolean {
        let someOk = some ?? false;
        let ok = true;
        if(someOk) {
            ok = false;
        }

        for(let feature of features) {
            let present = this.checkFeature(section, feature, mandatory, annot);
            if(mandatory) {
                if(someOk) {
                    ok = ok || present;
                } else {
                    ok = ok && present;
                }
            } else {
                if(someOk) {
                    ok = ok || !present;
                } else {
                    ok = ok && !present;
                }
            }
        }
        return ok;
    }

}