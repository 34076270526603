import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { Development } from './Constants';

export class EESWAS extends Syndrome {
    
    getName() {
        return "EESWAS";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        return new Yes("No mandatory seizures for this syndrome.");
    }

    public checkExlusionarySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_epileptic_spasms"];
        const hasS1 = this.containsSeizure(seizures, s1);
        
        if(hasS1) {
            return new No("Exclusionary present: Focal seizures with epileptic spasms.");
        } else {
            return new Yes();
        }   
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(dev, ["dev_dee_swas_regression"], true, annot);
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new Unresolved(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_sw_nrem_sleep"], true, annot);
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age >1y or <2y", "Focal tonic seizures during sleep", "Generalized paroxysmal fast activity in sleep", "Generalized slow spike-and-wave complexes of <2.5Hz in both awake and asleep state"];
    }

}