import { Development } from "./Constants";
import { FeatureResponse, No, Unclear, Unresolved, Yes } from "./FeatureResponse";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class FFEVF extends Syndrome {
    
    getName(): string {
        return "FFEVF";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 1 / 12, 56);
    }
    
    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let mandatoryOk = false;
        for (const seizure of seizures) {
            mandatoryOk = seizure.mainType === "focal";
            if (mandatoryOk) {
                break;
            }
        }
        
        if(mandatoryOk) {
            return new Yes("Mandatory present: Focal seizures");
        } else {
            return new No("Mandatory missing: Focal seizures");
        }
    }
    
    checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        let exclusionaryOk = true;
        for (const seizure of seizures) {
            exclusionaryOk = seizure.mainType !== "generalized" && seizure.mainType !== "unknown" && seizure.mainType !== "unclassified";
            if (!exclusionaryOk) {
                break;
            }
        }
        
        if(exclusionaryOk) {
            return new Yes("Exclusionary missing: Seizure types other than focal");
        } else {
            return new No("Exclusionary present: Seizure types other than focal");
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine exlusionary features.");
        }
        
        if (hasFeature(dev, "dev_disability_moderate") || hasFeature(dev, "dev_disability_profound") || hasFeature(dev, "dev_regression")) {
            return new No("Exclusionary present: Moderate or profound intellectual disability or developmental regression at onset.");
        }
        if (hasFeature(dev, "dev_disability_ns") || hasFeature(dev, "dev_disability_mild")) {
            return new Unclear();
        }
        return new Unclear("No exclusionary developmental features present"); // No mandatory features, so everything else not checked up here doesn't change anything
    }

    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = new Array();
        let mandatoryOk = this.checkFeature(history, "history_family_focal", true, annot);
        if(mandatoryOk)
            return new Yes(annot);
        return new No(annot);
    }
    
    getAlertCriteria(): string[] {
        return ["Age at onset < 1 month or > 56 years", "Mild intellectual disability"];
    }
}