export const removeFeature = (features: string[], feature: string) => {
    let index = features.indexOf(feature);
    while(index > -1) {
        features.splice(index, 1);
        index = features.indexOf(feature);
    }
}

export const removeFeatures = (features: string[], toRemove: string[]) => {
    toRemove.forEach(feature => removeFeature(features, feature));
}

export const addFeature = (features: string[], feature: string) => {
    if(features.indexOf(feature) === -1) {
        features.push(feature);
    }
}

export const addFeatures = (features: string[], toAdd: string[]) => {
    toAdd.forEach(feature => addFeature(features, feature));
}

export const hasFeature = (features: string[], feature: string) => {
    return features.indexOf(feature) > -1;
}

export const hasOnlyAllowedFeatures = (features: string[], allowedFeatures: string[]) => {
    let copy = [...features];
    for(let feature of allowedFeatures) {
        let index = copy.indexOf(feature);
        if(index > -1) {
            copy.splice(index, 1);
        }
    }
    return copy.length === 0;
}

export const toggleFeature = (features: string[], feature: string) => {
    if(hasFeature(features, feature)) {
        removeFeature(features, feature);
    } else {
        addFeature(features, feature);
    }
}

/**
     * Check if any of the toCheck features are contained in the specified section.
     * @param section section to search
     * @param toCheck array of features to look for
     * @returns FeatureResponse.YES if at least one of the features is found in the section, 
     *  NO if not and UNCLEAR if the section doesn't exist.
     */
export const hasAnyFeatures = (section: string[], toCheck: string[]) => {
    let found = false;
    if (section !== undefined) {
        for (let check of toCheck) {
            if (section.indexOf(check) > -1) {
                found = true;
            }
        }
    }

    return found;
}

export const findAnyFeatures = (section: string[], toCheck: string[]) => {
    let found: string[] = []; 
    if (section !== undefined) {
        for (let check of toCheck) {
            if (section.indexOf(check) > -1) {
                found.push(check);
            }
        }
    }

    return found;
}

  /**
     * Check if none of the toCheck features are contained in the specified section.
     * @param section 
     * @param toCheck 
     * @returns 
     */
export const hasNoneOfFeatures = (section: string[], toCheck: string[]) => {
    return !hasAnyFeatures(section, toCheck);
}
