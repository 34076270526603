import { Alert, Button, Col, Container, Form, FormGroup, Row, Stack } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { syndromes } from "../../classification/Syndromes";
import intl from "react-intl-universal";
import { submitValidation } from "../../requests/store";
import { useNavigate } from "react-router-dom";
import { setValidationInfo } from "../../features/validation/validationSlice";

function ValidationPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [syndromesList, setSyndromesList] = useState<string[]>([]);
    const [showMessage, setShowMessage] = useState(false);
    const features = useAppSelector(state => state.features.features);

    const validation = useAppSelector(state => state.validation);

    const [containedInLikely, setContainedInLikely] = useState(validation.containedInLikely);
    const [othersInLikely, setOthersInLikely] = useState(validation.othersInLikely);
    const [correctDiagnosis, setCorrectDiagnosis] = useState(validation.correctDiagnosis);
    const [comment, setComment] = useState(validation.comment);

    const containedInLikelyChanged = (e: React.FormEvent<HTMLInputElement>) => {
        setContainedInLikely(!containedInLikely);
    }

    const othersInLikelyChanged = (e: React.FormEvent<HTMLInputElement>) => {
        setOthersInLikely(!othersInLikely);
    }

    const selectedSyndromeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCorrectDiagnosis(e.target.value);
    }

    useEffect(() => {
        const temp = [...syndromes];
        temp.push("OtherDiagnosis");
        setSyndromesList(temp);
    }, []);

    useEffect(() => {
        dispatch(setValidationInfo({ containedInLikely: containedInLikely, othersInLikely: othersInLikely, correctDiagnosis: correctDiagnosis, comment: comment }));
    });

    const submit = async () => {
        const caseId = features.get("id");
        await submitValidation(caseId, containedInLikely, othersInLikely, correctDiagnosis, comment);
        setShowMessage(true);
        setTimeout(() => { setShowMessage(false) }, 3000);
    }

    return (
        <Container>
            <Row><h2>Validation</h2></Row>
            <Row><Col><p>This page is for validation purposes only, i.e., it will not be present in the published version of the app.</p></Col></Row>
            <Row><Col><h3>Questions</h3></Col></Row>
            <Row className="gap_above_small">
                <Col>
                    <p>Was the diagnosis in the list of “likely syndromes”? </p>
                    <FormGroup>
                        <Form.Check checked={containedInLikely} type="radio" label="Yes" value={0} onChange={containedInLikelyChanged} />
                        <Form.Check checked={!containedInLikely} type="radio" label="No" value={0} onChange={containedInLikelyChanged} />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="gap_above_small">
                <Col>
                    <p>Were there other syndromes in the “likely” list? </p>
                    <FormGroup>
                        <Form.Check checked={othersInLikely} type="radio" label="Yes" value={1} onChange={othersInLikelyChanged} />
                        <Form.Check checked={!othersInLikely} type="radio" label="No" value={1} onChange={othersInLikelyChanged} />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="gap_above_small">
                <Col>
                    <p>Please select the correct diagnosis for your case: </p>
                    <Form.Select aria-label="Correct diagnosis" title="Correct diagnosis" onChange={selectedSyndromeChanged}>
                        {
                            syndromesList.map(syndrome => {
                                return (
                                    <option key={syndrome} value={syndrome}>{intl.get(syndrome)}</option>
                                );
                            })
                        }
                    </Form.Select>
                </Col>
            </Row>
            <Row className="gap_above_small">
                <Col>
                    <p>Please share general considerations for the app? </p>
                    <Form.Control as="textarea" rows={10} value={comment} onChange={(e) => setComment(e.target.value)} />
                </Col>
            </Row>
            <Row className="gap_above">
                <Stack direction="horizontal" gap={2}>
                    <Col className="d-grid" md={{ span: 6 }}>
                        <Button variant={"secondary"} onClick={(e) => navigate("/pages/Results")}>
                            Go back
                        </Button>
                    </Col>
                    <Col className="d-grid" md={{ span: 6 }}>
                        <Button variant={"secondary"} onClick={submit}>
                            Submit
                        </Button>
                    </Col>
                </Stack>
                {showMessage &&
                    <Alert className="gap-above-small" key='success' variant='success'>
                        Comment submitted.
                    </Alert>
                }
            </Row>
        </Container>
    );
}

export default ValidationPage;