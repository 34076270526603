import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures } from './Seizures';

export class LGS extends Syndrome {
    
    getName() {
        return "LGS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let younger = super.checkAge(features, 0, 18);
        if(younger.isYes()) {
            return new Yes("Mandatory present: Age at onset younger than 18y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const hasFocal = this.containsSeizure(seizures, s1);

        s1 = defaultSeizure();
        s1.mainType = "generalized";
        const hasGeneralized = this.containsSeizure(seizures, s1);
        
        s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.details = ["motor_tonic"];
        const hasS1 = this.containsSeizure(seizures, s1);

        s1.mainType = "generalized";
        s1.details = ["nonmotor_gen_atypical"];
        const atyp_absences = this.containsSeizure(seizures, s1);

        s1.details = ["motor_gen_atonic"];
        const gen_atonic = this.containsSeizure(seizures, s1);

        s1.mainType = "focal";
        s1.details = ["motor_atonic"];
        const atonic = this.containsSeizure(seizures, s1);

        s1.mainType = "generalized";
        s1.details = ["motor_gen_myoclonic"];
        const gen_myoclonic = this.containsSeizure(seizures, s1);

        s1.mainType = "focal";
        s1.details = ["motor_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1);

        s1.mainType = "focal";
        s1.awareness = "impaired";
        s1.details = ["*"];
        const impairedSeizures = getCompatibleSeizures(seizures, s1);
        let impaired = false;
        for(let seizure of impairedSeizures) {
            if(seizure.details.indexOf("motor_tonic") === -1) {
                impaired = true;
                break;
            }
        }

        s1.mainType = "generalized";
        s1.awareness = "*";
        s1.details = ["motor_gen_tonic_clonic"];
        const gen_tonic_clonic = this.containsSeizure(seizures, s1);

        s1.mainType = "focal";
        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1);

        s1 = defaultSeizure();
        s1.other = ["seizure_other_non_convulsive_status"];
        const status = this.containsSeizure(seizures, s1);

        const annot: string[] = [];
        if(hasS1) {
            annot.push("Mandatory: Focal tonic seizures present.");
        } else {
            annot.push("Mandatory focal tonic seizures missing.");
        }
        if(atyp_absences) {
            annot.push("Additional atpical absences");
        }
        if(gen_atonic || atonic) {
            annot.push("Additional atonic seizures");
        }
        if(gen_myoclonic || myoclonic) {
            annot.push("Additional myoclonic seizures");
        }
        if(impaired) {
            annot.push("Additional focal seizure with impaired awareness");
        }
        if(gen_tonic_clonic) {
            annot.push("Additional tonic-clonic seizures");
        }
        if(spasms) {
            annot.push("Additional epileptic spasms");
        }
        if(status) {
            annot.push("Additional non-convulsive status epilepticus")
        }

        const anySpecific = hasS1 || atyp_absences || gen_atonic || atonic || gen_myoclonic || myoclonic || impaired || gen_tonic_clonic || spasms || status;
        if(!anySpecific) {
            if(hasFocal) {
                annot.push("Specific mandatory seizures not found, but focal seizures without any further details are specified: Keeping syndrome.");
            }
            if(hasGeneralized) {
                annot.push("Specific mandatory seizures not found, but generalized seizures without any further details are specified: Keeping syndrome.");
            }
        }
        
        if((hasFocal || hasGeneralized) || (hasS1 && (atyp_absences || gen_atonic || atonic || gen_myoclonic || myoclonic || impaired || gen_tonic_clonic || spasms || status))) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean, swFreq: number): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const sw = this.checkFeature(eeg, "eeg_gen_sw", true, annot);
        const fast_sleep = this.checkFeature(eeg, "eeg_gen_fast_sleep", true, annot);
        if(sw && !isNaN(swFreq)) {
            if(swFreq<2.5) {
                annot.push("Mandatory present: Spike-wave frequency below 2.5Hz");
            } else {
                annot.push("Mandatory missing: Spike wave frequency below 2.5Hz");
            }
        }

        const mandatoryOk = sw && fast_sleep;
        const exclusionaryOk = !this.checkFeature(eeg, "eeg_ictal_gen_fast", false, annot);
        
        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age >8y", "Photoparoxysmal EEG response at low frequencies"];
    }

}