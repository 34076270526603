import { Col, Container, Form, FormGroup } from "react-bootstrap";
import { FeatureCheck } from "../../components/FeatureCheck";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { updateFromEvent } from "./Utils";
import { setTestsFeatures } from "../../features/features/featuresSlice";
import { ClassificatorStage } from "../../classification/Classificator";

function TestPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    
    let temp = features.get("tests") === undefined ? [] as string[] : features.get("tests");
    const [tests, setTests] = useState<string[]>(temp);

    temp = features.get("genTestsFree") === undefined ? "" : features.get("genTestsFree");
    const [genTestsFree, setGenTestsFree] = useState(temp);

    temp = features.get("bloodTestsFree") === undefined ? "" : features.get("bloodTestsFree");
    const [bloodTestsFree, setBloodTestsFree] = useState(temp);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => updateFromEvent(e, tests, setTests);

    useEffect(() => {
        dispatch(setTestsFeatures({tests: tests, genTestsFree: genTestsFree, bloodTestsFree: bloodTestsFree}));
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({allowed: true, stage: ClassificatorStage.TESTS}));
    });

    const testsAvailable = tests.includes("tests_available");
    const geneticAvailable = tests.includes("test_genetic");

    return (
        <Container>
            <Form>
                <h2>Further tests or documented etiologies (genetic, metabolic, etc.)</h2>
                <p>Are results of any further tests available?</p>
                <FormGroup>
                    <FeatureCheck feature="tests_available" label="Tests available" featureArray={tests} onChange={handleChange} />
                </FormGroup>
            
                { testsAvailable && 
                    <div className="gap_above">
                        <h2>Genetic tests</h2>
                        <FormGroup>
                            <FeatureCheck feature="test_genetic" label="Genetic tests" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_array_cgh" label="Array-CGH" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_karyotype" label="Karyotype" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_gene_panel" label="Gene panel" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_wes" label="WES" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_wgs" label="WGS" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_genetic"]} offset={1} feature="test_mitochondrial_dna" label="Mitochondrial DNA" featureArray={tests} onChange={handleChange} />             
                        </FormGroup>
                        { geneticAvailable && 
                            <>
                                <FormGroup>
                                <FeatureCheck offset={1} feature="test_other" label="Other genetic tests (please specify):" featureArray={tests} onChange={handleChange} />
                                <Col md={{offset: 1}}>
                                    <Form.Control as="textarea" name="genTestsFree" rows={3} value={genTestsFree} onChange={(e) => setGenTestsFree(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <Col md={{offset: 1}}>
                                <h3 className="gap_above">Following your genetic testing, were variants in any of the following genes identified?</h3>
                            </Col>
                            <FormGroup>
                                <FeatureCheck offset={1} feature="test_genetics_cdkl5" label="CDKL5" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_kcnq2" label="KCNQ2" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_aldh7a1" label="ALDH7A1" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_plbp" label="PLBP" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_pcdh19" label="PCDH19" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_pnpo" label="PNPO" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_pmp22" label="PMP22" featureArray={tests} onChange={handleChange} />
                                <FeatureCheck offset={1} feature="test_genetics_tsc" label="TSC1/2" featureArray={tests} onChange={handleChange} /> 
                            </FormGroup>
                            </>
                        }
                        <h2 className="gap_above">Other investigations</h2>
                        <FormGroup>
                            <FeatureCheck feature="test_electrolyte" label="Electrolyte test" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck feature="test_toxicology" label="Toxicology screening" featureArray={tests} onChange={handleChange} />
                        </FormGroup>               
                        <FormGroup>
                            <FeatureCheck feature="test_blood_available" label="Metabolic work-up (blood) - specify:" featureArray={tests} onChange={handleChange} />
                            <Form.Control as="textarea" name="bloodTestsFree" rows={3} value={bloodTestsFree} onChange={(e) => setBloodTestsFree(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <FeatureCheck feature="test_csf" label="Metabolic work-up (CSF)" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_csf"]} offset={1} feature="test_csf_abnormal" label="Metabolic work-up (CSF) abnormal" featureArray={tests} onChange={handleChange} />
                            
                            <FeatureCheck prerequisites={["test_csf", "test_csf_abnormal"]} offset={2} feature="test_low_csf_glucose" label="Low CSF glucose" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_csf", "test_csf_abnormal"]} offset={2} feature="test_low_fasting_csf_glucose" label="Low fasting CSF glucose and CSF/plasma glucose ratio without other documented etiology for hypoglycorrhachia" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_csf", "test_csf_abnormal"]} offset={2} feature="test_lab_decreased_pyr" label="Decreased pyridoxal-5-phosphate in CSF" featureArray={tests} onChange={handleChange} />
                                                        
                            <FeatureCheck feature="test_metabolic_acidosis" label="Metabolic acidosis" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck feature="test_lab_semial_pipecolic" label="Increased a-aminoadipic semialdehyde and pipecolic acid in urine/plasma or CSF" featureArray={tests} onChange={handleChange}/>
                            
                            <FeatureCheck feature="test_lp_infection" label="Lumbar puncture showing evidence of central nervous system infection" featureArray={tests}	onChange={handleChange}/>
                            <FeatureCheck feature="test_csf_antibodies" label="Causal antibodies on CSF or plasma autoimmune testing" featureArray={tests} onChange={handleChange}/>
                            <FeatureCheck feature="test_autoimmune" label="Auto-immune epilepsy screening" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck feature="test_skin_biopsy" label="Skin biopsy" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck feature="test_brain_biopsy" label="Brain biopsy" featureArray={tests} onChange={handleChange} />
                        </FormGroup>

                        <h3 className="gap_above_small">Documented etiologies and diagnoses</h3>
                        <FormGroup>
                            <FeatureCheck feature="test_etiology_documented" label="Documented etiology for the patient's phenotype following the diagnostic work-up (i.e. genetic, metabolic, imaging etc)" featureArray={tests}  onChange={handleChange}/>
                            <FeatureCheck prerequisites={["test_etiology_documented"]} offset={2} feature="test_etiology_metabolic" label="Metabolic etiology" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_etiology_documented"]} offset={2} feature="test_etiology_genetic" label="Genetic etiology" featureArray={tests} onChange={handleChange} />
                            <FeatureCheck prerequisites={["test_etiology_documented"]} offset={2} feature="test_etiology_toxic" label="Toxic etiology" featureArray={tests} onChange={handleChange} />
                        </FormGroup>
                    </div>
                }
            </Form>
            <NextPreviousButtons next={() => navigate("/pages/AlertCriteria")} previous={() => navigate("/pages/Imaging")} />
        </Container>
    );
}

export default TestPage;
