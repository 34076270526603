import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class EIDEE extends Syndrome {
    
    getName() {
        return "EIDEE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 0, 3/12);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset <3 months.");
        }
                
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.onset = "motor";
        s1.details = ["motor_clonic"];
        const clonic = this.containsSeizure(seizures, s1, true, annot, "Focal clonic seizures", false, false);

        s1.details = ["motor_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, true, annot, "Epileptic spasms", false, false);

        s1.details = ["motor_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, true, annot, "Focal myoclonic seizures", false, false);

        s1.details = ["motor_tonic"];
        const tonic = this.containsSeizure(seizures, s1, true, annot, "Focal tonic seizures", false, false);

        s1.mainType = "generalized";
        s1.details = ["motor_gen_tonic"];
        const gen_tonic = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic seizures", false, false);

        s1.details = ["motor_gen_myoclonic"];
        const gen_myoclonic = this.containsSeizure(seizures, s1, true, annot, "Generalized myoclonic seizures", false, false);

        s1 = defaultSeizure();
        s1.other = ["seizure_drug_resistant"];
        const dre = this.containsSeizure(seizures, s1, true, annot, "Drug resistant seizures", false, true);

        if(dre && (tonic || myoclonic || gen_tonic || gen_myoclonic || clonic || spasms)) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine mandatory abnormal development at onset and during the course.");
        }

        const annot: string[] = new Array();

        const normal = hasFeature(dev, "dev_normal");
        const course_normal = hasFeature(dev, "dev_course_normal");

        if(normal) {
            annot.push("Mandatory missing: Abnormal development at onset.")
        } else {
            annot.push("Mandatory present: Abnormal development at onset.");
        }

        if(course_normal) {
            annot.push("Mandatory missing: Abnormal development during the course.")
        } else {
            annot.push("Mandatory present: Abnormal development during the course.");
        }

        if(!normal && !course_normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory abnormal exam at onset and during the course.");
        }

        const normal = hasFeature(exam, "exam_normal");
        const normal_course = hasFeature(exam, "exam_course_normal");
        
        if(!normal && !normal_course) {
            return new Yes("Mandatory present: Abnormal neurological exam");
        }
        return new Unclear();
    }
    
    protected checkHistoryDetails(history: string[], historyAvailable: boolean): FeatureResponse {
        if(!historyAvailable) {
            return new Unresolved("History is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(history, ["history_sodium_blockers", 
            "history_feeding_difficulties", "history_behavioral_problems"], true, annot, true);
                
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const ok = this.checkFeatures(eeg, ["eeg_burst_supression", "eeg_sleep_burst_supression", 
            "eeg_multifocal", "eeg_background_slowing", "eeg_discontinuity", "eeg_ictal_neonatal_myoclonus_no_EEG", "eeg_focal_ictal_discharges"], true, annot, true);
        
        if(ok) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Structural brain abnormalities are a frequent cause and should be checked.");
        }
        
        return new Yes("Structural brain abnormalities may or may not be present.");
    }

    getAlertCriteria(): string[] {
        return ["Normal development at onset", "Normal neurological exam at onset", "Normal interictal EEG"];
    }

}