import { Alert, Button, Card, Col, Container, FormControl, FormGroup, FormLabel, Image, Row } from "react-bootstrap";
import Jumbotron from "../../components/Jumbotron";
import Collapsible from "react-collapsible";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { logIn } from "../../requests/auth";
import { useAppDispatch } from "../../app/hooks";
import { setUser } from "../../features/user/userSlice";

function LandingPage() {
    const dispatch = useAppDispatch();
    const showLogin = true;
    
    const [email, setEmail] = useState('');
    const onChangeEmail = (event: any) => {
        setEmail(event.target.value);
    };
    const [password, setPassword] = useState('');
    const onChangePassword = (event: any) => {
        setPassword(event.target.value);
    };

    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();
    const handleNextButton = async () => {
        try {
            if(showLogin) {
                const response = await logIn({ email: email, password: password });
                setShowAlert(false);
                dispatch(setUser({id: response.id, name: response.name, firstName: response.firstName, institution: response.institution, email: response.email})); 
            } else {
                dispatch(setUser({id: 0, name: "TestUser", firstName: "Test", institution: "Test Institution", email: "test@test.org"}));
            }
            navigate("/pages/Age");
        } catch(error) {
            setShowAlert(true);
        }
        
    }

	return (
		<Container>
			<Col md={12}>		
                <Row>
                    <Col xs="auto" md="auto">
                        <Image className="logo" src="./EpiCare.svg" fluid />
                    </Col>
                    <Col xs="auto" md="auto">
                        <Image className="logo" src="./ILAE.gif" fluid />
                    </Col>
                    <Col xs="2" md="2">
                        <Image className="logo" src="./HCL.svg" fluid />
                    </Col>
                </Row>
                <Row className="gap_above_small">
					<Jumbotron title="ENGenE App" subheading="An educational tool on Epilepsy Nosology and Genetic Etiologies" />
                    <p>A simplified, interactive decision-making process tool for trainees and non-experts caring for people with epilepsy that will 
                        enhance and stratify knowledge regarding the syndromic classification with a special focus on genetic etiologies. </p>
				</Row>
				<Row>
					<p>The ENGenE app is currently undergoing validation and not available yet for public use.</p>
				</Row>
				<Row>
					<Collapsible trigger={<h3>Version 18 February 2024</h3>}></Collapsible>
				</Row>
                <Row>
                    <Collapsible trigger={<h3>Publications</h3>}>                   
                        <p>
                            Mastrangelo M, Celato A, Leuzzi V. A diagnostic algorithm for the evaluation of early onset genetic-metabolic epileptic encephalopathies. 
                            Eur J Paediatr Neurol 2012 Mar;16(2):179-91. <a href='https://pubmed.ncbi.nlm.nih.gov/21940184/'>Pubmed</a>
                        </p>
                        <p>
                            Hirsch E, French J, Scheffer IE, Bogacz A, Alsaadi T, Sperling MR et al. ILAE definition of the Idiopathic Generalized Epilepsy Syndromes: 
                            Position statement by the ILAE Task Force on Nosology and Definitions. Epilepsia 2022;63(6):1475-99.
                            {" "}<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17236">Link</a>
                        </p>
                        <p>
                            Riney K, Bogacz A, Somerville E, Hirsch E, Nabbout R, Scheffer IE et al. International League Against Epilepsy classification and definition of 
                            epilepsy syndromes with onset at a variable age: position statement by the ILAE Task Force on Nosology and Definitions. Epilepsia 2022;63(6):1443-74.
                            {" "}<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17240">Link</a>
                        </p>
                        <p>
                            Specchio N, Wirrell EC, Scheffer IE, Nabbout R, Riney K, Samia P et al. International League Against Epilepsy classification and definition of 
                            epilepsy syndromes with onset in childhood: Position paper by the ILAE Task Force on Nosology and Definitions. Epilepsia 2022;63(6):1398-442.
                            {" "}<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17241">Link</a>
                        </p>
                        <p>
                            Zuberi SM, Wirrell E, Yozawitz E, Wilmshurst JM, Specchio N, Riney K et al. 
                            ILAE classification and definition of epilepsy syndromes with onset in neonates and infants: Position statement by the ILAE Task Force on Nosology and Definitions. Epilepsia 2022;63(6):1349-97.
                            {" "}<a href='https://onlinelibrary.wiley.com/doi/10.1111/epi.17239'>Link</a>
                        </p>
                        <p>
                            Wirrell EC, Nabbout R, Scheffer IE, Alsaadi T, Bogacz A, French JA et al. Methodology for classification and definition of epilepsy syndromes 
                            with list of syndromes: Report of the ILAE Task Force on Nosology and Definitions. Epilepsia 2022;63(6):1333-48.
                            {" "}<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17237">Link</a>
                        </p>
                        
                    </Collapsible>
                </Row>
                        
                <Row>
                    <Collapsible open={false} trigger={<h3>Agreement to Terms and Conditions</h3>}>                       
                        <p>
                            This interactive decision support tool on Epilepsy Nosology and Genetic Etiologies is an educational tool funded by the CEF Telecom 2020 project. 
                            Its usage is reserved to healthcare professionals for educational reasons only. The GDPR conformity of the ENGenE App has been validated by the 
                            University Hospitals of Lyon. More information can be found <a href="./data/Mentions HCL_ENGenE App_english.pdf">here</a>.
                        </p>
                        <p>
                            The decisional support Algorithm was based on the Mandatory/Exclusionary/Alert criteria for each epileptic syndrome as defined by the last classification 
                            of the Nosology Committee of the International Ligue Against Epilepsy. The methodology to define those criteria is detailed in{" "} 
                            <a href="https://pubmed.ncbi.nlm.nih.gov/21940184/">this paper</a> and was not intended for the built-up of an Algorithm. Although a validation procedure was followed, the Algorithm may be subject to 
                            mistakes and is not intended to be used as a diagnostic tool and by no means to replace/delay/interfere with the established diagnostic procedure of each center/clinician.  
                        </p>
                        <p>
                            Furthermore, the results provided by the Algorithm are based on the information that is entered by the user at a specific time-point and are subject to data 
                            inaccuracy or variability at different time-points of a disease course. The information included for each syndrome is also according to the detailed description of the epileptic syndromes 
                            (<a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17240">Riney et al., 2022</a>, 
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17241">Specchio et al., 2022</a>, 
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17239">Zuberi et al., 2022</a>,
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/epi.17237">Wirell et al., 2022</a>)
                            and further developed/revised with focus on genetic aetiologies. The list of genetic causes is however not exhaustive and is not intended to replace 
                            standard procedures for genetic diagnosis or genetic counselling. 
                        </p>
                        <p>
                            You agree that by creating an account and accessing the web-based tool, you have read, understood, and agree to be bound by all of these Terms and Conditions Use. 
                            IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING IT AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                        </p>
                    </Collapsible>
                </Row>

                { showLogin &&
                    <Row>
                        <Card>
                            <Card.Body>
                                <FormGroup controlId="email">
                                    <FormLabel>Email address</FormLabel>
                                    <FormControl type="email" placeholder="Enter email" value={email} onChange={onChangeEmail} />
                                </FormGroup>
                                <FormGroup controlId="password">
                                    <FormLabel>Password</FormLabel>
                                    <FormControl type="password" placeholder="Password" value={password} onChange={onChangePassword} />
                                </FormGroup>
                                {/* <Button variant="link" onClick={() => navigate("/Register")}>Register</Button>
                                <Button variant="link" onClick={() => navigate("/Reset")}>Forgot login info?</Button> */}
                                <p>Are you part of the validation team and have no access yet or forgot your login? Please contact Stefan or Marietta.</p>
                            </Card.Body>
                        </Card>
                    </Row>
                }
                <Row>
                { showAlert && <Alert variant="danger">Email address or password wrong!</Alert>}
                </Row>
                <Row className="gap_above">
                    <Button variant="secondary" onClick={handleNextButton}>
                        Accept terms and login
                    </Button>
                </Row>
			</Col>
		</Container>
	);
}

export default LandingPage;
