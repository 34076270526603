import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ValidationState {
    containedInLikely: boolean;
    othersInLikely: boolean;
    correctDiagnosis: string;
    comment: string;
}

const initialState: ValidationState = {
   containedInLikely: false,
   othersInLikely: false,
   correctDiagnosis: "",
   comment: ""
}

export const validationSlice = createSlice({
    name: 'validation',
    initialState,
    reducers: {
        setValidationInfo: (state, action: PayloadAction<{containedInLikely: boolean, othersInLikely: boolean, correctDiagnosis: string, comment: string}>) => {
            state.containedInLikely = action.payload.containedInLikely;
            state.othersInLikely = action.payload.othersInLikely;
            state.correctDiagnosis = action.payload.correctDiagnosis;
            state.comment = action.payload.comment;
        }
    }
});

export const { setValidationInfo } = validationSlice.actions;

export const selectValidation = (state: RootState) => state.validation;

export default validationSlice.reducer;