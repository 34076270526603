import { Modal, Button, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { showAccountView } from "../features/account/accountUISlice";
import { selectUser } from "../features/user/userSlice";

function AccountView() {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const show = useAppSelector((state) => state.accountUI.open);

	const handleClose = () => dispatch(showAccountView(false));

	return (
		<div className="modal show" style={{ display: "block", position: "initial" }}>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Account info</Modal.Title>
				</Modal.Header>

				{user !== null && (
					<Modal.Body>
						<Row>
							<FormGroup controlId="firstName">
								<FormLabel>First Name</FormLabel>
								<FormControl disabled type="text" value={user.firstName} />
							</FormGroup>
						</Row>
						<FormGroup controlId="name">
							<FormLabel>Last Name</FormLabel>
							<FormControl disabled type="text" value={user.name} />
						</FormGroup>
						<FormGroup controlId="institution">
							<FormLabel>Institution</FormLabel>
							<FormControl disabled type="text" value={user.institution} />
						</FormGroup>
						<FormGroup controlId="email">
							<FormLabel>Email address</FormLabel>
							<FormControl disabled type="email" value={user.email} />
						</FormGroup>
					</Modal.Body>
				)}

				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default AccountView;
