import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { Seizure, defaultSeizure, getCompatibleSeizures } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class POLE extends Syndrome {
    
    getName() {
        return "POLE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 4, 17);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Age at onset between 4 and 17y.");
        }
        let younger = super.checkAge(features, 0, 1);
        if(younger.isYes()) {
            return new No("Exclusionary: Age at onset <1y.");
        }
        let older = super.checkAge(features, 50, 999);
        if(older.isYes()) {
            return new No("Exclusionary: Age at onset >50y.");
        }
        
        return new Unclear();
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();
        
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        s1.details = ["nonmotor_sensory"];
        const result = this.getContainingSeizures(seizures, s1, true, annot, "focal sensory seizures, triggered by photic stimuli and potential focal to bilateral tonic clonic seizures", false, false);
        
        // Check further details
        let visual_found = false;
        let photic_found = false;
        let both = false;
        if(result.found) {
            for(let seizure of result.seizures) {
                const visual = seizure.details.indexOf("nonmotor_sensory_visual") > -1;
                const photic = seizure.triggers.indexOf("seizure_trigger_photic") > -1;
                if(!visual_found && visual) {
                    visual_found = true;
                }
                if(!photic_found && photic) {
                    photic_found = true;
                }
                if(visual && photic) {
                    both = true;
                    break;
                }
            }
        }

        if(visual_found && photic_found && !both) {
            annot.push("Sensory visual seizures and photic trigger found in different seizures, mandatory criterion however requires occurence in the same.");
        }

        if(result.found && both) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.details = ["motor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "generalized onset myoclonic seizures");

        s1.details = ["motor_gen_atonic"];
        const atonic = this.containsSeizure(seizures, s1, false, annot, "generalized onset atonic seizures");

        s1.details = ["nonmotor_gen_eyelid_myoclonia"];
        const eyelid_myoclonia = this.containsSeizure(seizures, s1, false, annot, "generalized onset seizures with eyelid myoclonia");

        if(myoclonic || atonic || eyelid_myoclonia) {
            return new No(annot);
        } else {
            return new Yes(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine exlusionary neurocognitive regression at onset.");
        }

        if(hasFeature(dev, "dev_regression")) {
            return new No("Exlusionary: Neurocognitive regression");
        }
        return new Unclear();
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        if(hasFeature(exam, "exam_visual_field_deficit") || hasFeature(exam, "exam_course_visual_field_deficit")) {
            return new No("Exlusionary: Persistent visual field deficit");
        }
        return new Unclear();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory features.");
        }

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_trigger_IPS", "eeg_ied_occipital"], true, annot);
        
        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new Unclear();
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(imaging, ["imaging_lesion"], false, annot);

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new Unclear();
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset <4y or >17y", "Prolonged seizures (>15 min)", "Moderate or profound intellectual disability", "Any abnormal neurological sign",
                "Sustained focal slowing on EEG not limited to the post-ictal phase", "PPR at slow photic frequency (1-2 Hz) suggests CLN2 disease"];
    }

}