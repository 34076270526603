import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure, getCompatibleSeizures, Seizure } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class Glut1DS extends Syndrome {
    
    getName() {
        return "Glut1DS";
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = new Array();

        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, true, annot, "Focal seizures", true);

        s1.mainType = "generalized";
        const gen = this.containsSeizure(seizures, s1, true, annot, "Generalized tonic clonic seizures", true);

        if(focal || gen) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtCourseAvailable) {
            return new Unresolved("Info on development during the course is not available however is critical to determine mandatory neurodevelopmental impairment during the course.");
        }

        const annot: string[] = new Array();
        const disability = this.checkFeature(dev, "dev_course_abnormal", true, annot);

        if(disability) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkTestsDetails(tests: string[], testsAvailable: boolean): FeatureResponse {
        if(!testsAvailable) {
            return new Unresolved("Info on tests are not available however are critical to determine mandatory features.");
        }

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(tests, ["test_low_fasting_csf_glucose"], true, annot);

        if(mandatoryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Focal neurological signs"];
    }

}