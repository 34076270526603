import { FeatureResponse, No, Unclear, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasAnyFeatures, hasFeature } from './Utils';

export class EMAtS extends Syndrome {
    
    getName() {
        return "EMAtS";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        return super.checkAge(features, 6/12, 8);
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_myoclonic_atonic"];
        const myoclonic_atonic = this.containsSeizure(seizures, s1, true, annot, "Generalized myoclonic-atonic seizures", true, false);

        s1.details = ["motor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, true, annot, "Generalized myoclonic seizures", true, false);

        s1.details = ["motor_gen_atonic"];
        const atonic = this.containsSeizure(seizures, s1, true, annot, "Generalized atonic seizures", true, false);

        if(myoclonic_atonic || (myoclonic && atonic) || atonic) {
            return new Yes(annot);
        }
        return new No(annot);
    }

    public checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];
        
        let s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, false, annot, "Focal seizures");

        s1.mainType = "generalized";
        s1.details = ["motor_gen_epileptic_spasms"];
        const spasms = this.containsSeizure(seizures, s1, false, annot, "Generalized onset epileptic spasms");

        if(focal || spasms) {
            return new No(annot);
        }
        return new Yes(annot);
    }


    protected checkEEGDetails(eeg: string[], eegAvailable: boolean, swFreq: number | undefined): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = new Array();   
        let exclusionaryOk = this.checkFeatures(eeg, ["eeg_focal_slowing", "eeg_unilateral_discharges", "eeg_focal", "eeg_hypsarrhythmia"], false, annot);

        const psw = hasFeature(eeg, "eeg_gen_sw_psw");
        let gen_sw = hasFeature(eeg, "eeg_gen_sw");
        if(gen_sw) {
            if(swFreq === undefined || Number.isNaN(swFreq)) {
                annot.push("Generalized spike wave present, but frequency unclear. Frequency 2-6Hz is mandatory, still counting as likely.");
            } else {
                if(swFreq < 2) {
                    annot.push("Mandatory missing: Generalized spike wave present but frequency is slower than 2-6Hz");
                    gen_sw = false;
                } else if(swFreq > 6) {
                    annot.push("Mandatory missing: Generalized spike wave present but frequency is higher than 2-6Hz");
                    gen_sw = false;
                } else {
                    annot.push("Mandatory present: 2-6Hz generalized spike wave");
                }
            }
        } else {
            annot.push("Mandatory missing: 2-6Hz generalized spike wave");
        }      


        if((psw || gen_sw) && exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory and exlusionary features.");
        }
        
        const lesion = hasAnyFeatures(imaging, ["imaging_lesion"]);
        
        if(lesion) {
            return new No("Exlusionary: Causal lesion on MRI.");
        } 
        return new Unclear();
    }

    getAlertCriteria(): string[] {
        return ["Generalized tonic seizures within 12 months of disease onset", "Moderate or profound intellectual disability at onset", "Focal neurological abnormalities", 
                "Generalized paroxysmal fast activity in sleep", "Generalized slow spike- and- wave complexes of <2 Hz", "Photoparoxysmal response at low frequencies"];
    }

}