import React from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";

export interface NextPreviousButtonsProps {
    next?: React.MouseEventHandler<HTMLButtonElement>;
    previous?: React.MouseEventHandler<HTMLButtonElement>;
    nextDisabled?: boolean;
}

export const NextPreviousButtons = (props: NextPreviousButtonsProps) => { 
    let md;
    if(props.previous !== undefined) {
        md = {span: 6};
    } else {
        md = {offset: 6, span: 6};
    }

    return (
        <Row className="gap_above">
            <Stack direction="horizontal" gap={2}>
                {(props.previous !== undefined) &&
                    <Col className="d-grid" md={md}>
                        <Button variant={"secondary"} onClick={props.previous}>
                            Previous step
                        </Button>
                    </Col>
                }
                {(props.next !== undefined) &&
                    <Col className="d-grid" md={md}>
                        <Button variant={"secondary"} onClick={props.next} disabled={props.nextDisabled}>
                            Next step
                        </Button>
                    </Col>
                }

            </Stack>
        </Row>);
}