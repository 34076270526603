import React, { useState } from "react";
import { FormGroup, Form, Col } from 'react-bootstrap';
import { FeatureRadio } from "./FeatureRadio";
import { findAnyFeatures } from "../classification/Utils";

export interface FeatureCheckProps {
    mainFeature: string;
    mainLabel: string;
    features: string[];
    labels: string[];
    featureArray: string[];
    onChange: (mainFeatureSelected: boolean, mainFeature: string, option: string, previous: string) => void;
}

export const GroupedFeatureRadio = (props: FeatureCheckProps) => {
    const presentFeatures = findAnyFeatures(props.featureArray, props.features);

    const [open, setOpen] = useState(presentFeatures.length > 0);
    const [selection, setSelection] = useState(presentFeatures[0]);
    const [previous, setPrevious] = useState("");

    let onOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value; 
        setPrevious(selection);
        setSelection(value);
        props.onChange(open, props.mainFeature, value, selection);
    };

    let onMainFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOpen(!open);
        props.onChange(!open, props.mainFeature, selection, previous);
    };

    let options = [];
    for(let i=0;i<props.features.length;i++) {
        options.push(
        <Col key={i} md={{offset: 1}}>
            <FeatureRadio feature={props.features[i]} label={props.labels[i]} selection={selection} onChange={onOptionsChange} />
        </Col>);
    }
    
    return <FormGroup>
        <Form.Check checked={open} type="checkbox" label={props.mainLabel} value={props.mainFeature} onChange={onMainFeatureChange}/>
        { open &&
            <FormGroup>
                {options}
            </FormGroup>
        }
    </FormGroup>
}