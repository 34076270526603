import { FeatureResponse, No, Unresolved, Yes } from './FeatureResponse';
import { Syndrome } from './Syndrome';
import { defaultSeizure } from './Seizures';
import { hasFeature } from './Utils';
import { Development } from './Constants';

export class SeLNE extends Syndrome {
    
    getName() {
        return "SeLNE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        let mandatory = super.checkAge(features, 0, 28/365);
        if(mandatory.isYes()) {
            return new Yes("Mandatory present: Onset within the first 28 days.");
        }
                
        return new No("Mandatory missing: Onset within the first 28 days.");
    }

    public checkMandatorySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "focal|sequential";
        s1.onset = "motor";
        s1.details = ["motor_automatisms"];
        const automatisms = this.containsSeizure(seizures, s1, true, annot, "focal/sequential automatisms", true, false);

        s1.details = ["motor_tonic"];
        const tonic = this.containsSeizure(seizures, s1, true, annot, "focal/sequential tonic seizures", true, false);

        s1.details = ["motor_clonic"];
        const clonic = this.containsSeizure(seizures, s1, true, annot, "focal/sequential clonic seizures", true, false);

        s1.mainType = "focal|sequential";
        s1.onset = "nonmotor";
        s1.details = ["nonmotor_autonomic"];
        const autonomic = this.containsSeizure(seizures, s1, true, annot, "focal/sequential autonomic seizures", true, false);

        s1 = defaultSeizure();
        s1.details = ["focbilattc"];
        const foc2bilat = this.containsSeizure(seizures, s1, true, annot, "focal to bilateral tonic-clonic seizures", true, false);

        if(automatisms || clonic || tonic || autonomic || foc2bilat) {   
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine normal development at onset and during the course.");
        }

        const annot: string[] = [];
        const normal = this.checkFeatures(dev, ["dev_normal", "dev_course_normal"], true, annot);
        
        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable || !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine normal exam at onset and during the course.");
        }

        const annot: string[] = [];
        const normal = hasFeature(exam, "exam_normal") && hasFeature(exam, "exam_course_normal");
        if(normal) {
            annot.push("Mandatory present: Normal neurological examination");
        } else {
            annot.push("Mandatory missing: Normal neurological examination");
        } 

        if(normal) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        // Exclusionary : burst-supression, persistent focal slowing, Hypsarrhytmia

        const annot: string[] = [];
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_burst_supression", "eeg_focal_slowing", "eeg_hypsarrhythmia", "eeg_ictal_lack_EEG_correlate"], false, annot);
        
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unresolved("Imaging is not available however is critical to determine mandatory normal imaging.");
        }
        
        const normal = hasFeature(imaging, "imaging_normal");
        if(normal) {
            return new Yes("Mandatory present: Neuroimaging normal");
        } else {
            return new No("Exclusionary present: Abnormal neuroimaging");
        }
    }

    getAlertCriteria(): string[] {
        return ["Significant abnormalities on neurological examination", "Mild neurodevelopment delay long-term", "drug resistant epilepsy",
            "Mild background slowing on EEG"];
    }

}