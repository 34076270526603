import { Container, FormGroup, Form, Stack } from "react-bootstrap";
import { FeatureRadio } from "../../components/FeatureRadio";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setFeatures } from "../../features/features/featuresSlice";
import { setAllowedAndStage } from "../../features/classificationUI/classificationUISlice";
import { ClassificatorStage } from "../../classification/Classificator";
import { v4 as uuid } from 'uuid';

function AgePage() {
    const features = useAppSelector(state => state.features.features);
    
    let temp = features == null ? uuid() : features.get("id");
    const [id, setId] = useState(temp === undefined ? uuid() : temp);

    temp = features == null ? 'male' : features.get("gender");
    const [gender, setGender] = useState(temp === undefined ? 'male': temp);
    
    temp = features == null ? '' : features.get("age");
    const [age, setAge] = useState(temp === undefined ? '': temp);
    
    temp = features == null ? 'years' : features.get("ageUnit");
    const [ageUnit, setAgeUnit] = useState(temp === undefined ? 'years': temp);
    
    temp = features == null ? '' : features.get("ageAtOnset");
    const [ageAtOnset, setAgeAtOnset] = useState(temp === undefined ? '': temp);
    
    temp = features == null ? 'years' : features.get("ageAtOnsetUnit");
    const [ageAtOnsetUnit, setAgeAtOnsetUnit] = useState(temp === undefined ? 'years': temp);
    
    const navigate = useNavigate();
    const handleNextButton = () => {
        navigate("/pages/SeizureType");
    }

    const dispatch = useAppDispatch();
    const storeFeatures = () => {
        const features = new Map<string, any>();
        features.set("id", id);
        features.set("age", age);
        features.set("ageUnit", ageUnit);
        features.set("ageAtOnset", ageAtOnset);
        features.set("ageAtOnsetUnit", ageAtOnsetUnit);
        features.set("gender", gender);
        dispatch(setFeatures(features));
    }

    useEffect(() => {
        storeFeatures();
    });

    useEffect(() => {
        dispatch(setAllowedAndStage({ allowed: true, stage: ClassificatorStage.AGE_GENDER }));
    });

    return (
        <Container>
				<Form>
					<h2>Sex at birth</h2>
					<FormGroup>
						<FeatureRadio feature="male" label="Male" selection={gender} onChange={(event: any) => setGender(event.target.value)} />
						<FeatureRadio feature="female" label="Female" selection={gender} onChange={(event: any) => setGender(event.target.value)} />
						<FeatureRadio feature="unknown" label="Unknown" selection={gender} onChange={(event: any) => setGender(event.target.value)} />
					</FormGroup>
				</Form>
                <Form>
					<h2 className="gap_above_small">Age</h2>
					<Form.Group>
						<Form.Label>What is the current age of the patient?</Form.Label>
						<Stack direction="horizontal" gap={2}>
							<Form.Control type="text" placeholder="Enter current age" value={age} onChange={(event: any) => setAge(event.target.value)} />
							<Form.Check type="radio" label="years" checked={ageUnit === "years"} value="years" onChange={(event: any) => setAgeUnit(event.target.value)} />
							<Form.Check type="radio" label="months" checked={ageUnit === "months"} value="months" onChange={(event: any) => setAgeUnit(event.target.value)} />
							<Form.Check type="radio" label="days" checked={ageUnit === "days"} value="days" onChange={(event: any) => setAgeUnit(event.target.value)} />
						</Stack>
					</Form.Group>
					<h2 className="gap_above_small">Age at onset of seizures</h2>
					<Form.Group>
						<Form.Label>At what age did the seizures start? Leave empty if not known.</Form.Label>
						<Stack direction="horizontal" gap={2}>
							<Form.Control type="text" placeholder="Enter age at onset of seizures" value={ageAtOnset} onChange={(event: any) => setAgeAtOnset(event.target.value)} />
							<Form.Check type="radio" label="years" checked={ageAtOnsetUnit === "years"} value="years" onChange={(event: any) => setAgeAtOnsetUnit(event.target.value)} />
							<Form.Check type="radio" label="months" checked={ageAtOnsetUnit === "months"} value="months" onChange={(event: any) => setAgeAtOnsetUnit(event.target.value)} />
							<Form.Check type="radio" label="days" checked={ageAtOnsetUnit === "days"} value="days" onChange={(event: any) => setAgeAtOnsetUnit(event.target.value)} />
						</Stack>
					</Form.Group>
				</Form>
				<NextPreviousButtons next={handleNextButton} />
        </Container>
    );
}

export default AgePage;