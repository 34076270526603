import { Accordion, Button, Col, Container, Row, Stack } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import Collapsible from "react-collapsible";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Features } from "./Features";
import { SECTION } from "../../classification/Constants";
import { Classificator, ClassificatorStage } from "../../classification/Classificator";
import Survey from "../../components/Survey";
import { clearFeatures, selectSubmitted, setSubmitted } from "../../features/features/featuresSlice";
import intl from "react-intl-universal";
import { Syndrome } from "../../classification/Syndrome";
import { NextPreviousButtons } from "../../components/NextPreviousButtons";
import { useEffect } from "react";
import { submit } from "../../requests/store";
import { selectUser } from "../../features/user/userSlice";

function ResultsPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const features = useAppSelector(state => state.features.features);
    const user = useAppSelector(selectUser);
    const submitted = useAppSelector(selectSubmitted);
    
    const feat = new Features(features);
    const ageInfos = feat.generateAgeInfos();
	const eegInfos = feat.generateInfo(SECTION.EEG);
    const historyInfos = feat.generateInfo(SECTION.HISTORY);
    const seizureInfos = feat.generateSeizuresInfo();
    const developmentInfos = feat.generateInfo(SECTION.DEVELOPMENT);
    const neuroexamInfos = feat.generateInfo(SECTION.EXAM);
    const imagingInfos = feat.generateInfo(SECTION.IMAGING);

    const constructList = (syndromes: Syndrome[] | undefined) => {
		if(syndromes === undefined) {
			return;
		}

		const handleInfo = (type: string) => {
			navigate("/pages/Syndrome/"+type);
		}

		let list = [];
		if (syndromes.length === 0) {
			list.push(<p>None</p>);
		} else {
			for (const syndrome of syndromes) {
				let func = () => handleInfo(syndrome.getName());
				list.push(
					<Row>
						<Button variant="link" className="left" onClick={func}>
							{intl.get(syndrome.getName())}
						</Button>
					</Row>
				);
			}
		}

		return list;
	}

    const classificator = new Classificator();
    const classifications = classificator.check(features, ClassificatorStage.RESULTS);
    const likely = constructList(classifications.likely);
    const likelyWithAlerts = constructList(classifications.likelyWithAlerts);
    const uncertain = constructList(classifications.unclear);
    const unresolved = constructList(classifications.unresolved);
    const excluded = constructList(classifications.excluded);

    const noLikely = classifications.likely.length === 0;

    const submitData = async () => {
        try {
            if(user !== null) {
                const response = await submit(user.id, features);
                dispatch(setSubmitted());
            }
        } catch(err) {

        }
    }

    useEffect(() => {
        if(!submitted) {
            submitData();
        }
    }, []);

    const handleNewCase = () => {
		dispatch(clearFeatures());
		navigate("/pages/Age");
	}

    const handleValidation = () => {
        navigate("/pages/Validation");
    }

    const id = features.get("id");

    return (
        <div>
            <Container>
                <Collapsible trigger={<h2>Selected information</h2>}>
                    <Row>
                        <Col md={12}>
                            <h3>Age</h3>
                            <ul>{ageInfos}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>Seizures</h3>
                            {seizureInfos}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>Development</h3>
                            <ul>{developmentInfos}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>Neurological exam</h3>
                            <ul>{neuroexamInfos}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>EEG</h3>
                            <ul>{eegInfos}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>History</h3>
                            <ul>{historyInfos}</ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>Imaging</h3>
                            <ul>{imagingInfos}</ul>
                        </Col>
                    </Row>
                </Collapsible>
                <Collapsible trigger={<h2>Results</h2>} open>
                    <Row>
                        <Col md={12}>
                            <p>Please click in each syndrome in order to display alert criteria and more information for each syndrome.</p>

                            {noLikely &&
                                <p>
                                    According to the the information that you have provided and to ILAE’S 2022 classification and definition of epilepsy
                                    syndromes based on mandatory and exclusionary criteria, no epilepsy syndrome corresponds to the data entered.
                                    There are epilepsies that remain unclassified, but this result could also be linked to inadequate/inaccurate information provided.
                                </p>
                            }

                            <p>
                                The following results are based on the phenotype that you have described and maybe not all features of a syndrome are present at the 
                                beginning of the disease course so the results should be translated with caution and the tool could be used again in the future if there 
                                are changes in your patient’s presentation.
                            </p>

                            <Accordion defaultActiveKey={"0"}>
                                <AccordionItem eventKey={"0"}>
                                    <AccordionHeader>
                                        <h3>Likely</h3>
                                    </AccordionHeader>
                                    <AccordionBody>
                                        {!noLikely &&
                                                <p>According to the information you have provided and to ILAE’s 2022 classification and definition of epilepsy syndromes, your
                                                    patient's phenotype corresponds to all mandatory and has no exclusionary criteria for the following epilepsy syndromes:</p>
                                        }
                                        {likely}
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem eventKey={"1"}>
                                    <AccordionHeader>
                                        <h3>Likely with alerts</h3>
                                    </AccordionHeader>
                                    <AccordionBody>{likelyWithAlerts}</AccordionBody>
                                </AccordionItem>
                                <AccordionItem eventKey={"2"}>
                                    <AccordionHeader>
                                        <h3>Uncertain</h3>
                                    </AccordionHeader>
                                    <AccordionBody>{uncertain}</AccordionBody>
                                </AccordionItem>
                                <AccordionItem eventKey={"3"}>
                                    <AccordionHeader>
                                        <h3>Unresolved (essential investigation missing)</h3>
                                    </AccordionHeader>
                                    <AccordionBody>{unresolved}</AccordionBody>
                                </AccordionItem>
                                <AccordionItem eventKey={"4"}>
                                    <AccordionHeader>
                                        <h3>Excluded</h3>
                                    </AccordionHeader>
                                    <AccordionBody>{excluded}</AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                    </Row>
                </Collapsible>

                <Row className="gap_above">
                    <Stack direction="horizontal" gap={2}>
                        <Col className="d-grid" md={{span: 4}}>
                            <Button variant={"secondary"} onClick={(e) => navigate("/pages/Age")}>
                                Modify case
                            </Button>
                        </Col>
                        <Col className="d-grid" md={{span: 4}}>
                            <Button variant={"secondary"} onClick={handleNewCase}>
                                New case
                            </Button>
                        </Col>
                        <Col className="d-grid" md={{span: 4}}>
                            <Button variant={"secondary"} onClick={handleValidation}>
                                Validation
                            </Button>
                        </Col>
                    </Stack>
                </Row>
                <Row className="gap_above">
                    <Survey caseId={id}/>
                </Row>
            </Container>
        </div>
    );
}

export default ResultsPage;