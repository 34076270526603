import { Development } from "./Constants";
import { FeatureResponse, No, Unclear, Unresolved, Yes } from "./FeatureResponse";
import { defaultSeizure } from "./Seizures";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class JAE extends Syndrome {

    getName() {
        return "JAE";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        if(super.isAgeInRange(features, 0, 8)) {
            return new No("Exlusionary: Age < 8 years");
        }
        if(super.isAgeInRange(features, 20, 999)) {
            return new No("Exlusionary: Age > 20 years");
        }
        if(super.isAgeInRange(features, 9, 13)) {
            return new Yes("Mandatory present: Age between 9 and 13 years");
        }
        return new Unclear();
    }

    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        // Check for the mandatory hyperkinetic, tonic motor focal seizures
        let mandatoryOk = false;
        for (const seizure of seizures) {
            if (seizure.mainType === "generalized" && seizure.onset === "nonmotor") {
                const typical_absences = seizure.details.indexOf("nonmotor_gen_typical") > -1;
                mandatoryOk = typical_absences;
                if (mandatoryOk) {
                    break;
                }
            }
        }
        
        if(mandatoryOk) {
            return new Yes("Mandatory seizure types present: Typical absences");
        } else {
            return new No("Mandatory seizure types missing: Typical absences");
        }
    }

    checkExclusionarySeizures(seizures: any[]): FeatureResponse {
        const annot: string[] = [];

        let s1 = defaultSeizure();
        s1.mainType = "generalized";
        s1.onset = "motor";
        s1.details = ["motor_gen_tonic"];
        const tonic = this.containsSeizure(seizures, s1, false, annot, "Generalized tonic seizures");

        s1.details = ["motor_gen_atonic"];
        const atonic = this.containsSeizure(seizures, s1, false, annot, "Generalized atonic seizures");

        s1.onset = "nonmotor";
        s1.details = ["nonmotor_gen_atypical"];
        const atypical = this.containsSeizure(seizures, s1, false, annot, "Atypical absences");
        
        s1.details = ["nonmotor_gen_myoclonic"];
        const myoclonic = this.containsSeizure(seizures, s1, false, annot, "Myoclonic absences");

        s1 = defaultSeizure();
        s1.mainType = "focal";
        const focal = this.containsSeizure(seizures, s1, false, annot, "Focal onset seizures");

        let exclusionaryOk = !tonic && !atonic && !atypical && !myoclonic && !focal;
        
        if(exclusionaryOk) {
            return new Yes(annot);
        } else {
            return new No(annot);
        }
    }
    
    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable) {
            return new Unresolved("Info on development at onset is not available however is critical to determine mandatory and/or exlusionary features.");
        }

        const annot: string[] = [];
        const normal = hasFeature(dev, "dev_normal"); // specifying normal overrules other disability specifications
        const exclusionaryOk = normal || this.checkFeatures(dev, ["dev_disability_moderate", "dev_disability_profound"], false, annot);
        if(!exclusionaryOk)
            return new No(annot);
        
        return new Unclear();
    }

    protected checkImagingDetails(imaging: string[], imagingAvailable: boolean): FeatureResponse {
        if(!imagingAvailable) {
            return new Unclear("Imaging not available, no abnormal imaging known, which would otherwise argue against JAE");
        }

        const annot: string[] = [];
        const normal = this.checkFeatures(imaging, ["imaging_normal"], true, annot);
        if(normal) {
            return new Yes(annot);
        }
        return new No("Abnormal imaging argues against JAE");
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        const annot: string[] = [];
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_gen_sw"], true, annot);
        const exclusionaryOk = this.checkFeatures(eeg, ["eeg_unilateral_discharges", "eeg_background_slowing", "eeg_ictal_lack_EEG_correlate"], false, annot);

        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot); // only yes, if no exclusionary features
        } else {
            return new No(annot);
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset 8 or 14-20 years", "Staring spells with typical duration >30s or with postictal confusion or fatigue", 
            "Absence seizure frequency of >10 per day", "Mild intellectual disability", "Abnormal neurological exam",
            "Lack of GTCS over the course of the epilepsy, in the absence of treatment with ASMs that are effective for GTCS",
            "Myoclonic generalized seizures or eyelid myoclonia"];
    }

}