import { Development } from "./Constants";
import { hasMandatoryDevFeature } from "./Development";
import { Yes, Unclear, FeatureResponse, No, Unresolved } from "./FeatureResponse";
import { Syndrome } from "./Syndrome";
import { hasFeature } from "./Utils";

export class PME extends Syndrome {

    constructor() {
        super();
    }

    getName() {
        return "PME";
    }

    checkAgeAndGender(features: Map<string, any>): FeatureResponse {
        if(super.checkAge(features, 2, 50) === new Yes()) {
            return new Yes();
        }
        return new Unclear();
    }

    checkMandatorySeizures(seizures: any[]): FeatureResponse {
        // Check for the mandatory hyperkinetic, tonic motor focal seizures
        let mandatoryOk = false;
        for (const seizure of seizures) {
            if (seizure.mainType === "generalized" && seizure.onset == "motor") {
                mandatoryOk = seizure.details.indexOf("motor_gen_myoclonic") > -1;
                if (mandatoryOk) {
                    break;
                }
            }
        }
        
        if(mandatoryOk) {
            return new Yes();
        } else {
            return new No();
        }
    }

    protected checkDevelopmentDetails(dev: Development[], devAtOnsetAvailable: boolean, devAtCourseAvailable: boolean): FeatureResponse {
        if(!devAtOnsetAvailable || !devAtCourseAvailable) {
            return new Unresolved("Info on development is not available however is critical to determine mandatory features.");
        }

        const annot: string[] = new Array();
        let mandatoryOk = this.checkFeatures(dev, ["dev_normal"], true, annot);
        mandatoryOk = mandatoryOk && hasMandatoryDevFeature(dev, "dev_course_regression", annot, "progressive cognitive decline during the course", false, false);
        
        if(mandatoryOk) {
            return new Yes(annot); 
        } else {
            return new No(annot); 
        }
    }

    protected checkNeuroExamDetails(exam: string[],  examAtOnsetAvailable: boolean, examAtCourseAvailable: boolean): FeatureResponse {
        if(!examAtOnsetAvailable && !examAtCourseAvailable) {
            return new Unresolved("Info on neurological examination is not available however is critical to determine mandatory and/or exlusionary features.");
        }
        
        if(hasFeature(exam, "exam_normal") && hasFeature(exam, "exam_course_normal"))
            return new Yes("Mandatory present: Normal neurological examination");
        return new Unclear();
    }

    protected checkEEGDetails(eeg: string[], eegAvailable: boolean): FeatureResponse {
        if(!eegAvailable) {
            return new Unresolved("EEG is not available however is critical to determine mandatory and exlusionary features.");
        }

        // Mandatory: generalized spike/polyspike-and-wave ; EEG deterioration with progressive background slowing and/or increased epileptiform abnormality
        // Exclusionary : persistent focal epileptiform abnormality, other than occipital

        const annot: string[] = new Array();
        const mandatoryOk = this.checkFeatures(eeg, ["eeg_gen_sw_psw", "eeg_progressive_slowing"], true, annot);
        const focal = hasFeature(eeg, "eeg_focal");
        const occipital = hasFeature(eeg, "eeg_ied_occipital");
        const exclusionaryOk = !(focal && !occipital);

        if(!exclusionaryOk) {
            annot.push("Exclusionary present: Focal epileptiform abnormality, other than occipital");
        }
        
        if(mandatoryOk && exclusionaryOk) {
            return new Yes(annot); 
        } else {
            return new No(annot); 
        }
    }

    getAlertCriteria(): string[] {
        return ["Age at onset > 20 years"];
    }

}