import { Button, Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Syndrome } from "../../classification/Syndrome";
import intl from 'react-intl-universal';
import { useState } from "react";
import { Classificator } from "../../classification/Classificator";
import ExplanationModal from "./ExplanationModal";
import { setOpen } from "../../features/classificationUI/classificationUISlice";

function ClassifierPanel() {
    const allowed = useAppSelector(state => state.classificationUI.allowed);
    const open = useAppSelector(state => state.classificationUI.open);
    const stage = useAppSelector(state => state.classificationUI.stage);
    const features = useAppSelector(state => state.features.features);
    const dispatch = useAppDispatch();

    const [showDialog, setShowDialog] = useState(false);
    const [currentSyndrome, setCurrentSyndrome] = useState(null as null | Syndrome);

    if (!allowed) return null;
    
    const constructList = (syndromes: Syndrome[]) => {
		let list = new Array();
		if (syndromes.length === 0) {
			list.push(<p key={0}>None</p>);
		} else {
			for (const syndrome of syndromes) {
				list.push(
					<Button key={syndrome.getName()} variant="link" className="left" onClick={() => {
                        setCurrentSyndrome(syndrome);
                        setShowDialog(true);
                    }}>
						{intl.get(syndrome.getName())}
					</Button>
				);
			}
		}

		return list;
	}

    const classificator = new Classificator();
    const classifications = classificator.check(features, stage);
    const likely = constructList(classifications.likely);
    const uncertain = constructList(classifications.unclear);
    const unresolved = constructList(classifications.unresolved);
    const excluded = constructList(classifications.excluded);

    return (
        <div>
            <Offcanvas show={open} placement="end" onHide={(e: any) => dispatch(setOpen(false))}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Classification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h3>Likely</h3>
                    <hr />
                    {likely}
                    <h3 className="gap_above_small">Uncertain</h3>
                    <hr />
                    {uncertain}
                    <h3 className="gap_above_small">Unresolved</h3>
                    <hr />
                    {unresolved}
                    <h3 className="gap_above_small">Excluded</h3>
                    <hr />
                    {excluded}
                </Offcanvas.Body>
            </Offcanvas>
            {currentSyndrome !== null && (
                <ExplanationModal
                    classifier={classificator}
                    classifierStage={stage}
                    show={showDialog}
                    syndrome={currentSyndrome}
                    onHide={() => setShowDialog(false)}
                />
            )}
        </div>
    );
}

export default ClassifierPanel;