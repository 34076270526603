import { Container, Col, Row, FormGroup, FormLabel, FormControl, Alert, Button } from "react-bootstrap";
import Jumbotron from "../../components/Jumbotron";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../requests/auth";

function ResetPasswordPage() {
    const navigate = useNavigate();
    const params = useParams();
    const token = params.token;
    
    const [password, setPassword] = useState("");
	const onChangePassword = (event: any) => {
		setPassword(event.target.value);
	};

    const [password2, setPassword2] = useState("");
	const onChangePassword2 = (event: any) => {
		setPassword2(event.target.value);
	};

    const handleButton = async () => {
        try {
            if(token !== undefined) {
                await resetPassword({ password: password }, token);
            }
            navigate("/");
        } catch(error: any) {
			console.log(error);
        }
        
    }

    
    const passwordIdentical = password === password2;

    return (
        <Container>
			<Col>
				<Row>
					<Jumbotron title="ENGenE App" subheading="An educational tool on Epilepsy Nosology and Genetic Etiologies" />
				</Row>
				<Row>
					<p>Enter new password.</p>
				</Row>
				<Row>
                <FormGroup controlId="password">
						<FormLabel>Password</FormLabel>
						<FormControl type="password" placeholder="Enter password" value={password} onChange={onChangePassword} />
					</FormGroup>
                    <FormGroup controlId="password2">
						<FormLabel>Repeat password</FormLabel>
						<FormControl type="password" placeholder="Enter password again" value={password2} onChange={onChangePassword2} />
						{ !passwordIdentical && <Alert variant="danger">Password and repeated password are different!</Alert>}
					</FormGroup>
				</Row>
                <Row className="gap_above">
					<Button variant="secondary" disabled={!passwordIdentical} onClick={handleButton}>Set new password</Button>
				</Row>				
			</Col>
		</Container>
    );
}

export default ResetPasswordPage;